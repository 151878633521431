export enum Language {
  German = 'de',
  English = 'en'
}

export interface Locale {
  language: Language;
  plurals: Plurals;
  strings: Strings;
}

export interface PluralDescription {
  one?: string;
  two?: string;
  many?: string;
  default: string;
  [key: string]: string | undefined;
}

export interface Plurals {
  [key: string]: PluralDescription;
}

export interface Strings {
  [key: string]: string;
}

export const hydrateString = (
  stringToHydrate: string,
  values: Strings,
): string => {
  let hydratedString = stringToHydrate;
  for (const [key, value] of Object.entries(values)) {
    hydratedString = hydratedString.replace(
      new RegExp(`\\{\\{${key}\\}\\}`, 'g'),
      value,
    );
  }
  return hydratedString;
};

export const pluralize = (
  language: Language,
  amount: number,
  pluralDescription: PluralDescription,
): string => {
  const descriptionKey = new Intl.PluralRules(language).select(amount);
  const pluralValue =
    pluralDescription[descriptionKey] ?? pluralDescription.default;
  return hydrateString(pluralValue, { amount: amount.toString() });
};

export const select = (
    localized: { de?: string; en?: string } | undefined,
    language: Language,
): string => {
  switch (language) {
    case Language.German:
      return localized?.de ?? '';
    case Language.English:
      return localized?.en ?? '';
  }

  return '';
};
