import React, { useCallback } from 'react';
import {
  deletePlaces,
  useTicketSelection,
} from '../../state/TicketSelection';
import Button from '../Button';
import Section from '../Section';
import Ticket from '../Ticket';
import { hydrateString } from '../../util/localization';
import style from './style.module.css';
import { useDispatch } from 'react-redux';
import { useFetch } from '../../state/Fetch';
import { useLocale } from '../../state/Localization';

const TicketBasketComponen: React.FC = () => {
  const dispatch = useDispatch();
  const { fetchComponent, fetchIndicator } = useFetch();
  const { strings } = useLocale();
  const ticketSelection = useTicketSelection();
  const places = ticketSelection?.places;
  const onRemoveAllClicked = useCallback(() => {
    dispatch(deletePlaces([], fetchComponent));
  }, [dispatch, fetchComponent]);
  return (
    <Section variant="sub">
      <div>
        <div className={style.Header}>
          <strong className={style.TextHeading}>
            {hydrateString(strings.Tickets_FirstRow, {
              ticketCount: (places?.length ?? 0).toString(),
            })}
          </strong>
          <Button
            fetchIndicator={fetchIndicator}
            onClick={onRemoveAllClicked}
            variant="sub"
          >
            {strings.Tickets_SecondRow}
          </Button>
        </div>
        {places ? (
          <div>
            {places.map((place, key) => (
              <Ticket key={key} index={key} place={place} />
            ))}
          </div>
        ) : null}
      </div>
    </Section>
  );
};

export default TicketBasketComponen;
