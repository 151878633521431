import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { API_ENDPOINTS } from '../../config.json';
import { ActionType } from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';

export const getBlockGroups = (
  venueEventId: string,
): AsyncReduxThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(API_ENDPOINTS.GET_BLOCK_GROUPS, {
      params: { venueEventId },
    });

    const blockGroups = await apiGETRequest(requestURL);

    dispatch({ payload: { blockGroups }, type: ActionType.SUCCESS });
  } catch (error) {
    dispatch({ payload: { error }, type: ActionType.ERROR });
    handleError(dispatch, error);
  }
};
