import React from 'react';
import {useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {useSession} from '../../state/Session';
import {useTicketSelection} from '../../state/TicketSelection';
import {useVenueEvent} from '../../state/VenueEvent';
import style from './style.module.css';
import {hydrateString} from '../../util/localization';

const PurchaseOptionsEventSummaryComponent: React.FC = () => {
  const ticketSelection = useTicketSelection();
  const {venueEvent} = useVenueEvent();
  const {language, strings} = useLocale();
  const {user} = useSession();
  const {fetchIndicator} = useFetch();

  if (fetchIndicator.fetching && !ticketSelection?.places) {
    return (
      <div className={style.Container}>
        <div className={style.LoaderContainer}>
          <div className={style.Loader}></div>
        </div>
      </div>
    );
  }

  if (!venueEvent || !ticketSelection?.places || ticketSelection?.places.length === 0) {
    return <></>;
  }

  const greetingString = (): string => {
    const purchaseForTicketHolder = ticketSelection.purchaseForTicketHolder;

    if (purchaseForTicketHolder && purchaseForTicketHolder.id !== user?.id && purchaseForTicketHolder.firstName) {
      return hydrateString(strings.PurchaseOptions_PlacesForUser, {
        firstName: purchaseForTicketHolder.firstName,
        lastName: purchaseForTicketHolder.lastName,
      });
    } else {
      return strings.PurchaseOptions_Places;
    }
  };

  return (
    <>
      <div className={style.Container}>
        {<>
          <h1 className={style.Title}>Gude {user?.firstName},</h1>
          <h1 className={style.Title}>{greetingString()}</h1>
          <h1 className={style.Title}> {venueEvent.title} am {venueEvent.startDate && <span>
              {venueEvent.startDate.toLocaleString(language, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}{' '}
              /{' '}
            {venueEvent.startDate.toLocaleString(language, {
              hour: '2-digit',
              minute: '2-digit',
            })}
            </span>}</h1>
        </>}
      </div>
    </>
  );
};

export default PurchaseOptionsEventSummaryComponent;
