import React from 'react';
import { useTicketSelection } from '../../state/TicketSelection';
import { hydrateString } from '../../util/localization';
import Section from '../Section';
import style from './style.module.css';
import { useLocale } from '../../state/Localization';
import { useSession } from '../../state/Session';
import { UserData } from '../../common/types';


const RightsProviderComponen: React.FC = () => {
  const { user } = useSession();
  const { strings } = useLocale();
  const ticketSelectionState = useTicketSelection();
  const purchaseForTicketHolder = ticketSelectionState?.purchaseForTicketHolder;

  const greetingString = (purchaseForTicketHolder?: UserData): string => {
    if (purchaseForTicketHolder && purchaseForTicketHolder.firstName) {
      return hydrateString(strings.RightsProviderPurchaseForTicketHolder_FirstRow, {
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        purchaseForTicketHolderFirstName: purchaseForTicketHolder.firstName,
        purchaseForTicketHolderLastName: purchaseForTicketHolder.lastName,
      });
    } else {
      return hydrateString(strings.RightsProvider_FirstRow, {
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
      });
    }
  };

  return (
    <>
      <Section>
        <div className={style.Greeting}>
          <strong>
            {greetingString(purchaseForTicketHolder)}
          </strong>
        </div>
      </Section>
    </>
  );
};

export default RightsProviderComponen;
