import {IResourceDictionary, Loader, Texture} from 'pixi.js';
import log from 'loglevel';
import {PlaceGraphicalState} from './PlaceGraphicalState';

const LOADER = new Loader();

const LABEL_SPRITESHEET_NAME = '/sprites.json';
const BLOCK_LABEL_SPRITESHEET_NAME = '/block_labels.json';
const NUMERIC_LABEL_FALLBACK_TEXTURE = 'label.unknown.png';
const SEAT_SELECTED_TEXTURE = 'seat_circle.png';
const SEAT_AVAILABLE_TEXTURE = 'seat_disc.png';
const SEAT_UNAVAILABLE_TEXTURE = 'seat_disc.png';
const SEAT_COMPLETE_TEXTURE = 'seat_dot.png';

export const NUMERIC_LABEL_OVERFLOW_THRESHOLD = 99;
export const NUMERIC_LABEL_OVERFLOW_NAME = 'overflow';

const SEAT_TEXTURE_BY_STATE: Record<PlaceGraphicalState, string> = {
  [PlaceGraphicalState.UNKNOWN]: SEAT_UNAVAILABLE_TEXTURE,
  [PlaceGraphicalState.UNAVAILABLE]: SEAT_UNAVAILABLE_TEXTURE,
  [PlaceGraphicalState.AVAILABLE]: SEAT_AVAILABLE_TEXTURE,
  [PlaceGraphicalState.SELECTED]: SEAT_SELECTED_TEXTURE,
  [PlaceGraphicalState.PROCESSING]: SEAT_SELECTED_TEXTURE,
  [PlaceGraphicalState.RESEATING]: SEAT_AVAILABLE_TEXTURE,
  [PlaceGraphicalState.RESEATING_COMPLETED]: SEAT_COMPLETE_TEXTURE,
  [PlaceGraphicalState.RESEATING_SELECTED]: SEAT_SELECTED_TEXTURE,
  [PlaceGraphicalState.RESEATING_AVAILABLE]: SEAT_AVAILABLE_TEXTURE
};

LOADER
  .add(BLOCK_LABEL_SPRITESHEET_NAME)
  .add(LABEL_SPRITESHEET_NAME);

function getResourceLoader(): Loader {
  return LOADER;
}

const RESOURCES = new Promise<IResourceDictionary>((resolve) => {
  LOADER.load((loader) => {
    resolve(loader.resources);
  });
});

export async function loadResources(): Promise<IResourceDictionary> {
  return RESOURCES;
}

/**
 * Gets a reference to one of the label textures.
 *
 * If there is no matching texture for the given label
 * a fallback texture will be returned.
 *
 * @param label The text of the label
 *
 * @throws if the label textures are not available.
 */
export function getNumericLabelTexture(label: string): Texture {
  let texture = getResourceLoader().resources[LABEL_SPRITESHEET_NAME]?.textures?.[`label.${label}.png`];

  if (!texture) {
    log.warn(`no texture for label "${label}" available, using "${NUMERIC_LABEL_FALLBACK_TEXTURE}" as fallback`);
    texture = getResourceLoader().resources[LABEL_SPRITESHEET_NAME]?.textures?.[NUMERIC_LABEL_FALLBACK_TEXTURE];
  }

  if (!texture) {
    throw new Error(`no texture for label "${label}" found`);
  }

  return texture;
}

/**
 * Returns the texture for the given label.
 *
 * @param label The label
 *
 * @throws if there is no matching texture for the label
 */
export function getBlockLabelTexture(label: string): Texture {
  const texture = getResourceLoader().resources[BLOCK_LABEL_SPRITESHEET_NAME]?.textures?.[`label.${label}.png`];

  if (!texture) {
    return getNumericLabelTexture(label);
  }

  return texture;
}

/**
 * Returns the state specific texture for a seat.
 *
 * @param state The state for which the texture should be returned.
 *
 * @throws if there is no matching texture for the seat.
 */
export function getSeatTexture(state: PlaceGraphicalState): Texture {
  const texture = getResourceLoader().resources[LABEL_SPRITESHEET_NAME]
    ?.textures?.[SEAT_TEXTURE_BY_STATE[state]];

  if (!texture) {
    throw new Error('no texture for seat found');
  }

  return texture;
}



