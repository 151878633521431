import { Plugin, Viewport } from 'pixi-viewport'

interface ClampOptions2 {
  left: number
  right: number
  top: number
  bottom: number
}

/**
 * Alternative implementation of pixi-viewport clamp plugin,
 * that fixes underflow behaviour for non default central point
 */
export class ViewportClampPlugin2 extends Plugin {
  private readonly options: ClampOptions2
  private last?: { x: number, y: number, scaleX: number, scaleY: number }

  /**
   * @private
   * @param {Viewport} parent
   * @param {ClampOptions2} [options]
   */
  constructor(parent: Viewport, options: ClampOptions2) {
    super(parent)
    this.options = options
    this.update()
  }

  /**
   * handle move events
   * @param {PIXI.interaction.InteractionEvent} event
   * @returns {boolean}
   */
  move() {
    this.update()
    return false
  }

  update() {
    // @ts-ignore
    if (this.paused) {
      return
    }

    // @ts-ignore
    const parent: Viewport = this.parent

    // only clamp on change
    if (this.last && parent.x === this.last.x && parent.y === this.last.y && parent.scale.x === this.last.scaleX && parent.scale.y === this.last.scaleY) {
      return
    }
    const original = { x: parent.x, y: parent.y }
    // @ts-ignore
    const decelerate = parent.plugins['decelerate'] || {}
    if (this.options.left !== null || this.options.right !== null) {
      let moved = false
      // @ts-ignore
      const screenWorldWidth = parent.screenWorldWidth
      if (screenWorldWidth < parent.screenWidth) {
        const targetX = (this.calcLeftLimitX(parent) + this.calcRightLimitX(parent)) * 0.5
        if (parent.x !== targetX) {
          parent.x = targetX
          moved = true
        }
      } else {
        if (parent.left < this.options.left) {
          parent.x = this.calcLeftLimitX(parent)
          decelerate.x = 0
          moved = true
        }
        if (parent.right > this.options.right) {
          parent.x = this.calcRightLimitX(parent)
          decelerate.x = 0
          moved = true
        }
      }
      if (moved) {
        parent.emit('moved', { viewport: parent, original, type: 'clamp-x' })
      }
    }
    // @ts-ignore
    if (this.options.top !== null || this.options.bottom !== null) {
      let moved = false
      // @ts-ignore
      const screenWorldHeight = parent.screenWorldHeight
      if (screenWorldHeight < parent.screenHeight) {
        const targetY = (this.calcTopLimitY(parent) + this.calcBottomLimitY(parent)) * 0.5
        if (parent.y !== targetY) {
          parent.y = targetY
          moved = true
        }
      } else {
        if (parent.top < this.options.top) {
          parent.y = this.calcTopLimitY(parent)
          decelerate.y = 0
          moved = true
        }
        if (parent.bottom > this.options.bottom) {
          parent.y = this.calcBottomLimitY(parent)
          decelerate.y = 0
          moved = true
        }
      }
      if (moved) {
        parent.emit('moved', { viewport: parent, original, type: 'clamp-y' })
      }
    }
    this.last = {
      x: parent.x,
      y: parent.y,
      scaleX: parent.scale.x,
      scaleY: parent.scale.y
    }
  }

  private calcLeftLimitX(parent: Viewport): number {
    return -this.options.left * parent.scale.x
  }

  private calcRightLimitX(parent: Viewport): number {
    return -this.options.right * parent.scale.x + parent.screenWidth
  }

  private calcTopLimitY(parent: Viewport): number {
    return -this.options.top * parent.scale.y
  }

  private calcBottomLimitY(parent: Viewport): number {
    return -this.options.bottom * parent.scale.y + parent.screenHeight
  }

  reset() {
    this.update()
  }
}
