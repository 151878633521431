import React, {useEffect, useState} from 'react';
import style from './style.module.css';
import Modal from 'react-modal';
import {cloneDeep, compact} from 'lodash';
import {toast} from 'react-toastify';
import {hydrateString} from '../../util/localization';
import {addStandingPlace} from '../../state/TicketSelection';
import {SelectedStandingPlace} from '../../state/PlaceReseating/types';
import {useDispatch} from 'react-redux';
import {useLocale} from '../../state/Localization';
import {useFetch} from '../../state/Fetch';
import {BlockSelectionEvent} from '../VenuePlan/interaction';
import {Place, usePlacesReseating} from '../../state/PlaceReseating';
import {deletePlaces, useTicketSelection} from '../../state/TicketSelection';
import {processReseating} from '../../state/PlaceReseating/actions';
import {Mode as PlaceReseatingMode} from '../../state/PlaceReseating/types';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

type StandingPlaceReseatingProps = {
  isShowModalReseatStandingPlace: boolean;
  setIsShowModalReaseatStandingPlace: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBlockForStandingPlace:  BlockSelectionEvent | undefined;
  availablePlacesInSelectedBlockForStandingPlace: number;
};

/**
 * Reseating for standing places modal
 */
const StandingPlaceReseating: React.FC<StandingPlaceReseatingProps> = (props) => {
  const {
    isShowModalReseatStandingPlace,
    setIsShowModalReaseatStandingPlace,
    selectedBlockForStandingPlace,
    availablePlacesInSelectedBlockForStandingPlace,
  } = props;

  const dispatch = useDispatch();
  const { fetchComponent } = useFetch();
  const { strings } = useLocale();

  const placeReseating = usePlacesReseating();
  const places = placeReseating.places;

  const ticketSelection = useTicketSelection();

  const [reseatingPlacesSelected, setReseatingPlacesSelected] = useState<Place[]>([]);
  const [reseatingPlacesInBlock, setReseatingPlacesInBlock] = useState<Place[]>([]);
  const [selectedPlacesInBlock, setSelectedPlacesInBlock] = useState<Place[]>([]);
  const [newPlacesInBlock, setNewPlacesInBlock] = useState<Place[]>([]);

  // Called when the modal gets opened.
  useEffect(() => {
    if (!isShowModalReseatStandingPlace) {
      return;
    }

    // Clone all reseating places in this block
    setReseatingPlacesInBlock(places.filter(
      (place) => place.blockId === selectedBlockForStandingPlace?.blockId
    ).map((place) => cloneDeep(place)));

    // Clone all places that could be reseated to this block
    setSelectedPlacesInBlock(places.filter(
      (place) => place.blockId !== selectedBlockForStandingPlace?.blockId && place.mode === 'selected'
    ).map((place) => cloneDeep(place)));

    // Clone all places that are reseated to this block
    setNewPlacesInBlock(compact((ticketSelection?.places || [])
      .filter((ticket) => ticket.blockId === selectedBlockForStandingPlace?.blockId)
      .map((ticket) => {
        const newPlace = cloneDeep(places.find((place) => place.contractId === ticket.reseatingContractId));
        newPlace!.mode = PlaceReseatingMode.View;
        return newPlace;
      })
    ));
  }, [isShowModalReseatStandingPlace]);

  const onCancel = (): void => {
    setIsShowModalReaseatStandingPlace(false);
  };

  const onReseatingConfirm = (): void => {
    // Remove deselcted places:
    for (const clonedPlace of reseatingPlacesInBlock) {
      const place = places.find((p) => p.id === clonedPlace.id);
      if (clonedPlace.mode === 'view' && clonedPlace.mode !== place?.mode) {
        dispatch(processReseating(clonedPlace.id));
      }
    }

    // Add selected places in correct order
    for (const selectedPlace of reseatingPlacesSelected) {
      const place = places.find((p) => p.id === selectedPlace.id);
      if (selectedPlace.mode !== place?.mode) {
        dispatch(processReseating(selectedPlace.id));
      }
    }

    setIsShowModalReaseatStandingPlace(false);
  };

  const onSelectionConfirm = (): void => {
    if (selectedBlockForStandingPlace?.blockId) {
      const selectedPlaces = selectedPlacesInBlock.filter((place) => place.mode === 'selected');
      if (selectedPlaces.length > availablePlacesInSelectedBlockForStandingPlace) {
        toast.error(
          hydrateString(strings.Reseating_AvailablePlacesExceeded, {
            availablePlaces: availablePlacesInSelectedBlockForStandingPlace.toString(),
            blockName: selectedBlockForStandingPlace?.blockName
          }),
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      const selectedStandingPlaces: SelectedStandingPlace[] = selectedPlacesInBlock
        .filter((place) => place.mode === 'selected')
        .map((place) => ({
          reseatingContractId: place.contractId,
          emailOrSubjectId: place.emailOrSubjectId,
          checkoutId: place.checkoutId
        }));

      dispatch(addStandingPlace(selectedBlockForStandingPlace.blockId, selectedStandingPlaces));
    }
    setIsShowModalReaseatStandingPlace(false);
  };

  const onNewPlaceDeleteConfirm = (): void => {
    const selectedPlaces = newPlacesInBlock
      .filter((place) => place.mode === 'selected')
      .map((place) => ticketSelection?.places.find((ticket) =>
        ticket.reseatingContractId === place.contractId
      )?.id) as string[];
    if (selectedPlaces.length) {
      dispatch(deletePlaces(selectedPlaces, fetchComponent));
    }
    setIsShowModalReaseatStandingPlace(false);
  };

  const onReseatingCheckboxClicked = (placeId: string): void => {
    setReseatingPlacesInBlock(
      reseatingPlacesInBlock.map((place) => {
        if (place.id === placeId) {
          if (place.mode === PlaceReseatingMode.Selected) {
            place.mode = PlaceReseatingMode.View;
            setReseatingPlacesSelected(
              reseatingPlacesSelected.filter((p) => p.id !== place.id)
            );
          } else {
            place.mode = PlaceReseatingMode.Selected;
            // Keep track of order (first in first out)
            setReseatingPlacesSelected(
              [...reseatingPlacesSelected, place]
            );
          }
        }
        return place;
      })
    );
  };

  const onSelectionCheckboxClicked = (placeId: string): void => {
    setSelectedPlacesInBlock(
      selectedPlacesInBlock.map((place) => {
        if (place.id === placeId) {
          place.mode = place.mode === 'selected'
            ? PlaceReseatingMode.View
            : PlaceReseatingMode.Selected;
        }
        return place;
      })
    );
  };

  const onNewPlaceCheckboxClicked = (placeId: string): void => {
    setNewPlacesInBlock(
      newPlacesInBlock.map((place) => {
        if (place.id === placeId) {
          place.mode = place.mode === 'selected'
            ? PlaceReseatingMode.View
            : PlaceReseatingMode.Selected;
        }
        return place;
      })
    );
  };

  return (
    <div>
      <Modal
        isOpen={isShowModalReseatStandingPlace}
        onRequestClose={onCancel}
        className={style.Modal}
        overlayClassName={style.Overlay}
      >
        <button onClick={onCancel} className={style.CloseButton}>{strings.StandingplaceModal_Cancel}</button>
        <div className={style.ModalInner}>
          <h2 className={style.Title}>{strings.Shared_Block} {selectedBlockForStandingPlace?.blockName}</h2>

          {!!availablePlacesInSelectedBlockForStandingPlace && !!selectedPlacesInBlock.length &&
            <div className={style.TicketReseatingBlock}>
              <h3 className={style.SubTitle}>{strings.Reseating_NewPlaceFor}</h3>
              {selectedPlacesInBlock.map((place, key) => (
                <div key={key} className={`${style.TicketReseating}
                  ${place.mode === 'selected' ? style.TicketReseatingSelected : ''}`}
                >
                  <div className={style.TicketReseatingCheckBox}>
                    <div className={style.TicketReseatingCheckBoxIcon}
                      onClick={(): void => { onSelectionCheckboxClicked(place.id); }}
                    >
                      <FontAwesomeIcon icon={faCheck}/>
                    </div>
                  </div>
                  <div className={style.TicketReseatingContent}>
                    <strong>{place.seasonTicketLegalRecipient}</strong>{', '}
                    {strings.Shared_Block}&nbsp;{place.blockLabel}
                    {place.blockType === 'seating' && <>
                      {', '}{strings.Shared_Row}&nbsp;{place.rowLabel}
                      {', '}{strings.Shared_Seat}&nbsp;{place.seatLabel}
                      {', '}{place.pricingCategoryName}
                    </>}
                  </div>
                </div>
              ))}
              <button onClick={onSelectionConfirm} className={style.SubmitButton}>
                {strings.StandingplaceModal_NewPlaceForSubmit}
              </button>
            </div>
          }

          {!!newPlacesInBlock.length &&
            <div className={style.TicketReseatingBlock}>
              <h3 className={style.SubTitle}>{strings.StandingplaceModal_YourNewPlaces}</h3>
              {newPlacesInBlock.map((place, key) => (
                <div key={key} className={`${style.TicketReseating}
                  ${place.mode === 'selected' ? style.TicketReseatingSelected : ''}`}
                >
                  <div className={style.TicketReseatingCheckBox}>
                    <div className={style.TicketReseatingCheckBoxIcon}
                      onClick={(): void => { onNewPlaceCheckboxClicked(place.id); }}>
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  </div>
                  <div className={`${style.TicketReseatingContent} ${style.TicketReseatingContentFlex}`}>
                    <strong>{place.seasonTicketLegalRecipient}</strong>
                    <div className={style.TicketReseatingStatusLabel}>
                      {strings.StandingplaceModal_StatusCompleted}
                    </div>
                  </div>
                </div>
              ))}
              <button onClick={onNewPlaceDeleteConfirm} className={style.SubmitButton}>
                {strings.StandingplaceModal_YourNewPlacesSubmit}
              </button>
            </div>
          }

          {!!reseatingPlacesInBlock.length &&
            <div className={style.TicketReseatingBlock}>
              <h3 className={style.SubTitle}>{strings.StandingplaceModal_YourPlaces}</h3>
              {reseatingPlacesInBlock.map((place, key) => (
                <div key={key} className={`${style.TicketReseating}
                  ${place.mode === 'selected' ? style.TicketReseatingSelected : ''}
                  ${place.mode === 'completed' ? style.TicketReseatingCompleted : ''}`}
                >
                  <div className={style.TicketReseatingCheckBox}>
                    <div className={style.TicketReseatingCheckBoxIcon}
                      onClick={(): void => { onReseatingCheckboxClicked(place.id); }}>
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  </div>
                  <div className={`${style.TicketReseatingContent} ${style.TicketReseatingContentFlex}`}>
                    <strong>{place.seasonTicketLegalRecipient}</strong>
                    <div className={style.TicketReseatingStatusLabel}>
                      {strings.StandingplaceModal_StatusCompleted}
                    </div>
                  </div>
                </div>
              ))}
              <button onClick={onReseatingConfirm} className={style.SubmitButton}>
                {strings.StandingplaceModal_YourPlacesSubmit}
              </button>
            </div>
          }
        </div>
      </Modal>
    </div>
  );
};

export default StandingPlaceReseating;
