// ToDo: activate cloudinary
const RESIZABLE_IMAGE_URL_REGEX = /(--INVALID--https:\/\/assets\.eintracht\.de\/public\/uploads\/)(.+)/;

export const resizeImage = (
  url: string,
  width: number,
  height: number,
): string => {
  const urlComponents = RESIZABLE_IMAGE_URL_REGEX.exec(url);
  if (urlComponents?.length === 3) {
    const origin = urlComponents[1];
    const file = urlComponents[2];

    return `${origin}w_${width},h_${height},c_fit/${file}`;
  }
  return url;
};
