import React, {createRef, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {addPlacesPersonalization, usePlacesPersonalization} from '../../state/PlacePersonalization';
import {clearPurchasableItems, loadPurchasableItemsForPlace, usePurchasableItems} from '../../state/PurchasableItems';
import {deletePlaces, Place, setPurchasableItem} from '../../state/TicketSelection';
import {useVenueEvent} from '../../state/VenueEvent';
import Button from '../Button';
import Countdown from '../Countdown';
import Select from '../Select';
import style from './style.module.css';

const TicketComponent: React.FC<{ place: Place; index: number; reseating?: boolean; otherPerson?: boolean }> =
  ({ place, index, reseating, otherPerson }) => {

  const dispatch = useDispatch();
  const { fetchComponent, fetchIndicator } = useFetch();
  const { language, strings } = useLocale();
  const purchasableItemsState = usePurchasableItems();
  const placesPersonalization = usePlacesPersonalization();
  const venueEvent = useVenueEvent();
  const purchasableItemsRef = createRef<HTMLSelectElement>();

  useEffect(() => {
    if (purchasableItemsState?.placeId === place.id && purchasableItemsState?.purchasableItems) {
      const select = purchasableItemsRef.current;
      if (select) {
        select.setAttribute('size', String(select.options.length));
      }
    }
  }, [purchasableItemsState?.placeId, purchasableItemsState?.purchasableItems]);

  const onSelectedPurchasableItemChanged = useCallback(
    (eventArgs: React.ChangeEvent<HTMLSelectElement>) => {
      const placeId = place.id;
      const purchasableItemId = eventArgs.currentTarget.value;
      dispatch(setPurchasableItem(placeId, purchasableItemId, fetchComponent));
      dispatch(clearPurchasableItems());
    },
    [dispatch, place.id, fetchComponent],
  );

  const onPurchasableItemOptionClicked = useCallback(
    (eventArgs: React.MouseEvent<HTMLOptionElement>) => {
      if (eventArgs.currentTarget.value === place.selectedPurchasableItem?.id) {
        dispatch(clearPurchasableItems());
      }
    },
    [dispatch, place.id, place.selectedPurchasableItem, fetchComponent],
  );

  const onChangePurchasableItemClicked = useCallback(() => {
    dispatch(loadPurchasableItemsForPlace(place.id, fetchComponent));
  }, [dispatch, place.id, fetchComponent]);

  const onDeletePlaceClicked = useCallback(() => {
    dispatch(deletePlaces([place.id], fetchComponent));
  }, [dispatch, place.id, fetchComponent]);

  const setFullName = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(addPlacesPersonalization(place.id, eventArgs.target.value, null, null));
    },
    [dispatch, place.id, fetchComponent],
  );

  const setPhoneNumber = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
        const regex = RegExp('^[+]?[0-9( )\\/-]*$');

        if (regex.exec(eventArgs.target.value) !== null) {
            dispatch(addPlacesPersonalization(place.id, null, eventArgs.target.value, null));
        }
    },
    [dispatch, place.id, fetchComponent],
  );

  const setSeasonTicketLegalRecipientId = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(addPlacesPersonalization(place.id, null, null, eventArgs.target.value));
    },
    [dispatch, place.id, fetchComponent],
  );

  const getFullName = (): string =>
    placesPersonalization.placesPersonalization[index]?.fullName ?? '';

  const getPhoneNumber = (): string =>
    placesPersonalization.placesPersonalization[index]?.phoneNumber ?? '';

  const getSeasonTicketLegalRecipientId = (): string =>
    placesPersonalization.placesPersonalization[index]?.seasonTicketLegalRecipientId ?? '';

  const placeIsExpired = place.expiresAt < new Date();

  return (
    <div className={`${style.Ticket} ${reseating ? style.TicketReseating : ''}`}>
      <strong>
        {place.selectedPurchasableItem
          ? place.selectedPurchasableItem?.publicName + ' ' + place.pricingCategory.name
          : place.pricingCategory.name}
      </strong>

      <div className={style.SpreadRow}>
        <div>
          {
              (venueEvent.venueEvent?.personalPhoneNumberOnTicketShouldBeRequired && index === 0) && (
              <>
                <p className={style.Label}>{strings.Tickets_Item_PersonalizationPhoneNumber}</p>
                <input
                  type="tel"
                  data-testid={`phone${index}`}
                  onChange={setPhoneNumber}
                  value={getPhoneNumber()}
                />
                  {
                      getPhoneNumber().length < 1 && !placeIsExpired ? (
                          <p className={style.Warning} data-testid={`warning${index}`}>
                              {strings.Tickets_Item_Phone_Number_Required}
                          </p>
                      ) :
                      (
                          <div className={style.Margin} data-testid={`margin${index}`}></div>
                      )
                  }
              </>
            )
          }
          {
            venueEvent.venueEvent?.seasonTicketLegalRecipientShouldBeCollected && (
              <>
                <p className={style.Label}>{strings.Tickets_Item_SeasonTicketLegalRecipientId}</p>
                <input
                  type="text"
                  onChange={setSeasonTicketLegalRecipientId}
                  value={getSeasonTicketLegalRecipientId()}
                />
                <div className={style.Margin} data-testid={`margin${index}`}></div>
              </>
            )
          }

          {
              venueEvent.venueEvent?.personalDataOnTicketShouldBeCollected && (
              <>
                <p className={style.Label}>{strings.Tickets_Item_PersonalizationData_Label}</p>
                <input
                  type="text"
                  data-testid={`name${index}`}
                  onChange={setFullName}
                  readOnly={placeIsExpired}
                  value={getFullName()}
                />
                {
                  venueEvent.venueEvent?.personalDataOnTicketIsRequired
                  && getFullName().length < 1 && !placeIsExpired ? (
                      <p className={style.Warning} data-testid={`warning${index}`}>
                        {strings.Tickets_Item_Name_Required}
                      </p>
                    ) :
                    (
                      <div className={style.Margin} data-testid={`margin${index}`}></div>
                    )
                }

              </>
            )
          }

          {place.blockType === 'seating' ? (
            <>
              <p>
                {strings.Shared_Block}&nbsp;{place.blockLabel}{', '}
                {strings.Shared_Row}&nbsp;{place.rowLabel}{', '}
                {strings.Shared_Seat}&nbsp;{place.seatLabel}
              </p>
            </>
          ) : (
            <>
              <p>
                  {strings.Shared_Block}&nbsp;{place.blockLabel}
              </p>
            </>
          )}
          {place.rightsProvider ? (
            <p>
              {strings.Tickets_Item_Rights_Provider}{' '}
              {place.rightsProvider.emailOrEfId}
            </p>
          ) : null}

          {!otherPerson &&
            <>
              {place.selectedPurchasableItem && (purchasableItemsState?.placeId !== place.id
                || (purchasableItemsState?.placeId === place.id && !purchasableItemsState?.purchasableItems)) ? (
                <div className={style.SelectedPurchasableItemContainer}>
                  <div className={style.SelectedPurchasableItem}>
                    {place.selectedPurchasableItem.pricingClass.publicName}
                  </div>
                  { (purchasableItemsState?.placeId !== place.id || !purchasableItemsState?.purchasableItems.length)
                  && !placeIsExpired && <Button
                      fetchIndicator={fetchIndicator}
                      onClick={onChangePurchasableItemClicked}
                      variant="sub"
                  >
                      {strings.Tickets_Item_Change}
                  </Button> }
                </div>
              ) : (
                <Select
                  className={style.PurchasableItemsSelect}
                  onChange={onSelectedPurchasableItemChanged}
                  value={place.selectedPurchasableItem ? place.selectedPurchasableItem.id : ''}
                  testid={`selectPurchasableItem${index}`}
                  forwardRef={purchasableItemsRef}
                >
                  {purchasableItemsState?.purchasableItems.map((purchasableItem, key) => (
                    <option
                      key={key} value={purchasableItem.id}
                      onClick={onPurchasableItemOptionClicked}
                    >
                      {`${purchasableItem.pricingClass.publicName} ${(
                        (purchasableItem.grossAmount ?? 0) / 100
                      ).toLocaleString(language, {
                        currency: 'EUR',
                        style: 'currency',
                      })}`}
                    </option>
                  ))}
                </Select>
              )}
            </>
          }
        </div>
        {!placeIsExpired && !otherPerson && <strong>
          {(
            (place.selectedPurchasableItem?.grossAmount ?? 0) / 100
          ).toLocaleString(language, {
            currency: 'EUR',
            style: 'currency',
          })}
        </strong>}
      </div>
      <div className={style.SpreadRow}>
        {!placeIsExpired && place.expiresAt ? <Countdown to={place.expiresAt}/> : null}
        {placeIsExpired && <span className={style.ExpiredRow}>{strings.Tickets_Item_Expired}</span>}
        <Button
          fetchIndicator={fetchIndicator}
          onClick={onDeletePlaceClicked}
          variant="sub"
        >
            {strings.Tickets_Item_Remove}
        </Button>
      </div>
      {place.placeInfoTitle && <div><span className={style.PlaceInfo}>{place.placeInfoTitle}</span></div>}
      {place.placeInfoText && <div><span className={style.PlaceInfo}>{place.placeInfoText}</span></div>}
      {place.bundleMessage && <div><span className={style.BundleMessageRow}>{place.bundleMessage}</span></div>}
    </div>
  );
};

export default TicketComponent;
