import { PricingCategory } from './PricingCategory';
import { PricingClass } from './PricingClass';

export const PurchasableItemSelectedIdentifier = Symbol();

interface PurchasableItemBase {
  id: string;
  publicName: string;

  grossAmount: number;
}

export interface PurchasableItemFlat extends PurchasableItemBase {
  pricingClassId: string;
  pricingCategoryId: string;
}

export interface PurchasableItem extends PurchasableItemBase {
  pricingCategory: PricingCategory;
  pricingClass: PricingClass;
}

export interface PurchasableItemSelectedFlat
  extends PurchasableItemBase {
  pricingCategoryId: string;
  pricingClassId: string;
}

export interface PurchasableItemSelected extends PurchasableItemBase {
  pricingCategory: PricingCategory;
  pricingClass: PricingClass;

  [PurchasableItemSelectedIdentifier]: true;
}
