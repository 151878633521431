import { VenueSelectedPlace } from '../../components/VenuePlan';
import { Action, ActionType, PlacesReseatingState, Place } from './types';

const initialState: PlacesReseatingState = {
  places: [],
	pricingCategories: [],
	pricingClasses: [],
	venuePlaces: [],
  selectedPlaces: []
};

export const reducer = (state = initialState, action: Action): PlacesReseatingState => {
  switch (action.type) {
    case ActionType.SET_RESEATING: {
      return {
        ...state,
        ...action.payload.state
      };
    }

    case ActionType.TOGGLE_PLACE_MODE: {
      const { placeId, mode } = action.payload;
      const places: Place[] = state.places.map(
        (place) =>
        place.id === placeId ? {
           ...place, 
           mode: mode
          } : place
      );
      return {
        ...state,
        places
      };
    }

    case ActionType.DESELECT: {
      const { placeId } = action.payload;
      const venuePlaces: VenueSelectedPlace[] = state.venuePlaces.map(
        (place) =>
        place.place.id === placeId ? {
           ...place, 
           status: 'reseating'
          } : place
      );
      return {
        ...state,
        venuePlaces
      };
    }
    
    case ActionType.SELECT: {
      const { placeId } = action.payload;
      const venuePlaces: VenueSelectedPlace[] = state.venuePlaces.map(
        (place) =>
        place.place.id === placeId ? {
           ...place, 
           status: 'reseating-selected'
          } : place
      );
      return {
        ...state,
        venuePlaces
      };
    }

    case ActionType.COMPLETE: {
      const { placeId } = action.payload;
      const venuePlaces: VenueSelectedPlace[] = state.venuePlaces.map(
        (place) =>
          place.place.id === placeId ? {
            ...place,
            status: 'reseating-completed'
          } : place
      );
      return {
        ...state,
        venuePlaces
      };
    }

    case ActionType.ADD_SELECTED_PLACE: {
      const { placeId, reseatingContractId } = action.payload;
      const unshift = action.options?.unshift || false;

      const selectedPlaces = unshift
        ? [{ placeId, reseatingContractId }, ...state.selectedPlaces]
        : [...state.selectedPlaces, { placeId, reseatingContractId }];

      return {
        ...state,
        selectedPlaces: selectedPlaces
      };
    }

    case ActionType.REMOVE_SELECTED_PLACE: {
      const { placeId } = action.payload;
      return {
        ...state,
        selectedPlaces: state.selectedPlaces.filter((selectedPlaceId) => selectedPlaceId.placeId !== placeId),
      };
    }

    default: {
      return state;
    }
  }
}; 