import React, {useEffect, useState} from 'react';
import style from './style.module.css';
import Modal from 'react-modal';
import {addStandingPlace} from '../../state/TicketSelection';
import {useDispatch} from 'react-redux';
import {BlockSelectionEvent} from '../VenuePlan/interaction';
import {useLocale} from '../../state/Localization';
import {hydrateString} from '../../util/localization';

if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
}

type StandingPlaceSelectionProps = {
    isShowModalChooseStandingPlace: boolean;
    setIsShowModalChooseStandingPlace:  React.Dispatch<React.SetStateAction<boolean>>;
    selectedBlockForStandingPlace:  BlockSelectionEvent | undefined;
    availablePlacesInSelectedBlockForStandingPlace: number;
    placesInCurrentBlock: number;
};

/**
 * Select a standing place modal
 */
const StandingPlaceSelection: React.FC<StandingPlaceSelectionProps> = (props) => {
    const NUMBER_REGEX = RegExp('^[0-9]+$');

    const {
        isShowModalChooseStandingPlace,
        setIsShowModalChooseStandingPlace,
        selectedBlockForStandingPlace,
        availablePlacesInSelectedBlockForStandingPlace,
        placesInCurrentBlock
    } = props;

    const [countInput, setCountInput] = useState(1);
    const dispatch = useDispatch();
    const { strings } = useLocale();

    useEffect(() => {
        setCountInput((availablePlacesInSelectedBlockForStandingPlace) ? 1 : 0);
    }, [isShowModalChooseStandingPlace]);

    function closeModal(): void {
        setIsShowModalChooseStandingPlace(false);
        setCountInput(1);
    }

    function setCount(newCount: number): void {
        setCountInput(Math.max(0, Math.min(newCount, availablePlacesInSelectedBlockForStandingPlace)));
    }

    function handleClickMinus(): void {
        setCount(countInput - 1);
    }

    function handleClickPlus(): void {
        setCount(countInput + 1);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (NUMBER_REGEX.test(e.target.value)) {
            setCount(parseInt(e.target.value,10));
        }
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (selectedBlockForStandingPlace?.blockId) {
            dispatch(addStandingPlace(selectedBlockForStandingPlace.blockId, undefined, countInput));
        }
        closeModal();
    };

    return (
        <div>
            <Modal
                isOpen={isShowModalChooseStandingPlace}
                onRequestClose={closeModal}
                className={style.Modal}
                overlayClassName={style.Overlay}
            >
                <button onClick={closeModal} className={style.CloseButton}>
                    X {strings.StandingPlaceSelection_Cancel}
                </button>
                <h2 className={style.Title}>Block {selectedBlockForStandingPlace?.blockName}</h2>

                {(placesInCurrentBlock) ?
                    <div>
                        {hydrateString(strings.StandingPlaceSelection_AlreadySelected, {
                            blockName: selectedBlockForStandingPlace?.blockName ?? '',
                            capacity: placesInCurrentBlock.toString()
                        })}
                    </div>
                    : ''
                }

                <h5 className={style.SubTitle}>{strings.StandingPlaceSelection_Header}</h5>
                <form onSubmit={(e) => handleFormSubmit(e)}>
                    <div className={style.InputBlock}>
                        <span className={style.Minus} onClick={handleClickMinus}>-</span>
                        <input
                            className={style.Input}
                            value={countInput}
                            onChange={(e) => handleInputChange(e)}
                        />
                        <span className={style.Plus} onClick={handleClickPlus}>+</span>
                    </div>
                    <button className={style.SubmitButton} disabled={countInput === 0}>
                        {strings.StandingPlaceSelection_Accept}
                    </button>
                </form>
            </Modal>
        </div>
    );
};

export default StandingPlaceSelection;
