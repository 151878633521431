import {PricingCategory, PricingClass, PurchasableItem} from '../../TicketSelection';

export enum ActionType {
  SET_PURCHASABLE_ITEMS = 'TICKET_SELECTION::SET_PURCHASABLE_ITEMS',
}
export interface PurchasableItems {
  placeId: string;
  purchasableItems: PurchasableItem[];
  pricingCategories: PricingCategory[];
  pricingClasses: PricingClass[];
}

export type PurchasableItemsState = PurchasableItems | null;

interface SetPurchasableItemsAction {
  payload: { state: PurchasableItems };
  type: typeof ActionType.SET_PURCHASABLE_ITEMS;
}

export type Action = SetPurchasableItemsAction;