import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from './state';
import { addAccessTokenChangeListener, AuthenticatedState, checkAuthenticationState, redirectToLogin} from './auth';
import { setJWT } from './state/Session';

function setupApplication(state: AuthenticatedState): void {
  const store = createStore();

  // prepopulate the JWT in the store.
  store.dispatch(setJWT(state.accessToken));

  // should the access token changed due to silent renews, etc... we also need
  // to reflect that change in the store.
  addAccessTokenChangeListener((accessToken) => {
    store.dispatch(setJWT(accessToken));
  });

  // "redirect" to the deep link from before making the roundtrip through the SSO
  if (state.context) {
    const url = [state.context.pathname, state.context.search, state.context.hash].join('');
    window.history.replaceState(undefined, process.env.REACT_APP_TITLE ?? '', url);
  }

  ReactDOM.render(<App store={store}/>, document.getElementById('root'));
}

function checkUrlRmv(): void {
  const location = window.location;
  if (location.pathname === '/rmv' || location.pathname === '/rmv/') {
    const store = createStore();
    ReactDOM.render(<App store={store}/>, document.getElementById('root'));
  } else {
    // any interaction with the app requires an authenticated user;
    // therefore redirect to login if the user is unauthenticated
    redirectToLogin();
  }
}

// Before even attempting to setup the app we handle all the authentication
// stuff. This way we avoid pulling up the whole app only to notice that we
// need to redirect to the SSO provider anyway or if it was a renew callback.
checkAuthenticationState().then((state) => {
  switch (state.type) {
    case 'SILENT_RENEW':
      // nothing else needs to be done; parent frame will be notified
      // of the renew by the OIDC client library
      return;
    case 'ANONYMOUS':
      return checkUrlRmv();
    case 'AUTHENTICATED':
      return setupApplication(state);
  }
});
