import { VenueBlockData, BlockReseatingState } from './data';
import { Container, Graphics, Point, Polygon, Sprite } from 'pixi.js';
import {
  getBlockLabelTexture,
  getNumericLabelTexture,
  getSeatTexture,
  NUMERIC_LABEL_OVERFLOW_NAME,
  NUMERIC_LABEL_OVERFLOW_THRESHOLD,
} from './resources';
import {
  TINT_BLOCK_LABEL,
  TINT_BLOCK_OUTLINE,
  TINT_ROW_LABEL,
  TINT_SEAT_LABEL_AVAILABLE,
  TINT_SEAT_LABEL_SELECTED,
  TINT_BLOCK_RESEATING_LABEL
} from './colors';
import { BLOCK_LABEL_SIZE, ROW_LABEL_SIZE, TEXT_ANCHOR } from './constants';
import { checkIfRectFitsWithinPolygon, circleCollidesPolygon } from './utils';
import Color from 'color';
import { PlaceGraphicalState } from './PlaceGraphicalState';

const BLOCK_BADGE_SIZE = 1.5;
const BLOCK_BADGE_LABEL_SIZE = BLOCK_BADGE_SIZE * 0.65;

export type BlockType =
  | 'seating'
  | 'standing'

export type BlockStatus =
  | 'AVAILABLE'
  | 'UNAVAILABLE'

export type BlockReseatingStatus =
  | 'AVAILABLE'
  | 'UNAVAILABLE'
  | 'SELECTED'
  | 'COMPLETED'

function createAmountBadge(): Container {
  const badge = new Sprite(getSeatTexture(PlaceGraphicalState.UNKNOWN));
  badge.width = BLOCK_BADGE_SIZE;
  badge.height = BLOCK_BADGE_SIZE;
  badge.anchor = TEXT_ANCHOR;
  return badge;
}

function createAmountLabel(): Sprite {
  const label = new Sprite(getNumericLabelTexture('0'));
  label.width = BLOCK_BADGE_LABEL_SIZE;
  label.height = BLOCK_BADGE_LABEL_SIZE;
  label.anchor = TEXT_ANCHOR;
  label.tint = 0x000000;
  return label;
}

function createReseatingBadge(): Sprite {
  const badge = new Sprite(getSeatTexture(PlaceGraphicalState.UNKNOWN));
  badge.width = BLOCK_BADGE_SIZE;
  badge.height = BLOCK_BADGE_SIZE;
  badge.anchor = TEXT_ANCHOR;
  badge.tint = TINT_BLOCK_RESEATING_LABEL;
  return badge;
}

function createReseatingBadgeBackground(): Sprite {
  const badge = new Sprite(getSeatTexture(PlaceGraphicalState.UNKNOWN));
  badge.width = BLOCK_BADGE_SIZE * .9;
  badge.height = BLOCK_BADGE_SIZE * .9;
  badge.anchor = TEXT_ANCHOR;
  return badge;
}

function createReseatingLabel(): Sprite {
  const label = new Sprite(getNumericLabelTexture('0'));
  label.width = BLOCK_BADGE_LABEL_SIZE;
  label.height = BLOCK_BADGE_LABEL_SIZE;
  label.anchor = TEXT_ANCHOR;
  label.tint = TINT_SEAT_LABEL_AVAILABLE;
  return label;
}

interface BlockLabel {
  readonly label: Container;
  readonly badge: Container;
  readonly amount: Sprite;
  readonly reseatingBadgeContainer: Container;
  readonly reseatingBadge: Sprite;
  readonly reseatingAmount: Sprite;
}

/**
 * Represents a block of seats or standing places.
 */
export class Block {

  private _status: BlockStatus = 'UNAVAILABLE';
  private _selectedAmount = 0;
  private _reseatingState: BlockReseatingState = {
    status: 'UNAVAILABLE',
    amount: 0
  };

  constructor(
    readonly id: string,
    readonly type: BlockType,
    readonly name: string,
    readonly color: number,
    readonly outlineGraphics: Graphics,
    readonly rowLabels: Container,
    private readonly outlines: number[][],
    private readonly blockLabel: BlockLabel,
  ) {
    this.selectedAmount = 0;
    this.reseatingStatus = {
      amount: 0,
      status: 'UNAVAILABLE'
    };
  }

  get status(): BlockStatus {
    return this._status;
  }

  set status(status) {
    this._status = status;

    if (this.type == 'standing') {
      this.outlineGraphics.tint = status === 'AVAILABLE' ? this.color : TINT_BLOCK_OUTLINE;
    }
  }

  get selectedAmount(): number {
    return this._selectedAmount;
  }

  set selectedAmount(selectedAmount: number) {
    this._selectedAmount = selectedAmount;

    const isBadgeVisible = (this.type === 'standing') && (selectedAmount > 0);
    this.blockLabel.badge.visible = isBadgeVisible;

    const label = selectedAmount > NUMERIC_LABEL_OVERFLOW_THRESHOLD
      ? NUMERIC_LABEL_OVERFLOW_NAME
      : selectedAmount.toFixed(0);

    this.blockLabel.amount.texture = getNumericLabelTexture(label);
  }

  get reseatingStatus(): BlockReseatingState {
    return this._reseatingState;
  }

  set reseatingStatus(reseatingStatus: BlockReseatingState) {
    const { amount, status } = reseatingStatus;

    const isBadgeVisible = this.type === 'standing' && status !== 'UNAVAILABLE';
    this.blockLabel.reseatingBadgeContainer.visible = isBadgeVisible;

    if (!isBadgeVisible) return;

    const label = amount > NUMERIC_LABEL_OVERFLOW_THRESHOLD
      ? NUMERIC_LABEL_OVERFLOW_NAME
      : amount.toFixed(0);

    this.blockLabel.reseatingAmount.texture = getNumericLabelTexture(label);

    let graphicalState: PlaceGraphicalState;

    switch (status) {
      case 'COMPLETED':
        graphicalState = PlaceGraphicalState.RESEATING_COMPLETED;
        break;
      case 'SELECTED':
        graphicalState = PlaceGraphicalState.RESEATING_SELECTED;
        break;
      default:
        graphicalState = PlaceGraphicalState.RESEATING_AVAILABLE;
    }

    this._reseatingState = reseatingStatus;
    this.blockLabel.reseatingBadge.texture = getSeatTexture(graphicalState);

    this.blockLabel.reseatingAmount.tint = status === 'AVAILABLE'
      ? TINT_SEAT_LABEL_AVAILABLE
      : TINT_SEAT_LABEL_SELECTED;
  }

  get label(): Container {
    return this.blockLabel.label;
  }

  /**
   * Check if this block intersects with a given circle.
   *
   * @param c The center of the circle to check against.
   * @param r The radius of the circle to check against.
   */
  intersectsCircle(c: Point, r: number): boolean {
    // Check every polygon that makes up the block against the circle.
    // It would be wrong to shortcut this and check for example only
    // against the convex hull of the block as the circle could fit
    // "between" the polygons without any intersections.
    // TODO: There could be a more efficient algorithm for this.
    for (const outline of this.outlines) {
      if (circleCollidesPolygon(c, r, new Polygon(outline))) {
        return true;
      }
    }

    return false;
  }

}

function createBlockLabelSprite(blockLabel: string): Sprite {
  const texture = getBlockLabelTexture(blockLabel);
  const sprite = new Sprite(texture);
  sprite.tint = TINT_BLOCK_LABEL;
  sprite.anchor = TEXT_ANCHOR;

  return sprite;
}

function createBlockOutline(block: VenueBlockData): Graphics {
  // create outline
  const blockOutline = new Graphics();
  blockOutline.lineStyle(0.1, 0xFFFFFF);

  // standing blocks are displayed as filled
  if (block.type == 'standing') {
    blockOutline.beginFill(0xFFFFFF);
  }

  block.outlines.forEach((o) => {
    blockOutline.drawPolygon(o);
  });

  blockOutline.endFill();
  blockOutline.tint = TINT_BLOCK_OUTLINE;
  return blockOutline;
}

function createRowLabelSprite(rowLabel: string): Sprite {
  const sprite = new Sprite(getNumericLabelTexture(rowLabel));
  sprite.tint = TINT_ROW_LABEL;
  sprite.anchor = TEXT_ANCHOR;
  sprite.width = ROW_LABEL_SIZE;
  sprite.height = ROW_LABEL_SIZE;

  return sprite;
}

function createBlockRowLabels(block: VenueBlockData): Container {
  // add row labels
  const blockRowLabelsContainer = new Container();

  // row labels should not be displayed for standing blocks
  if (block.type == 'standing') {
    return blockRowLabelsContainer;
  }

  block.rowLabels.forEach((rowLabelData) => {
    const rowNameText = createRowLabelSprite(rowLabelData.name);
    rowNameText.x = rowLabelData.x;
    rowNameText.y = rowLabelData.y;
    blockRowLabelsContainer.addChild(rowNameText);
  });

  return blockRowLabelsContainer;
}

function createBlockLabel(block: VenueBlockData): BlockLabel {
  const labelContainer = new Container();
  const badgeContainer = new Container();
  const reseatingBadgeContainer = new Container();
  const labelSprite = createBlockLabelSprite(block.name);
  const badgeSprite = createAmountBadge();
  const amountSprite = createAmountLabel();
  const reseatingBadgeSprite = createReseatingBadge();
  const reseatingBadgeBackground = createReseatingBadgeBackground();
  const reseatingAmountSprite = createReseatingLabel();

  labelContainer.addChild(labelSprite, badgeContainer, reseatingBadgeContainer);
  badgeContainer.addChild(badgeSprite, amountSprite);
  reseatingBadgeContainer.addChild(reseatingBadgeBackground, reseatingBadgeSprite, reseatingAmountSprite);

  labelContainer.x = block.labelPosition.x;
  labelContainer.y = block.labelPosition.y;

  const labelTextureWidth = labelSprite.texture.width;
  const labelTextureHeight = labelSprite.texture.height;
  // preserve aspect ratio when scaling the block labels.
  const labelScale = BLOCK_LABEL_SIZE / labelTextureHeight;

  badgeContainer.x = labelTextureWidth / 2;
  badgeContainer.y = -labelTextureHeight / 2;
  reseatingBadgeContainer.x = labelTextureWidth / 2;
  reseatingBadgeContainer.y = labelTextureHeight / 2;


  // Check if the label fits neatly inside the block's outline.
  // Otherwise, we scale the label down even more.
  // This check only makes sense for simple blocks, i.e. those that consist
  // only of a single polygon. For multi-part blocks the label will have
  // to be placed by hand to make sense anyway.
  const isNormalScale = (block.outlines.length != 1) || checkIfRectFitsWithinPolygon(
    block.labelPosition.x,
    block.labelPosition.y,
    labelTextureWidth * labelScale * 1.1,
    labelTextureHeight * labelScale * 1.1,
    block.outlines[0]);

  badgeContainer.scale.set(1 / labelScale);
  reseatingBadgeContainer.scale.set(1 / labelScale);
  labelContainer.scale.set(isNormalScale ? labelScale : labelScale * 0.5);

  return {
    label: labelContainer,
    badge: badgeContainer,
    amount: amountSprite,
    reseatingBadgeContainer: reseatingBadgeContainer,
    reseatingBadge: reseatingBadgeSprite,
    reseatingAmount: reseatingAmountSprite,
  };
}

export function createBlockGraphic(block: VenueBlockData): Block {
  const blockLabel = createBlockLabel(block);
  const blockOutline = createBlockOutline(block);
  const blockRowLabelsContainer = createBlockRowLabels(block);

  return new Block(
    block.id,
    block.type,
    block.name,
    Color(block.color).rgbNumber(),
    blockOutline,
    blockRowLabelsContainer,
    block.outlines,
    blockLabel
  );
}

