import { createFetchActions, FetchComponent } from '../../Fetch';
import { apiGETRequest, generateURL } from '../../../util/apiRequest';
import { API_ENDPOINTS } from '../../../config.json';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { ActionType as TicketSelectionActionType } from '../index';
import { TicketSelectionStateAPIResponse } from '../types/TicketSelectionStateAPIResponse';
import { ensureBasicState } from '../utils/ensureBasicState';
import { handleError } from '../../../util/handleError';
import { mapTicketSelectionStateAPIResponse } from '../types/mapTicketSelectionStateAPIResponse';
import {setPlacesPersonalization} from '../../PlacePersonalization';

export const getState = (
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      isPurchaseOptions,
      userId,
      venueEventId,
      organizationId
    } = ensureBasicState(
        appState,
    );

    const endpoint = isPurchaseOptions ? API_ENDPOINTS.GET_PURCHASE_OPTIONS_STATE : API_ENDPOINTS.GET_STATE;
    const requestURL = generateURL(endpoint, {
      params: { venueEventId },
      query: { purchaseForTicketHolderId, salesChannel, salesRuleId, subscriptionId, organizationId },
    });
    const stateResponse = (await apiGETRequest(requestURL)) as TicketSelectionStateAPIResponse;
    const state = mapTicketSelectionStateAPIResponse(stateResponse, userId);
    dispatch({ payload: { state }, type: TicketSelectionActionType.SET_STATE });
    if(
        state.places.length !== appState.placesPersonalization.placesPersonalization.length
    ){
      dispatch(setPlacesPersonalization());
    }
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch(fetchStop);
  }
};
