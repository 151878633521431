import {
  FetchComponent,
  FetchIndicator,
  FetchScope,
  createFetchComponent,
} from './';
import { useMemo } from 'react';
import { useSelector } from '../useSelector';

export const useFetch = (
  scope: FetchScope = FetchScope.ALL,
): { fetchComponent: FetchComponent; fetchIndicator: FetchIndicator } => {
  const component = useMemo(() => createFetchComponent(scope), [scope]);
  const state = useSelector((state) => state.fetch);
  const hasFocus = state.focus === component.id;

  let fetching = false;
  if (state.components.get(component.id)) {
    fetching = true;
  }
  if (scope !== FetchScope.SINGLE && (state.activeScopes & scope) === scope) {
    fetching = true;
  }

  return { fetchComponent: component, fetchIndicator: { fetching, hasFocus } };
};
