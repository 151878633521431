import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { API_ENDPOINTS } from '../../config.json';
import { ActionType } from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleActionError } from '../../util/handleActionError';

export const getBuildInfo = (): AsyncReduxThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(API_ENDPOINTS.GET_BUILD_INFO, {});

    const buildInfo = await apiGETRequest(requestURL, true);

    dispatch({ payload: { buildInfo }, type: ActionType.SUCCESS });
  } catch (error) {
    dispatch({ payload: { error }, type: ActionType.ERROR });
    dispatch(handleActionError(error));
  }
};
