import React, { useEffect } from 'react';
import style from './style.module.css';
import { getBuildInfo, useBuildInfo } from '../../state/BuildInfo';
import { useDispatch } from 'react-redux';

const DEPLOYMENT_GIT_COMMIT = process.env.REACT_APP_DEPLOYMENT_GIT_COMMIT;
const DEPLOYMENT_TIME = process.env.REACT_APP_DEPLOYMENT_TIME;

const BuildInfoComponent: React.FC = () => {
  const { buildInfo } = useBuildInfo();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBuildInfo());
  }, [dispatch]);

  const userAgent = navigator.userAgent; // Accessing user agent

  if(buildInfo && buildInfo.environment && (buildInfo.environment === 'development' || buildInfo.environment === 'test')){

    return (
      <div className={style.BuildInfo}>
        {
          buildInfo &&
          buildInfo.environment ? (
            <span>
                          Environment:{' '}
              <strong>{buildInfo.environment}</strong>
                      </span>
          ) : null
        }
        {
          buildInfo && buildInfo.deploymentTime ? (
            <span>
                          API built on:{' '}
              <strong>{new Date(buildInfo.deploymentTime).toLocaleString('de-de')}</strong>
                      </span>
          ) : null
        }
        {buildInfo && buildInfo.gitCommit ? (
          <span>
                      API commit:{' '}
            <strong>{buildInfo.gitCommit}</strong>
                  </span>
        ) : null
        }
        {DEPLOYMENT_TIME ? (
          <span>
                      Frontend built on:{' '}
            <strong>{new Date(DEPLOYMENT_TIME).toLocaleString('de-de')}</strong>
                  </span>
        ) : null
        }
        {DEPLOYMENT_GIT_COMMIT ? (
          <span>
                    Frontend commit: <strong>{DEPLOYMENT_GIT_COMMIT}</strong>
                  </span>
        ) : null
        }

        <span>User Agent: <strong>{userAgent}</strong></span> {/* Displaying user agent */}
      </div>
    );
  } else {
    return (
        <div className={style.Placeholder}></div>
    );
  }
};
export default BuildInfoComponent;
