import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {loadPurchasableItemsForPlace} from '../../state/PurchasableItems';
import {Place, useTicketSelection} from '../../state/TicketSelection';
import {ChangeInputCallback} from './purchaseOptionsList';
import PurchaseOptionsListPricingClassComponent from './purchaseOptionsListPricingClass';
import style from './style.module.css';

interface CheckedPlace {
  placeId: string;
}

const PurchaseOptionsListItemComponent: React.FC<{
  place: Place;
  deleteItemHandle: (id: string) => void;
  placeGetLabel: (place: Place | null, type: 'block' | 'row' | 'seat') => string;
  checkedPlaces: CheckedPlace[];
  checkPlace: ChangeInputCallback;
}> =
  ({
     place,
     deleteItemHandle,
     placeGetLabel,
     checkedPlaces,
     checkPlace,
   }) => {
    const dispatch = useDispatch();
    const {fetchComponent, fetchIndicator} = useFetch();
    const ticketSelection = useTicketSelection();
    const {language, strings} = useLocale();


    const onChangePurchasableItemClicked = useCallback((placeId: string) => {
      dispatch(loadPurchasableItemsForPlace(placeId, fetchComponent));
    }, [dispatch, fetchComponent]);

    const isCheckedPlace = (id: string): boolean => {
      const place = checkedPlaces.find((el) => {
        return el.placeId === id;
      });

      return !!place;
    };

    if (!ticketSelection?.places || ticketSelection?.places.length === 0) {
      return <></>;
    }

    if ((fetchIndicator.fetching && fetchIndicator.hasFocus)) {
      return (
        <tr>
          <td colSpan={8} style={{textAlign: 'center'}}>
            <div className={style.Loader}></div>
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <td>
          <input
            type="checkbox"
            checked={isCheckedPlace(place.id)}
            onChange={checkPlace}
            value={place.id}
          />
        </td>
        <td className={style.ShowWeb}>{place.blockLabel}</td>
        <td className={style.ShowWeb}>{placeGetLabel(place, 'row')}</td>
        <td className={style.ShowWeb}>{placeGetLabel(place, 'seat')}</td>
        <td className={`${style.ShowMobile} ${style.RowLabelCell}`}>
            <div>{strings.Shared_Block}: {placeGetLabel(place, 'block')}</div>
            <div>{strings.Shared_Row}: {placeGetLabel(place, 'row')}</div>
            <div>{strings.Shared_Seat}: {placeGetLabel(place, 'seat')}</div>
        </td>
        <td>
            {place.personalizationFullName}
        </td>
        <td>
            <PurchaseOptionsListPricingClassComponent place={place} fetchComponent={fetchComponent}/>
        </td>
        <td>{place.expiresAt.toLocaleString(language, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}, {place.expiresAt.toLocaleString(language, {
          hour: '2-digit',
          minute: '2-digit',
        })} Uhr
        </td>
        <td className={style.ActionCell}>
            {!place.isDenyPricingClassChange &&
                <span
                    className={place.isPurchaseObligations ? style.ChangePriceClassButton : style.ChangePriceClassButton}
                    onClick={(): void => onChangePurchasableItemClicked(place.id)}
                >
                {strings.PurchaseOptionsListItem_ChangePriceClass}
                </span>
            }

          {!place.isPurchaseObligations &&
          <span className={style.DeleteButton} onClick={(): void => deleteItemHandle(place.id)}>
            {strings.PurchaseOptionsListItem_Reject}
          </span>
          }
        </td>
      </tr>
    );
  };

export default PurchaseOptionsListItemComponent;
