import {ensureBasicState} from '../TicketSelection/utils/ensureBasicState';
import {getVenueEvent} from '../VenueEvent';
import {
  ActionType,
  Message,
  MessageWithId,
  RemoveMessageAction,
} from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import asyncTimeout from '../../util/asyncTimeout';
import { v4 as uuidv4 } from 'uuid';

export const addMessage = (
  messageWithoutId: Message,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const messageId = uuidv4();
  const message: MessageWithId = { ...messageWithoutId, id: messageId };
  dispatch({
    payload: { message },
    type: ActionType.ADD,
  });

  const appState = getState();
  const {salesChannel, venueEventId, queueittoken, userId } = ensureBasicState(
    appState,
  );

  switch (message.action) {
    case 'loadVenueEvent':
      dispatch(getVenueEvent(venueEventId, salesChannel, queueittoken, userId));
      break;
    // case 'loadPlaceSelection':
    //   //todo CHANGE IT TO LOAD PLACE SELECTION
    //   dispatch(getVenueEvent(venueEventId, salesChannel));
    //   break;
  }

  if (message.timeout && message.timeout > 0) {
    await asyncTimeout(message.timeout);
    dispatch({ payload: { messageId }, type: ActionType.REMOVE });
  }
};

export const removeMessage = (messageId: string): RemoveMessageAction => ({
  payload: { messageId },
  type: ActionType.REMOVE,
});
