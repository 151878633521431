import React, { useEffect, useState } from 'react';
import { useLocale } from '../../state/Localization';
import { hydrateString } from '../../util/localization';
import style from './style.module.css';

const getSeconds = (date: Date): number => Math.round(date.getTime() / 1000);
const getNow = (): number => getSeconds(new Date());

const CountdownComponent: React.FC<{ to: Date }> = ({ to }) => {
  const { strings } = useLocale();
  const [now, setNow] = useState(getNow());
  useEffect(() => {
    const timeout = setTimeout(() => setNow(getNow()), 1000);
    return (): void => clearTimeout(timeout);
  });
  const seconds = Math.max(getSeconds(to) - now, 0);
  const secondsUnit = seconds % 60;
  const minutesUnit = Math.floor(seconds / 60);
  return (
    <span className={style.TextSub}>
      {hydrateString(strings.Tickets_Item_ReservedFor, {
        minutes: minutesUnit.toString().padStart(2, '0'),
        seconds: secondsUnit.toString().padStart(2, '0'),
      })}
    </span>
  );
};

export default CountdownComponent;
