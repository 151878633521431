import { Action, ActionType, SessionState } from './types';
import jwtDecode from 'jwt-decode';


const initialState: SessionState = {
  arePlacesSubmitted: false,
  arePurchaseOptionsSubmitted: false,
  isPurchaseOptions: false
};

export const reducer = (state = initialState, action: Action): SessionState => {
  switch (action.type) {
    case ActionType.SET_JWT: {
      const { jwt } = action.payload;
      const jwtDecoded = jwtDecode<{ [key: string]: string }>(jwt);
      const user = {
        firstName: jwtDecoded.first_name,
        id: jwtDecoded.sub,
        lastName: jwtDecoded.last_name,
      };
      return {
        ...state,
        jwt,
        jwtDecoded,
        user,
      };
    }
    case ActionType.SET_SALES_CHANNEL: {
      const { salesChannel } = action.payload;
      return { ...state, salesChannel };
    }
    case ActionType.SET_SALES_RULE_ID: {
      const { salesRuleId } = action.payload;
      return { ...state, salesRuleId };
    }
    case ActionType.SET_PURCHASE_FOR_TICKET_HOLDER_ID: {
      const { purchaseForTicketHolderId } = action.payload;
      return { ...state, purchaseForTicketHolderId };
    }
    case ActionType.SET_SUBSCRIPTION_ID: {
      const { subscriptionId } = action.payload;
      return { ...state, subscriptionId };
    }
    case ActionType.SET_QUEUEITOKEN: {
      const { queueittoken } = action.payload;
      return { ...state, queueittoken };
    }
    case ActionType.SET_SELECTED_RIGHTS_PROVIDER: {
      const { selectedRightsProvider } = action.payload;
      return { ...state, selectedRightsProvider };
    }
    case ActionType.SET_IS_PURCHASE_OPTIONS: {
      const { isPurchaseOptions } = action.payload;
      return { ...state, isPurchaseOptions };
    }
    case ActionType.SET_ARE_PLACES_SUBMITTED: {
      const { arePlacesSubmitted } = action.payload;
      return { ...state, arePlacesSubmitted };
    }
    case ActionType.SET_ARE_PURCHASE_OPTIONS_SUBMITTED: {
      const { arePurchaseOptionsSubmitted } = action.payload;
      return { ...state, arePurchaseOptionsSubmitted };
    }
    case ActionType.SET_ACTION_CODE: {
      const { actionCode } = action.payload;
      return { ...state, actionCode };
    }
    case ActionType.SET_PLACE_SELECTION_ID: {
      const { placeSelectionId } = action.payload;
      return { ...state, placeSelectionId };
    }
    case ActionType.SET_ORGANIZATION_ID: {
      const { organizationId } = action.payload;
      return { ...state, organizationId };
    }
    case ActionType.SET_REDIRECT_URL: {
      const { redirectUrl } = action.payload;
      return { ...state, redirectUrl };
    }
    default: {
      return state;
    }
  }
};
