import {CheckedPlace} from '../../../components/PurchaseOptions/purchaseOptionsList';
import { createFetchActions, FetchComponent } from '../../Fetch';
import { apiPOSTRequest, generateURL, SelectionNotFoundError } from '../../../util/apiRequest';
import { API_ENDPOINTS } from '../../../config.json';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { ensureBasicState } from '../utils/ensureBasicState';
import { handleError } from '../../../util/handleError';
import {setArePurchaseOptionsSubmitted} from '../../Session';

export const submitPurchaseOptions = (
  checkedPlaces: CheckedPlace[],
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const { purchaseForTicketHolderId, salesChannel, venueEventId } = ensureBasicState(appState);
    const requestURL = generateURL(API_ENDPOINTS.POST_PURCHASE_OPTIONS, {
      params: { venueEventId },
      query: { purchaseForTicketHolderId, salesChannel },
    });

    const body = {places: checkedPlaces};
    await apiPOSTRequest(requestURL, body);
    dispatch(setArePurchaseOptionsSubmitted(true));
  } catch (error) {
    if (error instanceof SelectionNotFoundError) {
      //Seems like user clicked submit button multiply times
      dispatch(setArePurchaseOptionsSubmitted(true));
      return;
    }

    handleError(dispatch, error);
  } finally {
    dispatch(fetchStop);
  }
};
