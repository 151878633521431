import React from 'react';
import {API_ENDPOINTS} from '../../../config.json';
import {apiPOSTRequest, generateURL} from '../../../util/apiRequest';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import {handleError} from '../../../util/handleError';
import {createFetchActions, FetchComponent} from '../../Fetch';
import {addPlacesPersonalization, selectPersonalizationFullName} from '../../PlacePersonalization';
import {ActionType as TicketSelectionActionType} from '../types';
import {SelectedPlace} from '../../PlaceReseating/types';
import {Place} from '../types/Place';
import {mapTicketSelectionStateAPIResponse} from '../types/mapTicketSelectionStateAPIResponse';
import {TicketSelectionStateAPIResponse} from '../types/TicketSelectionStateAPIResponse';
import {ensureBasicState} from '../utils/ensureBasicState';
import {getState as getTicketSelectionState} from './getState';
import {useLocale} from '../../Localization';
import {toast} from 'react-toastify';
import {Strings} from '../../../util/localization';

const useShowSuccessToast = (
    places: Place[],
    seatId: string,
    strings: Strings,
    seasonTicketLegalRecipient?: string
  ): void => {
  const place = places.find((place) => place.id === seatId);

  if (!place) return;

  const content = <>
    <p><strong>{strings.ReseatingBasket_NewPlace}</strong></p>
    {seasonTicketLegalRecipient}<br/>
    {strings.Shared_Block} {place.blockLabel}
    {place.blockType === 'seating' &&
      <>, {strings.Shared_Row} {place.rowLabel}, {strings.Shared_Seat} {place.seatLabel}</>
    }
  </>;

  toast(content, {
    closeButton: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    progressStyle: { background: place.pricingCategory.color || '#009e0f' },
  });
};

export const addSeats = (
    seatsIds: string[],
    strings: Strings,
    fetchComponent?: FetchComponent
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);

  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      userId,
      venueEventId,
      actionCode,
      organizationId,
    } = ensureBasicState(
      appState,
    );
    const requestURL = generateURL(API_ENDPOINTS.ADD_PLACE, {
      params: { venueEventId },
      query: { actionCode, purchaseForTicketHolderId, salesChannel, salesRuleId, subscriptionId, organizationId },
    });

    let fullName = '';
    const phoneNumber = '';
    const seasonTicketLegalRecipientId = '';

    if (
      appState.ticketSelection?.places.length === 0
      && appState.venueEvent.venueEvent?.personalDataOnTicketShouldBeCollected
    ) {
      fullName = selectPersonalizationFullName(appState) ?? '';
    }

    let selectedPlace: SelectedPlace|undefined = undefined;

    if (salesChannel === 'reseating') {
      // Get first element from selected old places (first in first out)
      selectedPlace = appState.placesReseating.selectedPlaces[0];
      if (!selectedPlace) {
        toast.error(strings.Error_reseating_no_selection, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    }

    if (seatsIds.length === 1) {
      const seatId = seatsIds[0];

      const rightsProviderId = appState.session.selectedRightsProvider?.id ?? appState.session.user?.id;
      const body: any = {
        blockType: 'seating',
        rightsProviderId,
        seatId,
      };

      if (selectedPlace) {
        const oldPlace = appState.placesReseating.places.find(
          (place) => place.contractId === selectedPlace?.reseatingContractId
        );
        body.checkoutId = oldPlace?.checkoutId;
        body.emailOrSubjectId = oldPlace?.emailOrSubjectId;
        body.reseatingContractId = selectedPlace.reseatingContractId;
      }

      const stateResponse = (await apiPOSTRequest(requestURL, body)) as TicketSelectionStateAPIResponse;
      const state = mapTicketSelectionStateAPIResponse(stateResponse, userId);

      dispatch({payload: {state}, type: TicketSelectionActionType.SET_STATE});
      dispatch(addPlacesPersonalization(seatId, fullName, phoneNumber, seasonTicketLegalRecipientId));

      if (selectedPlace) {
        const originalPlace = appState.placesReseating.places.find(
          (place) => place.contractId === selectedPlace?.reseatingContractId
        );
        const seasonTicketLegalRecipient = originalPlace?.seasonTicketLegalRecipient;

        useShowSuccessToast(state.places, seatId, strings, seasonTicketLegalRecipient);
      }

    } else {
      const rightsProviderId =
        appState.session.selectedRightsProvider?.id ?? appState.session.user?.id;
      const body = {
        blockType: 'multiseating',
        rightsProviderId,
        seatsIds,
      };
      const stateResponse = (await apiPOSTRequest(requestURL, body)) as TicketSelectionStateAPIResponse;
      const state = mapTicketSelectionStateAPIResponse(stateResponse, userId);
      dispatch({payload: {state}, type: TicketSelectionActionType.SET_STATE});

      state.places.map((selectedSeat) => {
        if (seatsIds.includes(selectedSeat.id)) {
          dispatch(addPlacesPersonalization(selectedSeat.id, fullName, phoneNumber, seasonTicketLegalRecipientId));
        }
      });
    }
  } catch (error) {
    handleError(dispatch, error as Error);
    dispatch(getTicketSelectionState(fetchComponent));
  } finally {
    dispatch(fetchStop);
  }
};
