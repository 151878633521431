import queryString from 'query-string';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router-dom';
import PurchaseOptionsEventSummaryComponent from '../../components/PurchaseOptions/eventSummary';
import PurchaseOptionsList from '../../components/PurchaseOptions/purchaseOptionsList';
import style from '../../components/PurchaseOptions/style.module.css';
import {useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {
  setActionCode,
  setIsPurchaseOptions, setOrganizationId,
  setPurchaseForTicketHolderId,
  setSalesChannel,
  useSession
} from '../../state/Session';
import {getState as getTicketSelectionState, useTicketSelection} from '../../state/TicketSelection';
import {getVenueEvent, useVenueEvent} from '../../state/VenueEvent';
import {generateURL} from '../../util/apiRequest';
import OrganizationModalComponent from "../../components/OrganizationModal";

const PurchaseOptionsScreen: React.FC = () => {
  const location = useLocation();
  const { strings } = useLocale();

  const {venueEventId} = useRouteMatch<{
    venueEventId: string;
  }>().params;

  const dispatch = useDispatch();
  const {arePurchaseOptionsSubmitted, user} = useSession();
  const {venueEvent} = useVenueEvent();
  const appStateVenueEventId = venueEvent?.id;

  const searchParams = queryString.parse(location.search);
  const forwardAfterSale = searchParams.forwardAfterSale;

  // platform sends this param as it is named differently on their side
  const purchaseForTicketHolderId = searchParams.SubjectId;

  const actionCode = searchParams.ActionCode;

  const organizationId = (typeof searchParams.organizationId === 'string') ? searchParams.organizationId : '';

  useEffect(() => {
    if (typeof purchaseForTicketHolderId === 'string') {
      dispatch(setPurchaseForTicketHolderId(purchaseForTicketHolderId));
    }
  }, [dispatch, purchaseForTicketHolderId]);

  useEffect(() => {
    if (typeof actionCode === 'string') {
      dispatch(setActionCode(actionCode));
    }
  }, [dispatch, actionCode]);

  useEffect(() => {
    dispatch(setIsPurchaseOptions(true));
    dispatch(setSalesChannel('purchaseOptions'));
    dispatch(getVenueEvent(venueEventId, 'purchaseOptions', '', user?.id ?? ''));
  }, [dispatch, venueEventId]);

  useEffect(() => {
    if (organizationId && !searchParams.access_token) {
      dispatch(setOrganizationId(organizationId));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (appStateVenueEventId) {
      dispatch(getTicketSelectionState());
    }
  }, [dispatch, venueEvent, appStateVenueEventId]);

  const redirectToUrlFromEnv = (env: string): void => {
    window.location.href = generateURL(process.env[env] || '', {
      params: {
        contractId: '',
        masterId: venueEvent?.masterId || '',
        subjectId: (typeof purchaseForTicketHolderId === 'string') ? purchaseForTicketHolderId : ''
      },
      withoutBaseUrl: true,
    });
  };

  const ticketSelection = useTicketSelection();
  const {fetchIndicator} = useFetch();

  if (arePurchaseOptionsSubmitted) {
    const isSeasonTicketEvent = !!venueEvent?.masterId;

    if (typeof purchaseForTicketHolderId === 'string') {
      if (isSeasonTicketEvent) {
        redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CONTRACT_MASTER_BACKEND');
      } else {
        redirectToUrlFromEnv('REACT_APP_REDIRECT_BASE_URL_BACKEND_PURCHASE');
      }
    } else if (forwardAfterSale === 'web') {
      if (isSeasonTicketEvent) {
        redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CONTRACT_MASTER_WEB');
      } else {
        redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CART_URL');
      }
    } else { // "App" or not provided
      if (isSeasonTicketEvent) {
        redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CONTRACT_MASTER_APP');
      } else {
        redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_APP_CHANNEL_CART_URL');
      }
    }

    return <></>;
  }

  if (!fetchIndicator.fetching && ticketSelection?.places.length === 0) {
    return <div className={style.Error404}>
      <h1>{strings.PurchaseOptions_noPurchaseOptions}</h1>
      <OrganizationModalComponent/>
    </div>;
  }

  return (
    <>
      <PurchaseOptionsEventSummaryComponent/>
      <OrganizationModalComponent/>
      <PurchaseOptionsList/>
    </>
  );
};

export default PurchaseOptionsScreen;
