import { Action, ActionType, mapVenueEvent, VenueEventFlat } from './index';
import {apiGETRequest, generateURL, QueueItRedirectError} from '../../util/apiRequest';
import { API_ENDPOINTS } from '../../config.json';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';

export const getVenueEvent = (
  venueEventId: string,
  salesChannel: string,
  queueittoken: string,
  userId: string,
): AsyncReduxThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(API_ENDPOINTS.GET_EVENT, {
      params: { venueEventId },
      query: { salesChannel, queueittoken, redirectUrl: window.location.href, userId: userId ?? ''  }
    });

    const venueEventFlat = (await apiGETRequest(requestURL, true)) as VenueEventFlat;
    const venueEvent = mapVenueEvent(venueEventFlat);

    dispatch({ payload: { venueEvent }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof QueueItRedirectError) {
      window.location.href = error.url;
      return;
    }

    dispatch({ payload: { error }, type: ActionType.ERROR });
    handleError(dispatch, error);
  }
};

export const setConsecutiveSeats = (consecutiveSeats: number): Action => ({
  payload: { consecutiveSeats },
  type: ActionType.SET_CONSECUTIVE_SEATS,
});
