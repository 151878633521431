import type { RightsProvider } from '../TicketSelection';
import { UserData } from '../../common/types';

export enum ActionType {
  SET_JWT = 'USER::SET_JWT',
  SET_SALES_CHANNEL = 'USER::SET_SALES_CHANNEL',
  SET_SALES_RULE_ID = 'USER::SET_SALES_SALES_RULE_ID',
  SET_PURCHASE_FOR_TICKET_HOLDER_ID = 'USER::SET_PURCHASE_FOR_TICKET_HOLDER_ID',
  SET_SUBSCRIPTION_ID = 'USER::SET_SUBSCRIPTION_ID',
  SET_QUEUEITOKEN = 'USER::SET_QUEUEITOKEN',
  SET_SELECTED_RIGHTS_PROVIDER = 'USER::SET_SELECTED_RIGHTS_PROVIDER',
  SET_IS_PURCHASE_OPTIONS = 'USER::SET_IS_PURCHASE_OPTIONS',
  SET_ARE_PLACES_SUBMITTED = 'USER::SET_ARE_PLACES_SUBMITTED',
  SET_ARE_PURCHASE_OPTIONS_SUBMITTED = 'USER::SET_ARE_PURCHASE_OPTIONS_SUBMITTED',
  SET_ACTION_CODE = 'USER::SET_ACTION_CODE',
  SET_PLACE_SELECTION_ID = 'USER::SET_PLACE_SELECTION_ID',
  SET_ORGANIZATION_ID = 'USER::SET_ORGANIZATION_ID',
  SET_REDIRECT_URL = 'USER::SET_REDIRECT_URL'
}

export interface SessionState {
  jwt?: string;
  jwtDecoded?: { [key: string]: string };
  salesChannel?: string;
  purchaseForTicketHolderId?: string;
  subscriptionId?: string;
  queueittoken?: string;
  salesRuleId?: string;
  selectedRightsProvider?: RightsProvider;
  user?: UserData;
  arePlacesSubmitted: boolean;
  isPurchaseOptions: boolean;
  arePurchaseOptionsSubmitted: boolean;
  actionCode?: string;
  placeSelectionId?: string;
  organizationId?: string;
  redirectUrl?: string | undefined;
}

export interface SetJWTAction {
  type: typeof ActionType.SET_JWT;
  payload: { jwt: string };
}

export interface SetPurchaseForTicketHolderIdAction {
  type: typeof ActionType.SET_PURCHASE_FOR_TICKET_HOLDER_ID;
  payload: { purchaseForTicketHolderId: string };
}

export interface SetSubscriptionIdAction {
  type: typeof ActionType.SET_SUBSCRIPTION_ID;
  payload: { subscriptionId: string };
}

export interface SetQueueittokenAction {
  type: typeof ActionType.SET_QUEUEITOKEN;
  payload: { queueittoken: string };
}

export interface SetSalesChannelAction {
  type: typeof ActionType.SET_SALES_CHANNEL;
  payload: { salesChannel: string };
}

export interface SetSalesRuleIdAction {
  type: typeof ActionType.SET_SALES_RULE_ID;
  payload: { salesRuleId: string };
}

export interface SetSelectedRightsProviderAction {
  type: typeof ActionType.SET_SELECTED_RIGHTS_PROVIDER;
  payload: { selectedRightsProvider: RightsProvider | undefined };
}

export interface SetIsPurchaseOptions {
  type: typeof ActionType.SET_IS_PURCHASE_OPTIONS;
  payload: { isPurchaseOptions: boolean };
}

export interface SetArePlacesSubmitted {
  type: typeof ActionType.SET_ARE_PLACES_SUBMITTED;
  payload: { arePlacesSubmitted: boolean };
}

export interface SetArePurchaseOptionsSubmitted {
  type: typeof ActionType.SET_ARE_PURCHASE_OPTIONS_SUBMITTED;
  payload: { arePurchaseOptionsSubmitted: boolean };
}

export interface SetActionCodeAction {
  type: typeof ActionType.SET_ACTION_CODE;
  payload: { actionCode: string };
}

export interface SetPlaceSelectionId {
  type: typeof ActionType.SET_PLACE_SELECTION_ID;
  payload: { placeSelectionId: string | undefined };
}

export interface SetOrganizationId {
  type: typeof ActionType.SET_ORGANIZATION_ID;
  payload: { organizationId: string | undefined };
}

export interface SetRedirectUrl {
  type: typeof ActionType.SET_REDIRECT_URL;
  payload: { redirectUrl: string | undefined };
}

export type Action =
  | SetJWTAction
  | SetPurchaseForTicketHolderIdAction
  | SetSubscriptionIdAction
  | SetQueueittokenAction
  | SetSalesChannelAction
  | SetSalesRuleIdAction
  | SetSelectedRightsProviderAction
  | SetIsPurchaseOptions
  | SetArePlacesSubmitted
  | SetArePurchaseOptionsSubmitted
  | SetActionCodeAction
  | SetPlaceSelectionId
  | SetOrganizationId
  | SetRedirectUrl;
