import {isEmpty, pickBy} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {API_ENDPOINTS} from '../../config.json';
import {useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {clearPurchasableItems} from '../../state/PurchasableItems';
import {useSession} from '../../state/Session';
import {Place, useTicketSelection} from '../../state/TicketSelection';
import {usePlacesReseating} from '../../state/PlaceReseating';
import {processReseating} from '../../state/PlaceReseating/actions';
import {addSeats, deletePlaces} from '../../state/TicketSelection/actions';
import {apiGETRequest, generateURL, HTTPKnownError, KnowErrors} from '../../util/apiRequest';
import {handleError} from '../../util/handleError';
import Collapsible from '../Collapsible';
import Section from '../Section';
import StandingPlaceSelection from '../StandingPlaceSelection';
import StandingPlaceReseating from '../StandingPlaceReseating';
import VenuePlan, {
  PlacesAvailabilityData,
  PlacesSelection,
  VenueAvailabilityData,
  VenuePlacesData,
  VenueSelectedPlace,
  VenueStandingBlockData,
} from '../VenuePlan';
import {
  ActionType as PlaceReseatingActionType,
  Mode as PlaceReseatingMode
} from '../../state/PlaceReseating/types';
import {ActionType as VenuePlanActionType} from '../../state/VenuePlan/types';
import {VenueLayout} from '../VenuePlan/data';
import {BlockSelectionEvent, SelectionEvent} from '../VenuePlan/interaction';
import {PlaceGraphicalState} from '../VenuePlan/PlaceGraphicalState';
import {toast} from 'react-toastify';
import style from './style.module.css';
import {getVenueEvent} from '../../state/VenueEvent';
import {Seat} from '../../state/TicketSelection/types/Place';

export const AVAILABILITY_UPDATE_INTERVAL = 5000;
const SEAT_QUERY_SERIALIZATION_VARIANT_FULL = 'place_selection';
const SEAT_QUERY_SERIALIZATION_VARIANT_ID_ONLY = 'id_only';

const DEFAULT_VENUE_LAYOUT = 'DB_PARK';

/**
 * Applies places data to a venue layout.
 *
 * @param placesData The data to apply to the layout.
 * @param layout The layout to apply the data to.
 *
 * @return The modified layout.
 */
export function applyPlacesDataToVenueLayout(placesData: VenuePlacesData, layout: VenueLayout): VenueLayout {
  const standingBlocksByLabel = placesData.standingBlocks
    .reduce((acc: Record<string, VenueStandingBlockData>, block) => {
      acc[block.blockLabel] = block;
      return acc;
    }, {});

  // merge information about standing blocks into the layout
  layout.blocks.forEach((block) => {
    if (block.name in standingBlocksByLabel) {
      block.type = 'standing';
      block.id = standingBlocksByLabel[block.name].id;
      block.color = standingBlocksByLabel[block.name].color;
    }
  });

  return layout;
}

export interface VenuePlanProps {
  availability: VenueAvailabilityData;
  setAvailability: (value: (
    ((prevState: VenueAvailabilityData) => VenueAvailabilityData) | VenueAvailabilityData
  )) => void;

  /**
   * Subject user id for backend purchase.
   */
  purchaseForTicketHolderId: string;

  /**
   * Subscription id id for season ticket purchase.
   */
  subscriptionId: string;

  /**
   * Sales rule id for backend purchase.
   */
  salesRuleId: string;

  /**
   * The sales channel in which the place selection takes place.
   */
  salesChannel: string;

  /**
   * The ID of the event for which the venue plan should be displayed.
   */
  eventId: string;

  /**
   * Queue it token for the event.
   */
  queueItToken: string;

  /**
   * The name of the venue layout to use.
   */
  venueLayout: string;

  /**
   * Unique venue plan version
   */
  venuePlanVersionId: string;

  /**
   * Action code for purchase.
   */
  actionCode: string;

  /**
   * Organization id for impersonality user.
   */
  organizationId: string;
}

/**
 * Component that represents a graphical interface for selecting places in a venue.
 */
export const PlaceSelection: React.FC<VenuePlanProps> = (props) => {
  const { fetchComponent, fetchIndicator } = useFetch();
  const { strings, language } = useLocale();
  const { selectedRightsProvider, user } = useSession();
  const ticketSelection = useTicketSelection();
  const placeReseating = usePlacesReseating();

  const dispatch = useDispatch();

  const [isShowModalChooseStandingPlace, setIsShowModalChooseStandingPlace] = useState(false);
  const [isShowModalReseatStandingPlace, setIsShowModalReseatStandingPlace] = useState(false);
  const [nextReseatingContractId, setNextReseatingContractId] = useState('');
  const [placesInCurrentBlock, setPlacesInCurrentBlock] = useState(0);
  const [selectedBlockForStandingPlace, setSelectedBlockForStandingPlace] =
    useState<BlockSelectionEvent | undefined>(undefined);
  const [availablePlacesInSelectedBlockForStandingPlace, setAvailablePlacesInSelectedBlockForStandingPlace] =
    useState(0);

  const [pendingSelections, setPendingSelections] = useState<string[]>([]);
  const [venueLayout, setVenueLayout] = useState<VenueLayout | null>(null);
  const [placesData, setPlacesData] = useState<VenuePlacesData>({
    seats: [],
    standingBlocks: [],
  });
  const { availability, setAvailability } = props;

  const determineSeat = (
    toBeDetermined: Place,
  ): toBeDetermined is Seat => {
    return !!(toBeDetermined as Seat).id;
  };

  const selectedPlaces = ticketSelection?.places || [];
  const selectedSeats: VenueSelectedPlace[] = selectedPlaces
    .filter((p) => determineSeat(p))
    .map((s) => ({ place: { id: determineSeat(s) ? s.seatId : '', type: 'seat' }, status: 'selected' }));

  const placesSelection: PlacesSelection = {
    // extend selected seats by pending selections to give immediate feedback on selection.
    // FIXME: Handling of this this should probably move into the global state.
    seats: selectedSeats.concat(pendingSelections.map((id) => ({
      place: { id, type: 'seat' },
      status: 'processing',
    }))),
    standingPlaces: selectedPlaces.reduce((acc: Record<string, number>, p) => {
      if (p.blockType === 'standing') {
        acc[p.blockId] = (acc[p.blockId] ?? 0) + 1;
      }
      return acc;
    }, {}),
  };

  /**
   * @param availableOnly Flag if only data for available seats should be return.
   * @param idOnly Flag if serialization limited to the ID should be requested.
   */
  function createSeatQueryParams({ idOnly = false, localeInclude = false } = {}): { [key: string]: string } {
    const serializerGroup = idOnly ?
      SEAT_QUERY_SERIALIZATION_VARIANT_ID_ONLY :
      SEAT_QUERY_SERIALIZATION_VARIANT_FULL;

    return pickBy({
      purchaseForTicketHolderId: props.purchaseForTicketHolderId,
      subscriptionId: props.subscriptionId,
      salesRuleId: props.salesRuleId,
      rightsProviderId: selectedRightsProvider?.id ?? '',
      salesChannel: props.salesChannel,
      contractId: nextReseatingContractId,
      serializerGroup,
      actionCode: props.actionCode,
      userId: user?.id ?? '',
      locale: localeInclude ? language : '',
      organizationId: props.organizationId,
    });
  }

  function allowShowModalReseatStandingPlace(blockId: string, availablePlacesInBlock: number): boolean {
    const reseatingPlacesInBlock = !!placeReseating.places.find((place) => place.blockId === blockId);
    const completedPlacesInBlock = !!ticketSelection?.places.find((place) => place.blockId === blockId);
    const selectedPlaces = !!placeReseating.venuePlaces.find((place) => place.status === 'reseating-selected');
    return reseatingPlacesInBlock || completedPlacesInBlock || (selectedPlaces && !!availablePlacesInBlock);
  }

  /**
   * @param event The selection event to handle.
   */
  function handleSelectionEvent(event: SelectionEvent): void {
    // Since the API currently only supports selecting seats one-by-one we block
    // selection until the result of the previous request is known to avoid
    // inconsistencies between UI and cart state.
    if (!fetchIndicator.fetching) {
      switch (event.type) {
        case 'BLOCK': {
          const availablePlacesInBlock = availability.getRemainingBlockCapacity(event.blockId);
          const allowShowModalReseat = allowShowModalReseatStandingPlace(event.blockId, availablePlacesInBlock);

          setSelectedBlockForStandingPlace(event);
          setAvailablePlacesInSelectedBlockForStandingPlace(availablePlacesInBlock);
          setPlacesInCurrentBlock(placesSelection.standingPlaces[event.blockId] ?? 0);

          if (availablePlacesInBlock && props.salesChannel !== 'reseating') {
            setIsShowModalChooseStandingPlace(true);
          } else if (allowShowModalReseat) {
            setIsShowModalReseatStandingPlace(true);
          } else if (availablePlacesInBlock && props.salesChannel === 'reseating') {
            toast.error(strings.Error_reseating_no_selection, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }

          break;
        }
        case 'SEAT': {
          // In reseating only allow selection if place for reseating exist

          const seatIdToDelete = selectedPlaces.find(
            (place) => determineSeat(place) ? place.seatId : '' === event.seatId
          )?.id;

          if (props.salesChannel !== 'reseating' || placeReseating.selectedPlaces.length || !event.selected) {
            setPendingSelections((prevState) => event.selected ? prevState.concat([event.seatId]) : []);
            dispatch((event.selected ?
                addSeats([event.seatId], strings, fetchComponent) :
                deletePlaces([seatIdToDelete] as string[], fetchComponent)
            ));
          } else {
            toast.error(strings.Error_reseating_no_selection, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          }
          break;
        }
        case 'SEATS': {
          const selectPlacesIds: string[] = [];
          const deselectPlacesIds: string[] = [];

          event.seats.map((seat) => {
            let select = true;
            switch (seat.state) {
              case PlaceGraphicalState.AVAILABLE:
                select = true;
                break;
              case PlaceGraphicalState.SELECTED:
              case PlaceGraphicalState.PROCESSING:
                select = false;
                break;
              default:
                return;
            }

            if (select) {
              selectPlacesIds.push(seat.id);
            } else {
              deselectPlacesIds.push(seat.id);
            }
          });

          if (deselectPlacesIds.length) {
            dispatch(deletePlaces(deselectPlacesIds, fetchComponent));
          }

          if (selectPlacesIds.length) {
            dispatch(addSeats(selectPlacesIds, strings, fetchComponent));
          }

          break;
        }
      }
    }

    if (event.type === 'SEAT') {
      dispatch(clearPurchasableItems());
    }
  }

  function handleSelectionReseatingEvent(event: SelectionEvent): void {
    switch (event.type) {
      case 'SEAT':
        dispatch(processReseating(event.seatId));
      break;
    }
  }

  function setAvailabilityFromData(availabilityData: PlacesAvailabilityData): void {
    const idsOfAvailableSeats = new Set(availabilityData.seats.map((s) => s.id));
    const capacityByBlock = new Map(availabilityData.standingBlocks.map((b) => [b.id, b.availableCapacity]));
    const getRemainingBlockCapacity = (blockId: string): number => capacityByBlock.get(blockId) ?? 0;

    setAvailability((prevState) => {
      let availableStandingPlacesCount = 0;
      for (let standingBlock of availabilityData.standingBlocks) {
        availableStandingPlacesCount += standingBlock.availableCapacity ? standingBlock.availableCapacity : 0;
      }

      return {
        ...prevState,
        isSeatAvailable(seatId) {
          return idsOfAvailableSeats.has(seatId);
        },
        isBlockAvailable(blockId: string): boolean {
          return getRemainingBlockCapacity(blockId) > 0;
        },
        getRemainingBlockCapacity,
        availableSeatsCount: availabilityData.seats.length,
        availableStandingPlacesCount: availableStandingPlacesCount,
        version: prevState.version + 1,
      };
    });
  }

  // update of seat availability is performed periodically via setTimout()
  // setup by useEffect(). To be able to access the current props & state
  // for the request we store the update function in a ref on each
  // render to close over the current props & state obviating the need
  // to cancel any pending updates. For further explanation please refer to
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const updateAvailability = useRef<() => Promise<void>>();

  useEffect(() => {
    updateAvailability.current = async () => {
      const requestURL = generateURL(API_ENDPOINTS.GET_AVAILABLE_SEATS, {
        params: { venueEventId: props.eventId },
        query: createSeatQueryParams({ idOnly: true }),
      });

      return apiGETRequest(requestURL).then((availabilityData: PlacesAvailabilityData) => {
        if (props.venuePlanVersionId !== null && props.venuePlanVersionId !== availabilityData.venuePlanVersionId) {
          dispatch(getVenueEvent(props.eventId, props.salesChannel, props.queueItToken, user?.id ?? ''));
          return;
        }

        if (availabilityData.errorType !== null && availabilityData.errorType !== undefined) {
          if ((KnowErrors.FreePlacesAreMissing == availabilityData.errorType && isEmpty(placesSelection.standingPlaces) && placesSelection.seats.length === 0)
            || KnowErrors.UserHasMaxTickets == availabilityData.errorType) {
            handleError(dispatch, new HTTPKnownError(200, availabilityData.errorType));
          } else {
            handleError(dispatch, new HTTPKnownError(200, availabilityData.errorType));
          }
        }

        // We pretend that all seats that are currently selected are available to avoid the weird situation
        // where a seat becomes unavailable for a short time when the user removes it from his selection but
        // the availability has not been refreshed yet.
        availabilityData.seats = availabilityData.seats.concat(selectedSeats.map((s) => ({ id: s.place.id })));
        setAvailabilityFromData(availabilityData);
      }, (error) => {
        handleError(dispatch, error);
      });
    };
  });

  // trigger initial load of venue plan data
  useEffect(() => {
    const loadVenueLayout = async () => {
      const venueLayoutName = props.venueLayout ?? DEFAULT_VENUE_LAYOUT;
      return apiGETRequest(`/${venueLayoutName}.layout.json`);
    };

    const loadPlacesData = async () => {
      const requestURL = generateURL(API_ENDPOINTS.GET_SEATS, {
        params: { venuePlanVersionId: props.venuePlanVersionId },
        query: createSeatQueryParams({localeInclude: true}),
      });

      return apiGETRequest(requestURL);
    };

    const loadVenuePlanData = async () => {
      try {
        dispatch({ type: VenuePlanActionType.FETCH });
        const [venueLayoutData, placesData] = await Promise.all([loadVenueLayout(), loadPlacesData()]);
        setPlacesData(placesData);
        setVenueLayout(applyPlacesDataToVenueLayout(placesData, venueLayoutData));

        dispatch({
          payload: { venuePlan: placesData },
          type: VenuePlanActionType.SUCCESS
        });

      } catch (error) {
        handleError(dispatch, error as Error);
      }
    };

    loadVenuePlanData();
    // The venue plan itself is not dependent on the currently selected
    // sales rule therefore it is not listed here.
  }, [props.eventId, props.venueLayout, props.venuePlanVersionId]);

  // Start periodic update of seat availability.
  //
  // This needs to be done on the first render ONLY as the request to fetch the
  // availability is placed in a ref it is guaranteed that the currently
  // selected parameters  like sales rule etc. are used, therefore it is not
  // necessary to schedule this periodic update anew if those parameters change.
  useEffect(() => {
    const interval = setInterval(() => {
      updateAvailability.current?.();
    }, AVAILABILITY_UPDATE_INTERVAL)

    return() => clearInterval(interval);
  }, []);

  // reset any locally known pending selections when the ticket selection
  // state changes as this is the ultimate source of truth.
  useEffect(() => {
    setPendingSelections([]);
  }, [ticketSelection]);

  // In Reseating SalesChannel we need to refresh the availability for the
  // current selected place because availabilty may vary based on contractId
  useEffect(() => {
    if (
      props.salesChannel === 'reseating'
      && placeReseating.selectedPlaces.length
      && nextReseatingContractId !== placeReseating.selectedPlaces[0].reseatingContractId
    ) {
      setNextReseatingContractId(placeReseating.selectedPlaces[0].reseatingContractId);
    }
  }, [placeReseating.selectedPlaces]);

  // Refresh availabilty if reseatingContractId changes
  useEffect(() => {
    if (availability.version >= 1) {
      updateAvailability.current?.();
    }
  }, [nextReseatingContractId]);

  // When places are selected or deselected in the reaseating process the status
  // of the original seet is set to complete. This also ensures that completed
  // reseatings are restored after reload. Reaseating states are stored locally
  // and can be determined by the selected tickets via their reseatingContractId.
  useEffect(() => {
    if (ticketSelection) {

      ticketSelection.places.map((selectedPlace) => {
        const reseatingSelectedPlace = placeReseating.venuePlaces.find(
          (reseatingVenuePlace) => reseatingVenuePlace.place.contractId === selectedPlace.reseatingContractId
        );

        if (reseatingSelectedPlace && reseatingSelectedPlace.status !== 'reseating-completed') {
          dispatch({
            payload: { placeId: reseatingSelectedPlace.place.id },
            type: PlaceReseatingActionType.REMOVE_SELECTED_PLACE
          });
          dispatch({
            payload: { placeId: reseatingSelectedPlace.place.id, mode: PlaceReseatingMode.Completed },
            type: PlaceReseatingActionType.TOGGLE_PLACE_MODE
          });
          dispatch({
            payload: { placeId: reseatingSelectedPlace.place.id },
            type: PlaceReseatingActionType.COMPLETE
          });
        }
      });

      // Remove completed state if there is no seat with it's reseatingContractId is selected
      placeReseating.venuePlaces.map((venuePlace) => {
        const selectedPlace = ticketSelection.places.find(
          (place) => place.reseatingContractId === venuePlace.place.contractId
        );

        if (venuePlace.status === 'reseating-completed' && !selectedPlace) {
          dispatch({
            payload: { placeId: venuePlace.place.id, reseatingContractId: venuePlace.place.contractId },
            type: PlaceReseatingActionType.ADD_SELECTED_PLACE,
            options: { unshift: true }
          });
          dispatch({
            payload: { placeId: venuePlace.place.id, mode: PlaceReseatingMode.Selected },
            type: PlaceReseatingActionType.TOGGLE_PLACE_MODE
          });
          dispatch({
            payload: { placeId: venuePlace.place.id },
            type: PlaceReseatingActionType.SELECT
          });
        }
      });

    }
  }, [ticketSelection, placeReseating.venuePlaces]);

  // When parameters that possibly influence the availability of seats change
  // we have no choice but to assume that no seats are available to prevent
  // erroneous selections as we won't know which seats are available under
  // the new  parameters until we receive the next update from the API.
  useEffect(() => {
    setAvailabilityFromData({ seats: [], standingBlocks: [], errorType: null, venuePlanVersionId: null });
  }, [props.salesRuleId, props.salesChannel, selectedRightsProvider]);

  const isShowLoader = (availability && availability.version < 2);
  const title = props.salesChannel === 'reseating'
    ? strings.Reseating_FirstRow
    : strings.PlaceSelection_FirstRow;
  const description = props.salesChannel === 'reseating'
    ? strings.Reseating_Description
    : strings.PlaceSelection_Description;

  return (
    <Section>
      <Collapsible header={title} initial="visible">
        <div>{description}</div>
        <div className={style.VenuePlan}>
          <div>
            {isShowLoader &&
              <span className={style.PositionLoader}>
                <div className={style.Loader}></div>
              </span>
            }
            <div style={{opacity: isShowLoader ? '0.2' : '1'}}>
                {venueLayout && <VenuePlan
                    layout={venueLayout}
                    places={placesData}
                    placesSelection={placesSelection}
                    placesReseating={placeReseating}
                    salesChannel={props.salesChannel}
                    availability={availability}
                    isVisible={!isEmpty(placesData.seats) || !isEmpty(placesData.standingBlocks)}
                    onSelectionEvent={handleSelectionEvent}
                    onSelectionReseatingEvent={handleSelectionReseatingEvent}
                    isShowLoader={isShowLoader}
                    venueLayoutName={props.venueLayout ?? DEFAULT_VENUE_LAYOUT}
                  />
                }
            </div>
          </div>
        </div>
      </Collapsible>
      <StandingPlaceSelection
        isShowModalChooseStandingPlace={isShowModalChooseStandingPlace}
        setIsShowModalChooseStandingPlace={setIsShowModalChooseStandingPlace}
        selectedBlockForStandingPlace={selectedBlockForStandingPlace}
        availablePlacesInSelectedBlockForStandingPlace={availablePlacesInSelectedBlockForStandingPlace}
        placesInCurrentBlock={placesInCurrentBlock}
      />
      <StandingPlaceReseating
        isShowModalReseatStandingPlace={isShowModalReseatStandingPlace}
        setIsShowModalReaseatStandingPlace={setIsShowModalReseatStandingPlace}
        selectedBlockForStandingPlace={selectedBlockForStandingPlace}
        availablePlacesInSelectedBlockForStandingPlace={availablePlacesInSelectedBlockForStandingPlace}
      />
    </Section>
  );
};

export default PlaceSelection;
