import React, { useEffect, useState } from 'react';
import { updateEvents, useSalesChannelEventsState } from '../../state/SalesChannelEvents';
import {useRouteMatch} from 'react-router-dom';
import SalesChannelEventsListComponent from '../../components/SalesChannelEventsList';
import SalesChannelEventsSearchComponent from '../../components/SalesChannelEventsList/search';
import Section from '../../components/Section';
import style from '../../components/SalesChannelEventsList/style.module.css';
import { useDispatch } from 'react-redux';
import Header from "../../components/Page/Header";
import Footer from "../../components/Page/Footer";
import {useLocale} from "../../state/Localization";

const SalesChannelEventListScreen: React.FC = () => {
  const { strings } = useLocale();
  const dispatch = useDispatch();

  const { salesChannel } = useRouteMatch<{
    salesChannel: string;
  }>().params;

  if (salesChannel !== 'ticket_agency') {
    throw Error('Event list is available only for ticket_agency sales channel');
  }

  const [searchExpr, setSearchExpr] = useState<string>('');
  const [sortExpr, setSortExpr] = useState<string>('');

  const {events} = useSalesChannelEventsState();

  const onSearchExprChangeHandler = (searchExpr: string): void => {
    setSearchExpr(searchExpr);
  };

  const onSearchSubmitHandler = (): void => {
    /*TODO*/
  };

  const onSortExprChangeHandler = (typeKey: string): void => {
    if (sortExpr === typeKey) {
      setSortExpr(`-${typeKey}`);
    } else {
      setSortExpr(typeKey);
    }
  };

  useEffect(() => {
    dispatch(
      updateEvents(
        salesChannel,
        sortExpr,
        searchExpr,
      ),
    );
  }, [
    dispatch,
    salesChannel,
    sortExpr,
    searchExpr,
  ]);

  return (
    <>
      <div className={style.Main}>
        <Header/>
        <Section>
          <h1>{strings.EventList_Sales}</h1>
        </Section>
        <Section border={false}>
          <SalesChannelEventsSearchComponent
              searchExpr={searchExpr}
              isSearchValid={true}
              onSearchExprChangeHandler={onSearchExprChangeHandler}
              onSearchSubmitHandler={onSearchSubmitHandler}
          />
        </Section>
        <Section border={false}>
          <h2>{strings.EventList_Events}</h2>
          <SalesChannelEventsListComponent
              events={events}
              onSortExprChangeHandler={onSortExprChangeHandler}
          />
        </Section>
        <Footer/>
      </div>
    </>
  );
};

export default SalesChannelEventListScreen;
