import React from 'react';
import style from './style.module.css';
import {useLocale} from "../../../state/Localization";

const Footer: React.FC = () => {
    const { strings } = useLocale();

    return (
        <>
            <div className={style.Footer}>
                <div className={style.FooterItem}>© {new Date().getFullYear()} {strings.EintrachtFrankfurt}</div>
                <div className={style.FooterItem}>
                    <a className={style.FooterLink} href="https://www.eintracht.de/impressum/">{strings.Footer_Impressum}</a>
                </div>
                <div className={style.FooterItem}>
                    <a className={style.FooterLink} href="https://www.eintracht.de/datenschutz/">{strings.Footer_Datenschutz}</a>
                </div>
                <div className={style.FooterItem}>
                    <a className={style.FooterLink} href="https://stores.eintracht.de/allgemeine-geschaeftsbedingungen-vvk-stellen/">{strings.Footer_AGB}</a>
                </div>
            </div>
        </>
    )
};

export default Footer;
