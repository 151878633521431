import { FetchIndicator } from '../../state/Fetch';
import React from 'react';
import style from './style.module.css';
import images from '../../assets/images';

type StyleVariant = 'primary' | 'secondary' | 'default' | 'sub' | undefined;

const selectVariant = (variant: StyleVariant): string => {
  switch (variant) {
    case 'primary':
      return style.VariantPrimary;
    case 'secondary':
      return style.VariantSecondary;
    case 'sub':
      return style.VariantSub;
    case 'default':
    default:
      return style.VariantDefault;
  }
};

const ButtonComponen: React.FC<{
  component?: 'button' | 'a';
  disabled?: boolean;
  fetchIndicator?: FetchIndicator;
  href?: string;
  onClick?: (
    eventArgs: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  variant?: StyleVariant;
  testid?: string;
}> = ({
  children,
  component,
  disabled,
  fetchIndicator,
  href,
  onClick,
  variant,
  testid,
}) => {
  if (fetchIndicator?.fetching && fetchIndicator.hasFocus) {
    return <img src={images.loadingIndicator} width='30' height='30' alt='fetching...'/>
  }

  const fetchingStyle = fetchIndicator?.fetching ? style.Fetching : '';

  switch (component) {
    case 'a':
      return (
        <a
          className={`${style.ButtonBase} ${selectVariant(variant)} ${
            style.ComponentA
          } ${fetchingStyle}`}
          href={href}
        >
          {children}
        </a>
      );
    case 'button':
    default:
      return (
        <button
          className={`${style.ButtonBase} ${selectVariant(variant)} ${
            style.ComponentButton
          } ${fetchingStyle}`}
          disabled={fetchIndicator?.fetching ?? disabled}
          onClick={onClick}
          data-testid={testid}
        >
          {children}
        </button>
      );
  }
};

export default ButtonComponen;
