import {API_ENDPOINTS} from '../../../config.json';
import {apiDELETERequest, generateURL} from '../../../util/apiRequest';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import {handleError} from '../../../util/handleError';
import {createFetchActions, FetchComponent} from '../../Fetch';
import {
  removePlacesPersonalization
} from '../../PlacePersonalization';
import {ActionType as TicketSelectionActionType} from '../types';
import {mapTicketSelectionStateAPIResponse} from '../types/mapTicketSelectionStateAPIResponse';
import {TicketSelectionStateAPIResponse} from '../types/TicketSelectionStateAPIResponse';
import {ensureBasicState} from '../utils/ensureBasicState';
import {getState as getTicketSelectionState} from './getState';
import {deleteOtherSubscriberPlace} from '../../PlaceReseating/actions';

/**
 * Delete places from place selection, if placesIds array is empty then all places will be deleted
 *
 * @param placesIds if empty all places will be removed
 * @param fetchComponent
 */
export const deletePlaces = (
  placesIds: string[],
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const {fetchStart, fetchStop} = createFetchActions(fetchComponent);
  dispatch(fetchStart);

  try {
    const appState = getState();

    // After a page reload information concerning authorization are lost so
    // selection of a new tickets cannot be validated. Therefor we remove
    // the other person's original place, too.
    placesIds.map((deletedPlaceId) => {
      const contractId = appState.ticketSelection?.places.find(
        (place) => place.id === deletedPlaceId
      )?.reseatingContractId;

      const originalPlace = appState.placesReseating.places.find(
        (place) => place.contractId === contractId
      );

      if (originalPlace?.subscriberName && (!originalPlace?.emailOrSubjectId || !originalPlace?.checkoutId)) {
        dispatch(deleteOtherSubscriberPlace(originalPlace.id));
      }
    });

    const {
      purchaseForTicketHolderId,
      subscriptionId,
      salesChannel,
      salesRuleId,
      userId,
      venueEventId,
      organizationId
    } = ensureBasicState(
      appState,
    );
    const requestURL = generateURL(API_ENDPOINTS.DELETE_PLACES, {
      params: {venueEventId},
      query: { purchaseForTicketHolderId, salesChannel, salesRuleId, subscriptionId, organizationId},
    });

    const stateResponse = (await apiDELETERequest(requestURL, {
      placesIds: placesIds,
    })) as TicketSelectionStateAPIResponse;

    const state = mapTicketSelectionStateAPIResponse(stateResponse, userId);

    dispatch({payload: {state}, type: TicketSelectionActionType.SET_STATE});

    const selectionPlaces = state.places.map((selectedPlace) => selectedPlace.id);
    placesIds.map((deletedPlace) => {
      if (!selectionPlaces.includes(deletedPlace)) {
        dispatch(removePlacesPersonalization(deletedPlace));
      }
    });

  } catch (error) {
    handleError(dispatch, error as Error);
    dispatch(getTicketSelectionState(fetchComponent));
  } finally {
    dispatch(fetchStop);
  }
};
