
const PricingClassIdentifier = Symbol();

export interface PricingClassFlat {
  id: string;
  publicName: string;
}

export interface PricingClass extends PricingClassFlat {
  [PricingClassIdentifier]: true;
}
