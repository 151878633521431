import Collapsible from '../Collapsible';
import React from 'react';
import SeatingRightsProviderListItemComponent from '../RightsProviderSelectItem';
import SectionComponent from '../Section';
import style from './style.module.css';
import { useSession } from '../../state/Session';
import { useTicketSelection } from '../../state/TicketSelection';
import { useVenueEvent } from '../../state/VenueEvent';

const RightsProviderSelectComponen: React.FC = () => {
  const ticketSelection = useTicketSelection();
  const rightsProviders = ticketSelection?.rightsProviders ?? [];
  const places = ticketSelection?.places ?? [];
  const { selectedRightsProvider, user } = useSession();
  const { venueEvent } = useVenueEvent();

  if (rightsProviders.length === 0) {
    return null;
  }

  return (
    <SectionComponent>
      <Collapsible header="Berechtigungen" initial="visible">
        <p>Plätze wählen für...</p>
        <ol className={style.RightsProviderList}>
          {user ? (
            <SeatingRightsProviderListItemComponent
              rightsProviderId={undefined}
              rightsProviderName={`${user.firstName} ${user.lastName}`}
              rightsProviderTickets={
                places.filter((place) => !place.rightsProvider).length
              }
              rightsProviderTicketsMax={venueEvent?.maxTicketsPerUser}
              isSelected={!selectedRightsProvider}
            />
          ) : null}
          {rightsProviders.map((rightsProvider, key) => (
            <SeatingRightsProviderListItemComponent
              rightsProviderId={rightsProvider.id}
              rightsProviderName={rightsProvider.emailOrEfId}
              rightsProviderTickets={
                places.filter(
                  (place) => place.rightsProvider?.id === rightsProvider.id,
                ).length
              }
              rightsProviderTicketsMax={rightsProvider.maxTickets}
              isSelected={
                selectedRightsProvider &&
                rightsProvider.id === selectedRightsProvider.id
              }
              key={key}
            />
          ))}
        </ol>
      </Collapsible>
    </SectionComponent>
  );
};

export default RightsProviderSelectComponen;
