import Flatten from '@flatten-js/core';
import {maxBy, minBy} from 'lodash';
import * as PIXI from 'pixi.js';
import { Container, Point, Polygon, Renderer, RenderTexture, Texture } from 'pixi.js';

/**
 * Checks if a given rect somewhat fits within the bounds of given polygon.
 *
 * The somewhat is due to the fact that this check only tests if the four corners
 * are contained in the polygon so this works only for "mostly convex" polygons.
 *
 * @param centerX The X-coordinate of the rect to test
 * @param centerY The Y-coordinate of the rect to test
 * @param width The width of the rect to test
 * @param height The height of the rect to test
 * @param polygon The polygon to test against given as an array numbers with to numbers per point.
 */
export function checkIfRectFitsWithinPolygon(
  centerX: number, centerY: number,
  width: number, height: number,
  polygon: number[],
): boolean {
  const xShift = width * 0.5;
  const yShift = height * 0.5;
  const p = new Polygon(polygon);

  return p.contains(centerX - xShift, centerY - yShift)
    && p.contains(centerX + xShift, centerY - yShift)
    && p.contains(centerX - xShift, centerY + yShift)
    && p.contains(centerX + xShift, centerY + yShift);
}

interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

/** @return true if insideRect is fully inside outsideRect */
export function isRectContained(
  insideRect: Rect,
  outsideRect: Rect,
): boolean {
  return insideRect.x >= outsideRect.x
    && insideRect.x + insideRect.width <= outsideRect.x + outsideRect.width
    && insideRect.y >= outsideRect.y
    && insideRect.y + insideRect.height <= outsideRect.y + outsideRect.height;
}

export function createTexture(displayObject: Container, renderer: Renderer, padding: number): Texture {
  const bounds = displayObject.getLocalBounds();

  const texture = RenderTexture.create({
    width: bounds.width + padding * 2,
    height: bounds.height + padding * 2,
    scaleMode: PIXI.SCALE_MODES.LINEAR,
    resolution: window.devicePixelRatio,
  });

  const container = new Container();
  container.addChild(displayObject);
  container.x = padding;
  container.y = padding;

  renderer.render(container, texture);

  texture.baseTexture.setStyle(PIXI.SCALE_MODES.LINEAR, PIXI.MIPMAP_MODES.ON);

  return texture;
}

export function distance(p1: Point, p2: Point): number {
  const dx = p1.x - p2.x;
  const dy = p1.y - p2.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function coordinatesInsideRectangle(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x: number,
  y: number
): boolean {
  return (x > x1 && x < x2 && y > y1 && y < y2)
    || (x > x2 && x < x1 && y > y2 && y < y1)
    || (x > x2 && x < x1 && y > y1 && y < y2)
    || (x > x1 && x < x2 && y > y2 && y < y1);
}

export function calculateBoundingBox(points: Point[]): Flatten.Box {
  const xMin = minBy(points, 'x')?.x;
  const yMin = minBy(points, 'y')?.y;
  const xMax = maxBy(points, 'x')?.x;
  const yMax = maxBy(points, 'y')?.y;

  return new Flatten.Box(xMin, yMin, xMax, yMax);
}

export function clamp(value: number, min: number, max: number): number {
  if (value < min) return min;
  if (value > max) return max;
  return value;
}

export function lerp(start: number, end: number, part: number): number {
  return start + (end - start) * part;
}

/** Dot product of 2 vectors */
function dot(v1: [number, number], v2: [number, number]): number {
  return (v1[0] * v2[0]) + (v1[1] * v2[1]);
}

/**
 * from pixi-intersects
 * from http://stackoverflow.com/a/10392860/1955997
 * @param center center of the circle
 * @param radius radius of the circle
 * @param p1x x of first segment point
 * @param p1y y of first segment point
 * @param p2x x of second segment point
 * @param p2y y of second segment point
 */
function circleCollidesSegment(center: Point, radius: number,
                               p1x: number, p1y: number,
                               p2x: number, p2y: number) {
  const ac: [number, number] = [center.x - p1x, center.y - p1y];
  const ab: [number, number] = [p2x - p1x, p2y - p1y];
  const ab2 = dot(ab, ab);
  const acab = dot(ac, ab);
  let t = acab / ab2;
  t = (t < 0) ? 0 : t;
  t = (t > 1) ? 1 : t;
  let h: [number, number] = [(ab[0] * t + p1x) - center.x, (ab[1] * t + p1y) - center.y];
  const h2 = dot(h, h);
  return h2 <= radius * radius;
}

/**
 * from pixi-intersects
 * from http://stackoverflow.com/a/402019/1955997
 *
 * @param center center of the circle
 * @param radius radius of the circle
 * @param polygon the polygon to check
 */
export function circleCollidesPolygon(center: Point, radius: number, polygon: Polygon) {
  if (polygon.contains(center.x, center.y)) {
    return true;
  }
  const vertices = polygon.points;
  const count = vertices.length;
  for (let i = 0; i < count - 2; i += 2) {
    if (circleCollidesSegment(center, radius,
      vertices[i], vertices[i + 1], vertices[i + 2], vertices[i + 3])) {
      return true;
    }
  }
  return circleCollidesSegment(center, radius, vertices[0], vertices[1], vertices[count - 2], vertices[count - 1]);
}

/** Interpolate between color components independently using progress */
export function colorLerp(startColor: number, endColor: number, progress: number): number {
  return ((lerp((startColor >> 16) & 0xFF, (endColor >> 16) & 0xFF, progress) & 0xFF) << 16) |
    ((lerp((startColor >> 8) & 0xFF, (endColor >> 8) & 0xFF, progress) & 0xFF) << 8) |
    (lerp(startColor & 0xFF, endColor & 0xFF, progress) & 0xFF);
}

export function isInBounds(bounds: Rect, point: { x: number, y: number }): boolean {
  return point.x > bounds.x && point.x < bounds.x + bounds.width
    && point.y > bounds.y && point.y < bounds.y + bounds.height;
}
