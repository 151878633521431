const PricingCategoryIdentifier = Symbol();

export interface PricingCategoryFlat {
  id: string;
  name: string;
  color?: string;
}

export interface PricingCategory extends PricingCategoryFlat {
  [PricingCategoryIdentifier]: true;
}
