import React from 'react';
import { EventListProps } from './props';
import style from './style.module.css';
import {useLocale} from "../../state/Localization";

const SalesChannelEventsListComponent: React.FC<EventListProps> = ({
                                                                     events,
                                                                     onSortExprChangeHandler,
                                                                   }) => {
  const { strings } = useLocale();
  const onTableHeaderClicked = (
    event: React.MouseEvent<HTMLTableHeaderCellElement>,
  ): void => {
      // @ts-ignore
    const typeKey = event.currentTarget.parentElement.title;
    onSortExprChangeHandler(typeKey);
  };

  return (
    <div>
      <table>
        <thead>
        <tr>
          <th title="title" className={style.ColumnTitle}>
            <p onClick={onTableHeaderClicked}>{strings.EventList_Event}</p>
          </th>
          <th title="gameday" className={style.ColumnTitle}>
            <p onClick={onTableHeaderClicked}>{strings.EventList_GameDay}</p>
          </th>
          <th title="serialTitle" className={style.ColumnTitle}>
            <p onClick={onTableHeaderClicked}>{strings.EventList_Series}</p>
          </th>
          <th title="startDateTime" className={style.ColumnTitle}>
            <p onClick={onTableHeaderClicked}>{strings.EventList_Date}</p>
          </th>
          <th title="preliminaryDateText" className={style.ColumnTitle}>
            <p onClick={onTableHeaderClicked}>{strings.EventList_PreliminaryDateText}</p>
          </th>
          <th title="venueTitle" className={style.ColumnTitle}>
            <p onClick={onTableHeaderClicked}>{strings.EventList_Location}</p>
          </th>
          <th className={style.ColumnTitle + ' ' + style.ActionColumnHeading}>{strings.EventList_Action}</th>
        </tr>
        </thead>
        <tbody>
        {events.map((event) => (
          <tr key={event.id}>
            <td className={style.EventNameColumn}>
              {event.title}
            </td>
            <td className={style.GameDayColumn}>{event.gameday}</td>
            <td>{event.serialTitle}</td>
            <td>{event.startDateTime}</td>
            <td>
              {event.isDatePreliminary ? event.preliminaryDateText : ''}
            </td>
            <td>{event.venueTitle}</td>
            <td className={style.LinkColumn}>
              <a className={style.LinkButton}
                 href={event.salesLink}
              >
                {strings.EventList_TicketSales}
              </a>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesChannelEventsListComponent;
