import React from 'react';
import style from './style.module.css';
import images from '../../../assets/images';
import {useLocale} from "../../../state/Localization";

const Header: React.FC = () => {
    const { strings } = useLocale();

    return (
        <>
            <div className={style.Header}>
                <img className={style.Logo} src={images.logo} alt={strings.Header_Logo}></img>
            </div>
        </>
    )
};

export default Header;
