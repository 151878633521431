import React, { useEffect, useState } from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import { getVenueEvent, useVenueEvent } from '../../state/VenueEvent';
import {
  setActionCode, setOrganizationId,
  setPurchaseForTicketHolderId,
  setQueueittoken,
  setSalesChannel,
  setSubscriptionId,
  useSession
} from '../../state/Session';
import BuildInfoComponent from '../../components/BuildInfo';
import PlaceSelection from '../../components/PlaceSelection';
import RightsProviderSelectComponent from '../../components/RightsProviderSelect';
import RightsProviderTableComponent from '../../components/RightsProviderTable';
import SalesRuleTableComponent from '../../components/SalesRuleTable';
import SeatSelection from '../../components/SeatSelection';
import SubmitBasketComponent from '../../components/SubmitBasket';
import TicketBasket from '../../components/TicketBasket';
import TicketReseatingBasket from '../../components/TicketReseatingBasket';
import VenueEventSummaryComponent from '../../components/VenueEventSummary';
import { getState as getTicketSelectionState, useTicketSelection } from '../../state/TicketSelection';
import { getPlacesReseating } from '../../state/PlaceReseating';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import {apiGETRequest, generateURL} from '../../util/apiRequest';
import { VenueAvailabilityData } from '../../components/VenuePlan';
import PlaceSelectionWithoutGraphicalVenuePlan from '../../components/PlaceSelectionWithoutGraphicalVenuePlan';
import {API_ENDPOINTS} from '../../config.json';
import OrganizationModalComponent from '../../components/OrganizationModal';

const SelectTicketScreen: React.FC = () => {
  const location = useLocation();
  const { salesChannel, venueEventId } = useRouteMatch<{
    salesChannel: string;
    venueEventId: string;
  }>().params;

  const dispatch = useDispatch();
  const searchParams = queryString.parse(location.search);

  // platform sends this param as it is named differently on their side
  const purchaseForTicketHolderId = (typeof searchParams.SubjectId === 'string') ? searchParams.SubjectId : '';
  const actionCode = (typeof searchParams.ActionCode === 'string') ? searchParams.ActionCode : '';
  const forwardAfterReseating = searchParams.forwardAfterReseating || 'app';
  const contractId = (typeof searchParams.contractId === 'string') ? searchParams.contractId : '';
  const subscriptionId = searchParams.SubscriptionId;
  const queueittoken = (typeof searchParams.queueittoken === 'string') ? searchParams.queueittoken : '';
  const organizationId = (typeof searchParams.organizationId === 'string') ? searchParams.organizationId : '';

  const { arePlacesSubmitted, redirectUrl } = useSession();
  const { venueEvent } = useVenueEvent();
  const appStateVenueEventId = venueEvent?.id;
  const { salesRuleId } = useSession();
  const { placeSelectionId } = useSession();
  const { user } = useSession();
  const ticketSelection = useTicketSelection();
  const history = useHistory();

  const [availability, setAvailability] = useState<VenueAvailabilityData>({
    getRemainingBlockCapacity: () => 0,
    isBlockAvailable: () => false,
    isSeatAvailable: () => false,
    version: 0,
    availableSeatsCount: 0,
    availableStandingPlacesCount: 0,
  });

  useEffect(() => {
    if (purchaseForTicketHolderId) {
      dispatch(setPurchaseForTicketHolderId(purchaseForTicketHolderId));
    }
  }, [dispatch, purchaseForTicketHolderId]);

  useEffect(() => {
    if (actionCode) {
      dispatch(setActionCode(actionCode));
    }
  }, [dispatch, actionCode]);

  useEffect(() => {
    if (typeof subscriptionId === 'string') {
      dispatch(setSubscriptionId(subscriptionId));
    }
  }, [dispatch, subscriptionId]);

  useEffect(() => {
    if (typeof queueittoken === 'string') {
      dispatch(setQueueittoken(queueittoken));
    }
  }, [dispatch, queueittoken]);

  useEffect(() => {
    if (typeof salesChannel === 'string') {
      dispatch(setSalesChannel(salesChannel));
      dispatch(getVenueEvent(venueEventId, salesChannel, queueittoken, user?.id || ''));
    }
  }, [dispatch, venueEventId, salesChannel]);

  useEffect(() => {
    if (typeof salesChannel === 'string' && appStateVenueEventId) {
      dispatch(getTicketSelectionState());
    }
  }, [dispatch, venueEvent, salesChannel, salesRuleId, appStateVenueEventId]);

  useEffect(() => {
    if (ticketSelection && salesChannel === 'reseating') {
      dispatch(getPlacesReseating(contractId, ticketSelection.places));
    }
  }, [!!ticketSelection]);

  useEffect(() => {
    if (organizationId) {
      dispatch(setOrganizationId(organizationId));
    }
  }, [dispatch, organizationId]);

  const isReadyForRedirect = async (placeSelectionId: string): Promise<boolean> => {
    const result = await apiGETRequest(
        generateURL(API_ENDPOINTS.GET_PLACE_SELECTION_STATUS, {
          params: { placeSelectionId: placeSelectionId }
        })
    );

    return result.ready;
  };

  if (arePlacesSubmitted) {
    const redirectToUrlFromEnv = (env: string): void => {
      window.location.href = generateURL(process.env[env] || '', {
        params: {
          contractId: contractId,
          masterId: venueEvent?.masterId || '',
          subjectId: purchaseForTicketHolderId,
        },
        withoutBaseUrl: true,
      });
    };

    const redirectToUrlFromEnvWhenReady = async (env: string): Promise<void> => {
      if (!placeSelectionId) {
        return redirectToUrlFromEnv(env);
      }

      if (await isReadyForRedirect(placeSelectionId)) {
        return redirectToUrlFromEnv(env);
      }

      const interval = setInterval(async () => {
        try {
          if (await isReadyForRedirect(placeSelectionId)) {
            clearInterval(interval);
            redirectToUrlFromEnv(env);
          }
        } catch (e) {
          redirectToUrlFromEnv(env);
        }
      }, 3000);
    };

    if (redirectUrl) {
      window.location.href = redirectUrl; // todo change here

    } else if (purchaseForTicketHolderId) { // backend purchase
      if (salesChannel === 'reseating') {
        redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CONTRACT_BACKEND');
      } else {
        if (venueEvent?.masterId) {
          (async () => {
            await redirectToUrlFromEnvWhenReady('REACT_APP_REDIRECT_TO_CONTRACT_MASTER_BACKEND');
          })();
        } else {
          redirectToUrlFromEnv('REACT_APP_REDIRECT_BASE_URL_BACKEND_PURCHASE');
        }
      }
    } else {
      if (salesChannel === 'app') {
        if (venueEvent?.masterId) {
          (async () => {
            await redirectToUrlFromEnvWhenReady('REACT_APP_REDIRECT_TO_CONTRACT_MASTER_APP');
          })();
        } else {
          redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_APP_CHANNEL_CART_URL');
        }
      } else if (salesChannel === 'reseating') {
        if (forwardAfterReseating == 'web') {
          redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CONTRACT_WEB');
        } else { // On reseating app could have no forwardAfterReseating param set
          redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CONTRACT_APP');
        }
      } else if (salesChannel === 'ticket_agency') {
        history.push(generateURL('/purchasing/ticket_agency/success/{{placeSelectionId}}', {
          params: {
            placeSelectionId: placeSelectionId ? placeSelectionId : 'undefined',
          },
          withoutBaseUrl: true,
        }));
      } else {
        if (venueEvent?.masterId) {
          (async () => {
            await redirectToUrlFromEnvWhenReady('REACT_APP_REDIRECT_TO_CONTRACT_MASTER_WEB');
          })();
        } else {
          redirectToUrlFromEnv('REACT_APP_REDIRECT_TO_CART_URL');
        }
      }
    }
  }

  const showSelectionFallback = location.search.includes('fallback');

  return (
    <>
      <BuildInfoComponent/>
      <VenueEventSummaryComponent/>
      <RightsProviderTableComponent/>
      <OrganizationModalComponent/>
      <SalesRuleTableComponent/>
      {showSelectionFallback && <SeatSelection/>}
      {venueEvent && !venueEvent.disableVisualSelection && salesChannel && (
        <PlaceSelection salesChannel={salesChannel}
                        purchaseForTicketHolderId={purchaseForTicketHolderId}
                        actionCode={actionCode}
                        subscriptionId={
                          (typeof subscriptionId === 'string')
                            ? subscriptionId
                            : ''
                        }
                        salesRuleId={
                          (typeof salesRuleId === 'string')
                            ? salesRuleId
                            : ''
                        }
                        eventId={venueEvent.id}
                        queueItToken={queueittoken}
                        venuePlanVersionId={venueEvent.venuePlanVersionId}
                        venueLayout={venueEvent.venueLayout}
                        availability={availability}
                        setAvailability={setAvailability}
                        organizationId={organizationId}
        />
      )}
      {venueEvent && venueEvent.disableVisualSelection && (
          <PlaceSelectionWithoutGraphicalVenuePlan
              salesChannel={salesChannel}
              purchaseForTicketHolderId={purchaseForTicketHolderId}
              actionCode={actionCode}
              subscriptionId={
                (typeof subscriptionId === 'string')
                    ? subscriptionId
                    : ''
              }
              salesRuleId={
                (typeof salesRuleId === 'string')
                    ? salesRuleId
                    : ''
              }
              eventId={venueEvent.id}
              queueItToken={queueittoken}
              venuePlanVersionId={venueEvent.venuePlanVersionId}
              venueLayout={venueEvent.venueLayout}
              availability={availability}
              setAvailability={setAvailability}
              organizationId={organizationId}
          />
      )}
      <RightsProviderSelectComponent/>

      {salesChannel === 'reseating' && <TicketReseatingBasket/>}
      {salesChannel !== 'reseating' && <TicketBasket/>}

      <SubmitBasketComponent availability={availability} salesChannel={salesChannel} />
    </>
  );
};

export default SelectTicketScreen;
