import React from 'react';
import { getMessageComponentForType } from '../Message';
import style from './style.module.css';
import { useMessage } from '../../state/Message';

const MessageStackComponent: React.FC = () => {

  const { messages } = useMessage();
  if (messages.length > 0) {
    return (
      <div className={style.MessageStack}>
        {messages.map((message, key) => {
          const Message = getMessageComponentForType(message.type);
          return <Message key={key} message={message}/>;
        })}
      </div>
    );
  }
  return null;
};

export default MessageStackComponent;
