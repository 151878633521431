import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Place } from '../../state/PlaceReseating';
import Ticket from '../Ticket';
import { useTicketSelection } from '../../state/TicketSelection';
import style from './style.module.css';
import {useLocale} from '../../state/Localization';
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { processReseating } from '../../state/PlaceReseating/actions';

const TicketReseating: React.FC<{ place: Place; index: number }> = ({ place, index }) => {

  const { language, strings } = useLocale();
  const dispatch = useDispatch();
  const ticketSelection = useTicketSelection();

  const newPlace = ticketSelection?.places.find(
    (newPlace) => newPlace.reseatingContractId === place.contractId
  );

  const onCheckboxClicked = useCallback(() => {
    dispatch(processReseating(place.id));
  }, [dispatch]);

  return (
    <div className={style.TicketReseating}>
      <div className={`${style.TicketReseatingCheckBox }
        ${place.mode !== 'view' ? style.TicketReseatingCheckBoxActive : ''}`}>
        {place.mode !== 'completed' &&
          <div className={`${style.TicketReseatingCheckBoxIcon}
            ${place.mode === 'selected' ? style.TicketReseatingCheckBoxIconSelected : ''}`}
            onClick={onCheckboxClicked}>
            {place.mode === 'selected' && <FontAwesomeIcon icon={faCheck} />}
          </div>
        }
      </div>
      <div className={`${style.OldPalceBlock} ${place.mode === 'selected' ? style.OldPalceBlockSelected : ''}`}>
        <strong>
          {place.subscriberName === undefined
            ? place.seasonTicketLegalRecipient
            : <>{strings.ReseatingBasket_OthersPlaceFrom} {place.subscriberName}</>
          }
        </strong>

        {place.subscriberName !== undefined &&
          <p>{place.seasonTicketLegalRecipient}</p>
        }

        {place.blockType === 'seating' ? (
          <p>
            {strings.Shared_Block}&nbsp;{place.blockLabel}{', '}
            {strings.Shared_Row}&nbsp;{place.rowLabel}{', '}
            {strings.Shared_Seat}&nbsp;{place.seatLabel}{', '}
            {place.pricingCategoryName}
          </p>
        ) : (
            <p>{strings.Shared_Block}&nbsp;{place.blockLabel}</p>
        )}

        {place.subscriberName === undefined &&
          <p>
            {place.pricingClassName}{', '}
            {((place.price ?? 0) / 100).toLocaleString(language, { currency: 'EUR', style: 'currency' })}
          </p>
        }

      </div>
      <div className={style.NewPalceBlock}>
        {place.mode !== 'view' &&
          <>
            <div className={style.ArrowIcon}>
              <FontAwesomeIcon icon={faArrowRight}/>
            </div>
            {place.mode === 'selected' && <div className={style.EmptyPlace}>
              <strong>{strings.ReseatingBasket_PleaseSelect}</strong>
            </div>}
            {place.mode === 'completed' && newPlace &&
              <div className={style.Place} style={{ borderColor: newPlace.pricingCategory.color }}>
                <Ticket index={index}
                        place={newPlace}
                        reseating={true}
                        otherPerson={place.subscriberName !== undefined} />
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};

export default TicketReseating;