import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {setSalesRuleId, useSession} from '../../state/Session';
import {useTicketSelection} from '../../state/TicketSelection';
import Section from '../Section';
import Select from '../Select';
import style from './style.module.css';

const SalesRuleComponen: React.FC = () => {
  const dispatch = useDispatch();
  const {fetchIndicator} = useFetch();
  const {strings} = useLocale();
  const ticketSelectionState = useTicketSelection();
  const {salesRuleId} = useSession();
  const salesRules = ticketSelectionState?.salesRules;
  const selectedSalesRule = ticketSelectionState?.selectedSalesRule;

  const onSalesRuleChanged = useCallback(
    (eventArgs: React.ChangeEvent<HTMLSelectElement>) => {
      const salesRuleId = eventArgs.currentTarget.value;
      dispatch(setSalesRuleId(salesRuleId));
    },
    [dispatch],
  );

  useEffect(() => {
    if (ticketSelectionState?.selectedSalesRule && !salesRuleId) {
      dispatch(setSalesRuleId(ticketSelectionState.selectedSalesRule.id));
    }
  }, [dispatch, salesRuleId, ticketSelectionState]);

  let salesRuleName = strings.SalesRules_All;

  if (selectedSalesRule) {
    salesRuleName = selectedSalesRule.name;
  } else if (salesRules) {
    for (const salesRule of salesRules) {
      if (salesRule.id === salesRuleId) {
        salesRuleName = salesRule.name;
      }
    }
  }

  return (
    <>
      {salesRules?.length ? (
        <Section border={true}>
          <div>
            <p>{strings.SalesRules_Title}</p>
            {ticketSelectionState?.places.length ? (
              <div className={style.SelectedSalesRule}>
                {salesRuleName}
              </div>
            ) : <Select
              fetchIndicator={fetchIndicator}
              onChange={onSalesRuleChanged}
              value={salesRuleId}
              className={style.Select}
            >
              <option value="">{strings.SalesRules_All}</option>
              {salesRules.map((salesRule, key) => (
                <option key={key} value={salesRule.id}>
                  {`${salesRule.name}`}
                </option>
              ))}
            </Select>}
          </div>
        </Section>
      ) : null}
    </>
  );
};

export default SalesRuleComponen;
