import {Place, submitTickets, useTicketSelection,} from '../../state/TicketSelection';
import React, {useCallback, useState} from 'react';
import Button from '../Button';
import Section from '../Section';
import style from './style.module.css';
import {useDispatch} from 'react-redux';
import {useLocale} from '../../state/Localization';
import {usePlacesPersonalization} from '../../state/PlacePersonalization';
import {useVenueEvent} from '../../state/VenueEvent';
import {usePlacesReseating} from '../../state/PlaceReseating';
import {VenueAvailabilityData} from '../VenuePlan';
import {Strings} from '../../util/localization';
import {ChangeInputCallback} from "../PurchaseOptions/purchaseOptionsList";
import images from "../../assets/images";

const sumTickets = (places: Iterable<Place>): number => {
  let sum = 0;
  for (const place of places) {
    if (place.selectedPurchasableItem && new Date() < place.expiresAt) {
      sum += place.selectedPurchasableItem.grossAmount;
    }
  }
  return sum / 100;
};

type BasketSubmitProps = {
  availability: VenueAvailabilityData;
  salesChannel: string;
};

function getLabel(
  isDisabled: undefined | boolean,
  seatsAreAvailableForUser: boolean,
  strings: Strings,
  isLoading: boolean,
  salesChannel: string,
  isSeasonTicketEvent: boolean | undefined
) {
  if (isLoading) {
    return strings.SubmitBasket_Submit_Button_data_loading;
  }

  if (!seatsAreAvailableForUser) {
    return strings.SubmitBasket_Submit_Button_inactive_no_seats_available;
  }

  if (salesChannel === 'reseating') {
    return strings.SubmitBasket_Submit_Button_Reseating;
  }

  if (salesChannel === 'ticket_agency') {
    return strings.SubmitBasket_Submit_Button_Ticket_Agency;
  }

  if (isSeasonTicketEvent) {
    return strings.SubmitBasket_Submit_Button_SeasonTicketEvent;
  }

  if (isDisabled) {
    return strings.SubmitBasket_Submit_Button_inactive;
  }

  return strings.SubmitBasket_Submit_Button_active;
}

const BasketSubmitComponent: React.FC<BasketSubmitProps> = (props) => {
  const dispatch = useDispatch();
  const {language, strings} = useLocale();
  const placesPersonalization = usePlacesPersonalization();
  const venueEvent = useVenueEvent();
  const places = useTicketSelection()?.places ?? [];
  const bundles = useTicketSelection()?.bundles ?? [];
  const reseatingPlaces = usePlacesReseating().places;
  const {availability, salesChannel} = props;
  const [tosChecked, setTosChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const now = new Date();

  const selectionHasExpiredPlaces = !(
    places.find((place) => place.expiresAt < now) ===
    undefined && places?.length > 0
  );

  const selectionHasNotValidBundles = !!(
    bundles.find((place) => !place.valid)
  );

  const onSubmitBasketClicked = useCallback(() => {
    setLoading(true);
    dispatch(submitTickets());
  }, [dispatch]);
  const sum = sumTickets(places.values());

  const changeTos = useCallback<ChangeInputCallback>(
    (eventArgs) => setTosChecked(eventArgs.currentTarget.checked),
    [setTosChecked],
  );

  const tosRequiredAndNotChecked = !!(venueEvent.venueEvent?.tosRequired && !tosChecked);

  const isDisabled = selectionHasExpiredPlaces ||
    tosRequiredAndNotChecked ||
    selectionHasNotValidBundles ||
    !(places.find((place) =>
          !place.selectedPurchasableItem
          // exclude other subscriber's places not having a purchasable item
          && !reseatingPlaces.find((reseatingPlace) =>
            reseatingPlace.contractId === place.reseatingContractId
            && !!reseatingPlace.subscriberName
          )
      ) === undefined
      && places?.length > 0
    ) || (
      // if personalization is required and nothing is put as fullName, submit button should be disabled
      (venueEvent.venueEvent?.personalDataOnTicketIsRequired
        && placesPersonalization.placesPersonalization.filter((p) => p.fullName.length === 0).length > 0)
      // if personalization for phone number is enabled and first input is empty, submit button should be disabled
      || (venueEvent.venueEvent?.personalPhoneNumberOnTicketShouldBeRequired
        && (placesPersonalization.placesPersonalization.length > 0
          && placesPersonalization.placesPersonalization[0].phoneNumber.length < 1
        )
      )
    );

  const availablePlaceExists = (availability.availableSeatsCount > 0 || availability.availableStandingPlacesCount > 0);
  const placesAreAvailableForUser = (places?.length > 0 || availablePlaceExists);
  const isSeasonTicketEvent = !!venueEvent.venueEvent?.masterId;
  const TosBlock = (): JSX.Element => {
    if (venueEvent.venueEvent?.tosText) {
      return <div>
        {venueEvent.venueEvent?.tosRequired &&
            <input type="checkbox" className={style.Checkbox} id="tos" onChange={changeTos} checked={tosChecked}/>}
        <label htmlFor="tos" className={style.LabelText}>
            <span>
              <span dangerouslySetInnerHTML={{
                __html: venueEvent.venueEvent.tosText
              }}/>
            </span>
        </label>
      </div>;
    }

    return <></>;
  };

  return (
    <>
      <Section>
        {salesChannel !== 'reseating'
          ? <p className={style.SumRow}>
            <span>
              <strong>{strings.SubmitBasket_Sum}</strong>{' '}
              {strings.SubmitBasket_Corona_tos_first_line}
            </span>
            <strong>
              {sum.toLocaleString(language, {
                currency: 'EUR',
                style: 'currency',
              })}
            </strong>
          </p>
          : ''
        }

        <TosBlock/>

        {loading &&
            <div className={style.LoadingIndicatorContainer}>
              <img src={images.loadingIndicator} width='30' height='30' alt='Loading...'/>
            </div>
        }
        <Button
            onClick={onSubmitBasketClicked}
            variant="primary"
          disabled={isDisabled || loading}
          testid="submit"
        >
          {getLabel(
            isDisabled,
            placesAreAvailableForUser,
            strings,
            availability.version < 2,
            salesChannel,
            isSeasonTicketEvent
          )}
        </Button>
      </Section>
    </>
  );
};

export default BasketSubmitComponent;
