import React from 'react';
import {useLocale} from "../../state/Localization";

interface EventSearchProps {
  searchExpr: string;
  isSearchValid: boolean;
  onSearchExprChangeHandler: (searchExpr: string) => void;
  onSearchSubmitHandler: () => void;
}

const SalesChannelEventsSearchComponent: React.FC<EventSearchProps> = ({
  searchExpr,
  // isSearchValid,
  onSearchExprChangeHandler,
  // onSearchSubmitHandler,
}) => {
  const { strings } = useLocale();
  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = (event.target as HTMLInputElement).value;
    onSearchExprChangeHandler(inputValue);
  };

  return (
    <div>
      <input
        type="search"
        placeholder={strings.Search}
        aria-label="Search"
        value={searchExpr}
        onChange={onInputChanged}
      />
      {/*<button disabled={!isSearchValid} onClick={onSearchSubmitHandler}>Search</button>*/}
    </div>
  );
};

export default SalesChannelEventsSearchComponent;
