import {MessageLevel, MessageType, MessageWithId, removeMessage} from '../../state/Message';
import React, {useCallback} from 'react';
import {hydrateString, Locale} from '../../util/localization';
import style from './style.module.css';
import {useDispatch} from 'react-redux';
import {useLocale} from '../../state/Localization';
import {getAuthenticationState, redirectToLogin} from '../../auth';

const getMessageStyle = (level: MessageLevel): string => {
  switch (level) {
    case MessageLevel.Success:
      return `${style.Message} ${style.MessageLevelSuccess}`;
    case MessageLevel.Warning:
      return `${style.Message} ${style.MessageLevelWarning}`;
    case MessageLevel.Error:
      return `${style.Message} ${style.MessageLevelError}`;
  }
};

const getMessageText = (type: MessageType, locale: Locale): string => {
  switch (type) {
    case MessageType.InvalidPlace:
      return locale.strings.Error_place_not_valid;
    case MessageType.InvalidPurchasableItem:
      return locale.strings.Error_purchasableItem_not_valid;
    case MessageType.InvalidRightsProvider:
      return locale.strings.Error_rightsProvider_not_valid;
    case MessageType.SubscriptionIdNotFound:
      return locale.strings.Error_subscription_id_not_found;
    case MessageType.InvalidSeasonTicketLegalRecipientId:
      return locale.strings.Error_seasonTicketLegalRecipientId_not_valid;
    case MessageType.PersonalizationRequired:
      return locale.strings.Error_personalization_required;
    case MessageType.SalesChannelNotFound:
      return locale.strings.Error_sales_channel_not_found;
    case MessageType.APIClientError:
      return locale.strings.Error_API_CLIENT_ERROR;
    case MessageType.APIClientErrorUnauthorized:
      return locale.strings.Error_API_CLIENT_ERROR_UNAUTHORIZED;
    case MessageType.APIServerError:
      return locale.strings.Error_API_SERVER_ERROR;
    case MessageType.Error:
      return locale.strings.Error_ERROR;
    case MessageType.UserIsNotInTargetGroup:
      return locale.strings.Error_user_is_not_in_targetGroup;
    case MessageType.UserHasMaxTickets:
      return locale.strings.Error_user_has_max_tickets;
    case MessageType.PlaceIsBocked:
      return locale.strings.Error_place_is_bocked;
    case MessageType.FreePlacesAreMissing:
      return locale.strings.Error_free_places_are_missing;
    case MessageType.ErrorBookingNotFound:
      return locale.strings.Error_booking_not_found;
    case MessageType.ErrorWrongBooking:
      return locale.strings.Error_wrong_booking;
    case MessageType.UserDefaultAddressIsNotSet:
      return locale.strings.Error_default_address_is_not_set;
  }
};

export type MessageComponent = React.FC<{ message: MessageWithId }>;

export const SimpleMessage: MessageComponent = ({ message }) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const onRemoveClicked = useCallback(() => {
    dispatch(removeMessage(message.id));
  }, [dispatch, message.id]);
  let messageText = getMessageText(message.type, locale);

  if (message.messageParam !== undefined) {
    messageText = hydrateString(messageText, {
      errorMessageParam: message.messageParam,
    });
  }

  return (
    <div className={getMessageStyle(message.level)}>
      <div className={style.MessageMain}>{messageText}</div>
      <div className={style.MessageAside} onClick={onRemoveClicked}>
        x
      </div>
    </div>
  );
};

export const ReauthenticateMessage: MessageComponent = ({ message }) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const onRemoveClicked = useCallback(() => {
    dispatch(removeMessage(message.id));
  }, [dispatch, message.id]);
  const messageText = getMessageText(message.type, locale);

  const authenticationState = getAuthenticationState();
  const shoudlOfferToReauthenticate = (authenticationState.type === 'ANONYMOUS')
    || (authenticationState.type === 'AUTHENTICATED' && authenticationState.mode === 'SSO');

  return (
    <div className={getMessageStyle(message.level)}>
      <div className={style.MessageMain}>
        {messageText}&nbsp;
        {shoudlOfferToReauthenticate &&
        <a className={style.MessageLink} onClick={redirectToLogin}>{locale.strings.Message_ReauthenticatePrompt}</a>
        }
      </div>
      <div className={style.MessageAside} onClick={onRemoveClicked}>
        x
      </div>
    </div>
  );
};

export function getMessageComponentForType(type: MessageType): MessageComponent {
  switch (type) {
    case MessageType.APIClientErrorUnauthorized:
      return ReauthenticateMessage;
    default:
      return SimpleMessage;
  }
}

export default SimpleMessage;
