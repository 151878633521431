import {
  ActionType,
  SetResponseRevokeTicket,
} from './index';

export const setResponseRevokeTicket = (
    isRevokeTicketSent: boolean,
    responseRevokeStatus: number,
): SetResponseRevokeTicket => ({
  payload: { isRevokeTicketSent, responseRevokeStatus },
  type: ActionType.SET_RESPONSE_REVOKE_TICKET,
});
