import {Action, ActionType, RevokeTicketState} from './types';

const initialState: RevokeTicketState = {
  isRevokeTicketSent: false,
  responseRevokeStatus: 0
};

export const reducer = (state = initialState, action: Action): RevokeTicketState => {
  switch (action.type) {
    case ActionType.SET_RESPONSE_REVOKE_TICKET: {
      const { isRevokeTicketSent, responseRevokeStatus } = action.payload;
      return { ...state, isRevokeTicketSent, responseRevokeStatus };
    }
    default: {
      return state;
    }
  }
};
