import React, {createRef, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {FetchComponent, useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {clearPurchasableItems, usePurchasableItems} from '../../state/PurchasableItems';
import {Place, PurchasableItem, setPurchasableItem} from '../../state/TicketSelection';
import Select from '../Select';
import style from '../Ticket/style.module.css';

const PurchaseOptionsListPricingClassComponent: React.FC<{ place: Place; fetchComponent: FetchComponent }> = (
  {
    place,
    fetchComponent,
  }) => {
  const dispatch = useDispatch();
  const {language} = useLocale();

  const purchasableItemsState = usePurchasableItems();
  const purchasableItemsRef = createRef<HTMLSelectElement>();


  const purchasableItemPlace = purchasableItemsState?.placeId ?? '';
  const purchasableItemPurchasableItems = purchasableItemsState?.purchasableItems ?? [];

  useEffect(() => {
    if (purchasableItemPlace === place.id && purchasableItemPurchasableItems) {
      const select = purchasableItemsRef.current;
      if (select) {
        select.setAttribute('size', String(select.options.length));
      }
    }
  }, [place.id,
    purchasableItemPlace,
    purchasableItemPurchasableItems,
    purchasableItemsRef,
    purchasableItemsState
  ]);

  const onSelectedPurchasableItemChanged = useCallback(
    (eventArgs: React.ChangeEvent<HTMLSelectElement>) => {
      const placeId = place.id;
      const purchasableItemId = eventArgs.currentTarget.value;
      dispatch(setPurchasableItem(placeId, purchasableItemId, fetchComponent));
      dispatch(clearPurchasableItems());
    },
    [dispatch, place.id, fetchComponent],
  );

  const onPurchasableItemOptionClicked = useCallback(
    (eventArgs: React.MouseEvent<HTMLOptionElement>) => {
      if (eventArgs.currentTarget.value === place.selectedPurchasableItem?.id) {
        dispatch(clearPurchasableItems());
      }
    },
    [dispatch, place.id, place.selectedPurchasableItem, fetchComponent],
  );

  const purchasableItemName = (purchasableItem: PurchasableItem): string => {
    return `${purchasableItem.pricingClass.publicName} ${(
      (purchasableItem.grossAmount ?? 0) / 100
    ).toLocaleString(language, {
      currency: 'EUR',
      style: 'currency',
    })}`;
  };

  return (
    <div>
      {place.selectedPurchasableItem && (purchasableItemPlace !== place.id
        || (purchasableItemPlace === place.id && !purchasableItemPurchasableItems)) ? (
        <span>
          {purchasableItemName(place.selectedPurchasableItem)}
        </span>
      ) : (
        <Select
          className={style.PurchasableItemsSelect}
          onChange={onSelectedPurchasableItemChanged}
          value={place.selectedPurchasableItem ? place.selectedPurchasableItem.id : ''}
          forwardRef={purchasableItemsRef}
        >
          {purchasableItemPurchasableItems.map((purchasableItem, key) => (
            <option
              key={key} value={purchasableItem.id}
              onClick={onPurchasableItemOptionClicked}
            >
              {purchasableItemName(purchasableItem)}
            </option>
          ))}
        </Select>
      )}
    </div>
  );
};

export default PurchaseOptionsListPricingClassComponent;
