import { FetchIndicator } from '../../state/Fetch';
import React, {RefObject} from 'react';
import style from './style.module.css';
import images from '../../assets/images';

const SelectComponen: React.FC<{
  fetchIndicator?: FetchIndicator;
  onChange?: (eventArgs: React.ChangeEvent<HTMLSelectElement>) => void;
  onClick?: (eventArgs: React.MouseEvent<HTMLSelectElement>) => void;
  onFocus?: (eventArgs: React.FocusEvent<HTMLSelectElement>) => void;
  value?: string;
  className?: string;
  testid?: string;
  forwardRef?: RefObject<HTMLSelectElement>;
}> = ({ children, fetchIndicator, onChange, onClick, onFocus, value, className, testid, forwardRef }) => {
  if (fetchIndicator?.fetching && fetchIndicator.hasFocus) {
    return <img src={images.loadingIndicator} width='30' height='30' alt='fetching...'/>;
  }

  const fetchingStyle = fetchIndicator?.fetching ? style.Fetching : '';

  return (
    <select
      className={`${style.Select} ${fetchingStyle} ${className}`}
      disabled={fetchIndicator?.fetching}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      value={value}
      data-testid={testid}
      ref={forwardRef}
    >
      {children}
    </select>
  );
};

export default SelectComponen;
