export enum PlaceGraphicalState {
  UNKNOWN,
  AVAILABLE,
  UNAVAILABLE,
  SELECTED,
  PROCESSING,
  RESEATING,
  RESEATING_COMPLETED,
  RESEATING_SELECTED,
  RESEATING_AVAILABLE
}
