import { Plugin, Viewport } from 'pixi-viewport';
import * as PIXI from 'pixi.js';
import { lerp } from './utils';

export interface ZoomOptions {
  targets: {
    centerX: number
    centerY: number
    width: number
    height: number
    duration: number
    ease: (t: number) => number
  }[]
  interruptable?: boolean
}

interface ZoomBorders {
  left: number
  right: number
  top: number
  bottom: number,
  duration: number
  endTime: number
  ease?: (t: number) => number
}

/**
 * pixi-viewport plugin, that combines zoom and move to the specified point
 * it also disable clamp while animating
 *
 * Original combo of 'snap-zoom' + 'snap' works not good enough
 * (ugly animation: clamps on the way, strange shift at the end)
 */
export class ViewportZoomToPointPlugin extends Plugin {
  private readonly viewport: Viewport;
  private waypoints: ZoomBorders[] = [];
  private startTime = 0;
  private active = false;
  private duration: number = 0;
  private interruptable = true;

  constructor(viewport: Viewport) {
    super(viewport);

    this.viewport = viewport;
  }

  /** Call this to zoom to point animation */
  zoomToPoint(
    options: ZoomOptions,
  ) {
    this.waypoints = [
      // current position
      {
        left: this.viewport.center.x - this.viewport.worldScreenWidth * 0.5,
        right: this.viewport.center.x + this.viewport.worldScreenWidth * 0.5,
        top: this.viewport.center.y - this.viewport.worldScreenHeight * 0.5,
        bottom: this.viewport.center.y + this.viewport.worldScreenHeight * 0.5,
        endTime: 0,
        duration: 0,
      },
    ];
    let timeSum = 0;
    for (const target of options.targets) {
      timeSum += target.duration;
      const widthScale = this.viewport.screenWidth / target.width;
      const heightScale = this.viewport.screenHeight / target.height;
      const minScale = Math.min(widthScale, heightScale);
      const normalizedWidth = this.viewport.screenWidth / minScale;
      const normalizedHeight = this.viewport.screenHeight / minScale;
      this.waypoints.push(
        {
          left: target.centerX - normalizedWidth * 0.5,
          right: target.centerX + normalizedWidth * 0.5,
          top: target.centerY - normalizedHeight * 0.5,
          bottom: target.centerY + normalizedHeight * 0.5,
          duration: target.duration,
          ease: target.ease,
          endTime: timeSum,
        },
      );
    }
    this.duration = timeSum;

    this.active = true;
    this.startTime = performance.now();
    this.interruptable = options.interruptable || true;

    this.viewport.plugins.pause('clamp');
    this.viewport.plugins.pause('clamp-zoom');
  }

  isActive(): boolean {
    return this.active;
  }

  resize() {
    if (this.active && this.interruptable) {
      this.stop();
    }
  }

  wheel(_event: WheelEvent) {
    if (this.active && this.interruptable) {
      this.stop();
    }
  }

  down(_event: PIXI.InteractionEvent) {
    if (this.active && this.interruptable) {
      this.stop();
    }
  }

  update() {
    if (!this.active) return;

    const time = Math.min(performance.now() - this.startTime, this.duration);
    const waypoints = this.waypoints;
    let waypointIndex = 1;
    for (; waypointIndex < waypoints.length; waypointIndex++) {
      if (waypoints[waypointIndex].endTime >= time) {
        break;
      }
    }

    const ease = waypoints[waypointIndex].ease!;
    const waypointPart = ease((time - waypoints[waypointIndex - 1].endTime) / waypoints[waypointIndex].duration);

    const left = lerp(waypoints[waypointIndex - 1].left, waypoints[waypointIndex].left, waypointPart);
    const top = lerp(waypoints[waypointIndex - 1].top, waypoints[waypointIndex].top, waypointPart);

    const right = lerp(waypoints[waypointIndex - 1].right, waypoints[waypointIndex].right, waypointPart);
    const bottom = lerp(waypoints[waypointIndex - 1].bottom, waypoints[waypointIndex].bottom, waypointPart);

    // convert to center and scale
    const centerX = (right + left) * 0.5;
    const centerY = (top + bottom) * 0.5;
    const width = right - left;
    const scale = this.viewport.screenWidth / width;

    // apply calculations to viewport
    this.viewport.scale.set(scale);
    this.viewport.moveCenter(centerX, centerY);

    // check we are finished
    if (time >= this.duration) {
      this.stop();
    }
  }

  stop() {
    this.active = false;
    this.viewport.plugins.resume('clamp');
    this.viewport.plugins.resume('clamp-zoom');
  }
}
