import React from 'react';
import { apiGETRequest, apiPOSTRequest, generateURL } from '../../util/apiRequest';
import { FetchComponent, createFetchActions } from '../Fetch';
import {
  ActionType,
  Mode,
  Place as PlaceContractFlat,
  PlaceContract,
  ValidateAnotherSubscriberAPIResponse
} from './types';
import { VenueSelectedPlace } from '../../components/VenuePlan/data';
import { Place } from '../TicketSelection/types/Place';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';
import { API_ENDPOINTS } from '../../config.json';
import { ensureBasicState } from '../TicketSelection/utils/ensureBasicState';
import { mapReseatingStateAPIResponse } from './mapReseatingStateAPIResponse';
import { SyncThunkAction } from '../thunkAction';
import { toast } from 'react-toastify';

const useShowSuccessToast = (place: PlaceContract, index: number): void => {
  const content = <>
    <p><strong>{ index }. { place.seasonTicketLegalRecipient }</strong></p>
    Block  { place.blockLabel }
    { place.blockType === 'seating' &&
      <>,  Reihe  { place.rowLabel },  Platz { place.seatLabel } </>
    }
  </>;

  toast(content, {
    closeButton: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    progressStyle: { background: '#009e0f' }
  });
};

export const processReseating = (placeId: string): SyncThunkAction => (dispatch, getState): void => {
  const appState = getState();
  const { venuePlaces, places } = appState.placesReseating;
  const venuePlace = venuePlaces.find((place) => place.place.id === placeId);
  const index = appState.placesReseating.selectedPlaces.length + 1;

  if (venuePlace) {
    let mode = Mode.View;
    let venueActionType = ActionType.DESELECT;
    let selectedActionType = ActionType.REMOVE_SELECTED_PLACE;

    if (venuePlace.status === 'reseating') {
      venueActionType = ActionType.SELECT;
      selectedActionType = ActionType.ADD_SELECTED_PLACE;
      mode = Mode.Selected;
    }
  
    dispatch({ payload: { placeId }, type: venueActionType });
    dispatch({ payload: { placeId, mode }, type: ActionType.TOGGLE_PLACE_MODE });

    const place: PlaceContract | undefined = places.find((place) => place.id === placeId);

    if (place) {
      dispatch({ payload: { placeId, reseatingContractId: place.contractId }, type: selectedActionType });

      if (venuePlace.status === 'reseating') {
        useShowSuccessToast(place, index);
      }
    }
  }
};

export const getPlacesReseating = (
  contractId: string,
  ticketSelection: Place[],
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);

  try {
    const { purchaseForTicketHolderId, venueEventId } = ensureBasicState(getState());
    const endpoint = API_ENDPOINTS.GET_RESEATING_SEATS;
    const requestURL = generateURL(endpoint, {
      params: { venueEventId },
      query: { purchaseForTicketHolderId, salesChannel: 'reseating' }
    });

    const response = await apiGETRequest(requestURL);
    const state = mapReseatingStateAPIResponse(response);

    dispatch({ payload: { state }, type: ActionType.SET_RESEATING });

    if (contractId) {
      const selectedPlace = state.places.find((place) => place.contractId === contractId);
      const completed = !!ticketSelection.find((place) => place.reseatingContractId === contractId);

      if (selectedPlace && !completed) {
        dispatch(processReseating(selectedPlace.id));
      }
    }

  } catch (error) {
    handleError(dispatch, error as Error);
  } finally {
    dispatch(fetchStop);
  }
};

export const fetchOtherSubscriberPlaces = (
  emailOrSubjectId: string,
  checkoutId: number | null,
  excludePlaceIds: string[],
  callback: (response: ValidateAnotherSubscriberAPIResponse[]) => void,
  blockId: string,
  seatId?: string,
  fetchComponent?: FetchComponent
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);

  dispatch(fetchStart);
  try {
    const appState = getState();

    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      venueEventId
    } = ensureBasicState(
      appState,
    );

    const requestURL = generateURL(API_ENDPOINTS.GET_OTHER_SUBSCRIBER_SEAT, {
      params: { venueEventId },
      query: { purchaseForTicketHolderId, salesChannel, salesRuleId, subscriptionId },
    });

    const body: any = {
      checkoutId,
      emailOrSubjectId,
      blockId
    };

    if (seatId) {
      body.seatId = seatId;
    } else {
      body.excludePlaceIds = excludePlaceIds;
    }

    const responsePlaces: ValidateAnotherSubscriberAPIResponse[] = await apiPOSTRequest(requestURL, body);

    if (callback) callback(responsePlaces);

  } catch (error) {
    handleError(dispatch, error as Error);
  } finally {
    dispatch(fetchStop);
  }
};

export const addOtherSubscriberPlaces = (
  places: PlaceContractFlat[], venuePlaces: VenueSelectedPlace[]
): SyncThunkAction => (dispatch, getState): void => {
  const appState = getState();
  const state = appState.placesReseating;

  state.places = state.places.concat(places);
  state.venuePlaces = state.venuePlaces.concat(venuePlaces);

  dispatch({ payload: { state }, type: ActionType.SET_RESEATING });
};

export const deleteOtherSubscriberPlace = (placeId: string): SyncThunkAction => (dispatch, getState): void => {
  const appState = getState();
  const state = appState.placesReseating;

  const placeIndex = state.places.findIndex((place) => place.id === placeId);
  if (placeIndex >= 0) {
    state.places.splice(placeIndex, 1);
  }

  const venuePlaceIndex = state.venuePlaces.findIndex((place) => place.place.id === placeId);
  if (placeIndex >= 0) {
    state.venuePlaces.splice(venuePlaceIndex, 1);
  }

  dispatch({ payload: { state }, type: ActionType.SET_RESEATING });
};
