import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFetch } from '../../state/Fetch';
import _ from 'lodash';
import style from './style.module.css';
import images from '../../assets/images';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocale } from '../../state/Localization';
import { useVenuePlan } from '../../state/VenuePlan';
import { VenuePlan } from '../../state/VenuePlan/types';
import { addOtherSubscriberPlaces, fetchOtherSubscriberPlaces } from '../../state/PlaceReseating/actions';
import { usePlacesReseating } from '../../state/PlaceReseating';
import { VenueSelectedPlace } from '../../components/VenuePlan/data';
import TicketReseatingRecipientSelectionComponent from '../TicketReseatingRecipientSelection';
import {
  Mode,
  ValidateAnotherSubscriberAPIResponse,
  Place as PlaceContractFlat,
} from '../../state/PlaceReseating/types';

type FormProps = {
  emailOrSubjectId: string;
  checkoutId: number | null;
  blockId: string;
  rowLabel: string;
  seatId: string;
  agreement: boolean;
};

const initialState: FormProps = {
  emailOrSubjectId: '',
  checkoutId: null,
  blockId: '',
  rowLabel: '',
  seatId: '',
  agreement: false,
};

const TicketReseatingAddSubscriberComponent: React.FC = () => {

  const { strings } = useLocale();
  const { fetchComponent, fetchIndicator } = useFetch();
  const placesReseating = usePlacesReseating();
  const venuePlan = useVenuePlan();
  const dispatch = useDispatch();

  const [placesData, setPlacesData] = useState<VenuePlan>();
  const [isShowForm, setIsShowForm] = useState(false);
  const [state, setState] = useState(initialState);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const [blocks, setBlocks] = useState<{id: string; label: string}[]>([]);
  const [rows, setRows] = useState<string[]>([]);
  const [seats, setSeats] = useState<{id: string; label: string}[]>([]);

  const [showRecipientSelectionModal, setShowRecipientSelectionModal] = useState(false);
  const [standingPlaces, setStandingPlaces] = useState<PlaceContractFlat[]>([]);
  const [venuePlaces, setVenuePlaces] = useState<VenueSelectedPlace[]>([]);

  // Set placesData when venuePlan has loaded
  useEffect(() => {
    setPlacesData(venuePlan.venuePlan);
  }, [venuePlan]);

  // Set blocks when placesData changes
  useEffect(() => {
    if (!placesData) return;

    const standingBlocks = placesData.standingBlocks?.map((block) => ({
      id: block.id, label: block.blockLabel
    })) || [];

    const seatingBlocks = placesData.seats?.map((seat) => ({
      id: seat.blockId, label: seat.blockLabel
    })) || [];

    setBlocks(
      _.uniqBy([...standingBlocks, ...seatingBlocks], 'id').sort(
        (a, b) => a.label.localeCompare(b.label, 'de', { numeric: true, sensitivity: 'base' })
      )
    );
  }, [placesData]);

  // Set rows when block has changed
  useEffect(() => {
    if (!state.blockId || !placesData) return;

    const seats = placesData.seats?.filter((seat) => seat.blockId === state.blockId) || [];
    const rowsInBlock = _.uniq(seats.map((seat) => seat.rowLabel)).sort(
      (a, b) => a.localeCompare(b, 'de', { numeric: true, sensitivity: 'base' })
    );

    setRows(rowsInBlock);
    setState({
      ...state,
      rowLabel: rowsInBlock.length === 1 ? rowsInBlock[0] : initialState.rowLabel
    });

  }, [state.blockId]);

  // Set seats when row has changed
  useEffect(() => {
    if (!state.rowLabel || !placesData) {
      setState({ ...state, seatId: initialState.seatId });
      return;
    }

    const seatsInRow = placesData.seats?.filter(
      (seat) => seat.blockId === state.blockId && seat.rowLabel === state.rowLabel
    ).map(
      (seat) => ({id: seat.id, label: seat.seatLabel})
    ).sort(
      (a, b) => a.label.localeCompare(b.label, 'de', { numeric: true, sensitivity: 'base' })
    );

    setSeats(seatsInRow || []);
    setState({ ...state, seatId: initialState.seatId });
  }, [state.rowLabel]);

  const handleToggleForm = (): void => {
    setIsShowForm(!isShowForm);
  };

  const validateForm = (): boolean => {
    const errors: string[] = [];

    for (const [key, value] of Object.entries(state)) {
      if (!rows.length && ['rowLabel', 'seatId'].includes(key)) continue;
      if (!value) errors.push(key);
    }

    setFormErrors(errors);

    return !errors.length;
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement|HTMLSelectElement>): void => {
    const target = e.target as HTMLInputElement;
    let value: any = target.type === 'checkbox' ? target.checked : target.value;

    if (target.name === 'checkoutId') {
      if (value === '') {
        value = null;
      } else {
        value = parseInt(value);
      }
    }

    setState({ ...state, [target.name]: value });
    setFormErrors(formErrors.filter((error) => error !== target.name));
  };

  const onSuccess = (responsePlaces: ValidateAnotherSubscriberAPIResponse[]): void => {
    const places: PlaceContractFlat[] = [];
    const venuePlaces: VenueSelectedPlace[] = [];

    for (const responsePlace of responsePlaces) {
      const alreadyAdded = !!places.find(
        (place) => place.contractId === responsePlace.contractId
      );

      if (!alreadyAdded) {
        const { checkoutId, emailOrSubjectId } = state;
        const venuePlace: VenueSelectedPlace = {
          place: {
            id: responsePlace.id,
            contractId: responsePlace.contractId,
            type: responsePlace.blockType === 'standing' ? 'block' : 'seat'
          },
          status: 'reseating'
        };

        const place: PlaceContractFlat = {
          ...responsePlace,
          mode: Mode.View,
          checkoutId,
          emailOrSubjectId
        };

        places.push(place);
        venuePlaces.push(venuePlace);
      }
    }

    if (places[0]?.blockType === 'standing') {
      setStandingPlaces(places);
      setVenuePlaces(venuePlaces);
      setShowRecipientSelectionModal(true);
    } else {
      dispatch(addOtherSubscriberPlaces(
        places, venuePlaces
      ));
    }

    setState(initialState);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!validateForm()) return;

    const excludePlaceIds = placesReseating.places
      .filter((place) => place.blockType === 'standing' && !!place.subscriberName)
      .map((place) => place.id);

    dispatch(fetchOtherSubscriberPlaces(
      state.emailOrSubjectId,
      state?.checkoutId,
      excludePlaceIds,
      onSuccess,
      state.blockId,
      !state.seatId ? undefined : state.seatId,
      fetchComponent
    ));
  };

  return (
    <div className={`${style.Container} ${isShowForm ? style.ShowForm : ''}`}>

      <div className={style.Header} onClick={handleToggleForm}>
        <strong>{strings.ReseatingAddSubscriber_Header}</strong>
        <span className={style.Toggle}><FontAwesomeIcon icon={faCaretDown} /></span>
      </div>

      <div className={style.Form}>
        <strong className={style.Description}>{strings.ReseatingAddSubscriber_Description}</strong>
        <form onSubmit={handleFormSubmit}>

          <div className={style.FormRow}>
            <label className={formErrors.includes('emailOrSubjectId') ? style.Error : ''}>
              {strings.ReseatingAddSubscriber_Email}
              <input type="text"
                     name="emailOrSubjectId"
                     value={state.emailOrSubjectId}
                     disabled={fetchIndicator.fetching}
                     onChange={handleInputChange}
              />
            </label>

            <label className={formErrors.includes('checkoutId') ? style.Error : ''}>
              {strings.ReseatingAddSubscriber_OrderId}
              <input type="number"
                     name="checkoutId"
                     value={state.checkoutId || ''}
                     disabled={fetchIndicator.fetching}
                     onChange={handleInputChange}
              />
            </label>
          </div>

          <div className={style.SeatSelection}>
            <label>{strings.ReseatingAddSubscriber_Place}</label>

            <div>
              <select name="blockId"
                      value={state.blockId}
                      onChange={handleInputChange}
                      className={formErrors.includes('blockId') ? style.Error : ''}
                      disabled={fetchIndicator.fetching}>
                <option disabled value=''>{strings.Shared_Block}</option>

                {blocks.map((block) =>
                  <option key={block.id} value={block.id}>{block.label}</option>
                )}
              </select>

              {!!rows.length &&
                <select name="rowLabel"
                        value={state.rowLabel}
                        disabled={!state.blockId || fetchIndicator.fetching}
                        onChange={handleInputChange}
                        className={formErrors.includes('rowLabel') ? style.Error : ''}>
                  <option disabled value=''>{strings.Shared_Row}</option>

                  {rows.map((row) =>
                    <option key={row} value={row}>{row}</option>
                  )}
                </select>
              }

              {!!rows.length &&
                <select name="seatId"
                        value={state.seatId}
                        disabled={!state.rowLabel || fetchIndicator.fetching}
                        onChange={handleInputChange}
                        className={formErrors.includes('seatId') ? style.Error : ''}>
                  <option disabled value=''>{strings.Shared_Seat}</option>

                  {seats.map((seat) =>
                    <option key={seat.id} value={seat.id}>{seat.label}</option>
                  )}
                </select>
              }
            </div>
          </div>

          <div className={style.Agreement}>
            <label className={formErrors.includes('agreement') ? style.Error : ''}>
              <input type="checkbox"
                     name="agreement"
                     checked={state.agreement}
                     onChange={handleInputChange}
                     disabled={fetchIndicator.fetching}
              />
              {strings.ReseatingAddSubscriber_Agreement}
            </label>
          </div>

          <div className={style.Submit}>
            {fetchIndicator?.fetching &&
              <img src={images.loadingIndicator} width='30' height='30' alt='fetching...' />
            }
            <input type="submit"
                   name="submit"
                   disabled={fetchIndicator.fetching}
                   value={strings.ReseatingAddSubscriber_Submit} />
          </div>

        </form>
      </div>

      <TicketReseatingRecipientSelectionComponent
        places={standingPlaces}
        venuePlaces={venuePlaces}
        blockName={standingPlaces[0]?.blockLabel}
        showRecipientSelectionModal={showRecipientSelectionModal}
        setShowRecipientSelectionModal={setShowRecipientSelectionModal}
      />

    </div>
  );
};

export default TicketReseatingAddSubscriberComponent;
