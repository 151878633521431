import log, { LogLevelDesc } from 'loglevel';

const DEFAULT_LOGLEVEL = process.env.REACT_APP_LOGLEVEL as LogLevelDesc;

log.setDefaultLevel(DEFAULT_LOGLEVEL);

export function getRootLogger(): log.Logger {
  return log;
}

export function getNamedLogger(name: string): log.Logger {
  const logger = log.getLogger(name);

  // A named logger inherits the current loglevel of the root logger. Since this
  // might have changed before the named logger is created we set it to the
  // configured default to avoid any unexpected behaviour.
  logger.setDefaultLevel(DEFAULT_LOGLEVEL);

  return logger;
}
