export enum ActionType {
  GET_BLOCK_GROUPS = 'BLOCK_GROUP::GET_BLOCK_GROUPS',
  ERROR = 'BLOCK_GROUP::ERROR',
  FETCH = 'BLOCK_GROUP::FETCH',
  SUCCESS = 'BLOCK_GROUP::SUCCESS',
  SET_BLOCK_GROUP = 'BLOCK_GROUP::SET_BLOCK_GROUP',
}

// single block group
export interface BlockGroup {
  backendNameShort: string;
  id: string;
  name: {
    de: string;
    en: string;
  };
  [key: string]: unknown;
}

// block group state
export interface BlockGroupState {
  error?: Error;
  fetching: boolean;
  blockGroups?: BlockGroup[];
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { blockGroups: BlockGroup[] };
  type: typeof ActionType.SUCCESS;
}

export type Action = ErrorAction | FetchAction | SuccessAction;
