import { getFullNameFromUserData } from '../../util/personalization';
import { AppState } from '../index';

/**
 * Selects the full name to use for personalization if available.
 */
export const selectPersonalizationFullName = (state: AppState): string | undefined => {
  if (state.ticketSelection?.purchaseForTicketHolder) {
    return getFullNameFromUserData(state.ticketSelection.purchaseForTicketHolder);
  } else if (state.session.user) {
    return getFullNameFromUserData(state.session.user);
  } else {
    return undefined;
  }
};
