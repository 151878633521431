import React, { useEffect, useRef, useState } from 'react';
import Collapsible from '../Collapsible';
import Section from '../Section';

import style from './style.module.css';
import { useLocale } from '../../state/Localization';
import { useDispatch } from 'react-redux';
import {
    PlacesAvailabilityData,
    VenueAvailabilityData,
    VenueStandingBlockData
} from '../VenuePlan';
import {apiGETRequest, generateURL, HTTPKnownError, KnowErrors} from '../../util/apiRequest';
import {API_ENDPOINTS} from '../../config.json';
import {handleError} from '../../util/handleError';
import {indexOf, pickBy} from 'lodash';
import {useFetch} from '../../state/Fetch';
import {useSession} from '../../state/Session';
import {addStandingPlace, deletePlaces, useTicketSelection} from '../../state/TicketSelection';
import {getVenueEvent} from '../../state/VenueEvent';

export const AVAILABILITY_UPDATE_INTERVAL = 5000;
const SEAT_QUERY_SERIALIZATION_VARIANT_FULL = 'place_selection';
const SEAT_QUERY_SERIALIZATION_VARIANT_ID_ONLY = 'id_only';

export interface VenuePlanProps {
  availability: VenueAvailabilityData;
  setAvailability: (value: (
    ((prevState: VenueAvailabilityData) => VenueAvailabilityData) | VenueAvailabilityData
  )) => void;

  /**
   * Subject user id for backend purchase.
   */
  purchaseForTicketHolderId: string;

  /**
   * Subscription id for season ticket purchase.
   */
  subscriptionId: string;

  /**
   * Sales rule id for backend purchase.
   */
  salesRuleId: string;

  /**
   * The sales channel in which the place selection takes place.
   */
  salesChannel: string;

  /**
   * The ID of the event for which the venue plan should be displayed.
   */
  eventId: string;

    /**
     * Queue it token for the event.
     */
    queueItToken: string;

  /**
   * The name of the venue layout to use.
   */
  venueLayout: string;

  /**
   * Unique venue plan version
   */
  venuePlanVersionId: string;

  /**
  * The action code for the place selection.
  */
  actionCode: string;

  /**
   * Organization id for impersonality user.
   * */
  organizationId: string;
}

export interface BlockInWork {
    block: VenueStandingBlockData;
    countSelectedPlaces: number;
}

export const PlaceSelectionWithoutGraphicalVenuePlan: React.FC<VenuePlanProps> = (props) => {
    const NUMBER_REGEX = RegExp('^[0-9]+$');

    const { availability, setAvailability } = props;

    const [blocksInWork, setBlocksInWork] = useState<BlockInWork[]>([]);
    const [isShowMainLoader, setIsShowMainLoader] = useState(true);

    const { fetchComponent, fetchIndicator } = useFetch();
    const { strings } = useLocale();
    const { selectedRightsProvider, user } = useSession();
    const ticketSelection = useTicketSelection();
    const dispatch = useDispatch();

    /**
     * @param availableOnly Flag if only data for available seats should be return.
     * @param idOnly Flag if serialization limited to the ID should be requested.
     */
    function createSeatQueryParams({ idOnly = false } = {}): { [key: string]: string } {
        const serializerGroup = idOnly ?
            SEAT_QUERY_SERIALIZATION_VARIANT_ID_ONLY :
            SEAT_QUERY_SERIALIZATION_VARIANT_FULL;

        return pickBy({
            purchaseForTicketHolderId: props.purchaseForTicketHolderId,
            rightsProviderId: selectedRightsProvider?.id ?? '',
            salesChannel: props.salesChannel,
            salesRuleId: props.salesRuleId,
            serializerGroup,
            subscriptionId: props.subscriptionId,
            actionCode: props.actionCode,
            userId: user?.id ?? '',
            organizationId: props.organizationId
        });
    }

    function setAvailabilityFromData(availabilityData: PlacesAvailabilityData): void {
        const capacityByBlock = new Map(availabilityData.standingBlocks.map((b) => [b.id, b.availableCapacity]));
        const getRemainingBlockCapacity = (blockId: string): number => capacityByBlock.get(blockId) ?? 0;

        setAvailability((prevState) => {
            return {
                ...prevState,
                getRemainingBlockCapacity,
                isBlockAvailable(blockId: string): boolean {
                    return getRemainingBlockCapacity(blockId) > 0;
                },
                availableSeatsCount: availabilityData.standingBlocks.length,
                version: prevState.version + 1,
            };
        });

        if (availability.version >= 1) {
            setIsShowMainLoader(false);
        }
    }

    // update of seat availability is performed periodically via setTimout()
    // setup by useEffect(). To be able to access the current props & state
    // for the request we store the update function in a ref on each
    // render to close over the current props & state obviating the need
    // to cancel any pending updates. For further explanation please refer to
    // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    const updateAvailability = useRef<() => Promise<void>>();

    useEffect(() => {
        updateAvailability.current = async () => {
            const requestURL = generateURL(API_ENDPOINTS.GET_AVAILABLE_SEATS, {
                params: { venueEventId: props.eventId },
                query: createSeatQueryParams({ idOnly: true }),
            });

            return apiGETRequest(requestURL).then((availabilityData: PlacesAvailabilityData) => {
                if (props.venuePlanVersionId !== null && props.venuePlanVersionId !== availabilityData.venuePlanVersionId) {
                    dispatch(getVenueEvent(props.eventId, props.salesChannel, props.queueItToken, user?.id ?? ''));
                    return;
                }

                if (availabilityData.errorType !== null && availabilityData.errorType !== undefined) {
                    if (availabilityData.errorType === KnowErrors.FreePlacesAreMissing) {
                        let isSelectedPlaces = false;
                        for (const blockInWork of blocksInWork) {
                            if (blockInWork.countSelectedPlaces > 0) {
                                isSelectedPlaces = true;
                                break;
                            }
                        }
                        if (!isSelectedPlaces) {
                            handleError(dispatch, new HTTPKnownError(200, availabilityData.errorType));
                        }
                    } else {
                        handleError(dispatch, new HTTPKnownError(200, availabilityData.errorType));
                    }
                }

                setAvailabilityFromData(availabilityData);
            }, (error) => {
                handleError(dispatch, error);
            });
        };
    });

    // Start periodic update of seat availability.
    //
    // This needs to be done on the first render ONLY as the request to fetch the
    // availability is placed in a ref it is guaranteed that the currently
    // selected parameters  like sales rule etc. are used, therefore it is not
    // necessary to schedule this periodic update anew if those parameters change.
    useEffect(() => {
        (function scheduleAvailabilityUpdate() {
            setTimeout(() => {
                // refer to update function closed over the current props & state.
                updateAvailability.current?.().then(scheduleAvailabilityUpdate);
            }, AVAILABILITY_UPDATE_INTERVAL);
        })();
    }, []);


    // trigger initial load of blocks
    useEffect(() => {
        const loadPlacesData = async () => {
            const requestURL = generateURL(API_ENDPOINTS.GET_SEATS, {
                params: { venuePlanVersionId: props.venuePlanVersionId },
                query: createSeatQueryParams(),
            });

            return apiGETRequest(requestURL);
        };

        const loadVenuePlanData = async () => {
            try {
                const [placesData] = await Promise.all([loadPlacesData()]);

                const blocks = placesData.standingBlocks.map((b: VenueStandingBlockData) => {
                    return {block: b, countSelectedPlaces: 0};
                });

                setBlocksInWork(blocks);
            } catch (error) {
                handleError(dispatch, error as Error);
            }
        };

        loadVenuePlanData();
        // The venue plan itself is not dependent on the currently selected
        // sales rule therefore it is not listed here.
    }, [props.eventId, props.venueLayout, props.venuePlanVersionId]);

    // When parameters that possibly influence the availability of seats change
    // we have no choice but to assume that no seats are available to prevent
    // erroneous selections as we won't know which seats are available under
    // the new  parameters until we receive the next update from the API.
    useEffect(() => {
        setAvailabilityFromData({ seats: [], standingBlocks: [], errorType: null, venuePlanVersionId: null });
    }, [props.salesRuleId, props.salesChannel, selectedRightsProvider]);

    const getWorkingBlockByBlockId = (blockId: string): BlockInWork|undefined => {
        return blocksInWork.find((b) => {
            return b.block.id === blockId;
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, block: BlockInWork) => {
        if (NUMBER_REGEX.exec(e.target.value)) {
            e.target.blur();

            const blockWithPrevState = getWorkingBlockByBlockId(block.block.id);

            const blockWithNewState = {
                block: block.block,
                countSelectedPlaces: Math.max(0, parseInt(e.target.value,10))
            };

            if (blockWithPrevState) {
                let difference = blockWithNewState.countSelectedPlaces - blockWithPrevState.countSelectedPlaces;
                if (difference < 0) {
                    const places = ticketSelection?.places;
                    if (places) {
                        const placesNeedDelete = [];
                        for (let i = places.length - 1; i >= 0; i--) {
                            if (places[i].blockId === blockWithNewState.block.id) {
                                placesNeedDelete.push(places[i].id);

                                if (placesNeedDelete.length >= Math.abs(difference)) {
                                    break;
                                }
                            }
                        }

                        dispatch(deletePlaces(placesNeedDelete, fetchComponent));
                    }
                } else if (difference > 0) {
                    difference = Math.min(Math.abs(difference), availability.getRemainingBlockCapacity(block.block.id));
                    dispatch(addStandingPlace(blockWithNewState.block.id, undefined, Math.abs(difference)));
                }
            }

            //update state with blockInWork with new value
            const newBlocks = blocksInWork.map((obj) => {
                return obj.block.id === blockWithNewState.block.id ? blockWithNewState : obj;
            });

            setBlocksInWork(newBlocks);
        }
    };

    const getCountSelectedPlacesByBlockId = (blockId: string) => {
        const selectedBlock = getWorkingBlockByBlockId(blockId);

        if (selectedBlock) {
            return selectedBlock.countSelectedPlaces;
        }

        return 0;
    };

    //populating blocksInWork with places from selection after reloading page
    useEffect(() => {
        const updatedBlocksInWork: BlockInWork[] = [];
        const places = ticketSelection?.places;

        for (let i = 0; i < blocksInWork.length; i++) {
            updatedBlocksInWork.push({
                block: blocksInWork[i].block,
                countSelectedPlaces: 0
            });
        }

        if (places) {
            places.forEach((place) => {
                const blockFromCurrentPlace = updatedBlocksInWork.find((block) => {
                    return block.block.id === place.blockId;
                });

                const index = indexOf(updatedBlocksInWork, blockFromCurrentPlace);

                if (blockFromCurrentPlace && index !== -1) {
                    blockFromCurrentPlace.countSelectedPlaces += 1;
                    updatedBlocksInWork[index] = blockFromCurrentPlace;
                }
            });
        }

        setBlocksInWork(updatedBlocksInWork);
    }, [ticketSelection?.places]);

    // show or not spinner on blocks
    const classNameForDisableInputs = `${style.LoadingInsteadInputs} 
        ${!!fetchIndicator?.fetching || isShowMainLoader ? '' : style.DisplayNone}`;

    const isShowBlock = (block: BlockInWork) => {
        return availability.isBlockAvailable(block.block.id)
            || (!availability.isBlockAvailable(block.block.id) && block.countSelectedPlaces);
    };

    return (
        <Section border={true}>
            <Collapsible header={strings.PlaceSelectionWithoutGraphicalVenuePlan_Heading} initial="visible">
                <div className={style.StandingBlockSelection}>
                    <div className={classNameForDisableInputs}>
                        <span>
                          <div className={style.Loader}></div>
                       </span>
                    </div>

                    <div className="standing-blocks">
                        {blocksInWork.map((block, key) => (
                            (isShowBlock(block) ? (
                                <div className={style.StandingBlock} key={key}>
                                    <input
                                        min={0}
                                        type="number"
                                        onChange={(e) => handleInputChange(e, block)}
                                        value={getCountSelectedPlacesByBlockId(block.block.id)}
                                    />
                                    <div className={style.BlockLabel}>{block.block.blockLabel}</div>
                                </div>
                            ) : '')
                        ))}
                    </div>
                </div>
            </Collapsible>
        </Section>
    );
};

export default PlaceSelectionWithoutGraphicalVenuePlan;
