import React, { useCallback, useState } from 'react';
import Chevron from '../Chevron';
import style from './style.module.css';

type InitialState = 'visible' | 'collapsed' | undefined;
type StyleVariant = 'main' | 'sub' | undefined;

const selectStyle = (variant: StyleVariant): string => {
  switch (variant) {
    case 'sub':
      return style.TextHeadingSub;
    case 'main':
    default:
      return style.TextHeadingMain;
  }
};

const CollapsibleComponent: React.FC<{
  header?: string;
  initial?: InitialState;
  variant?: StyleVariant;
  testid?: string;
}> = ({ children, header, initial, variant, testid }) => {
  const [collapsed, setCollapsed] = useState(initial !== 'visible');
  const onCollapseClicked = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  return (
    <>
      <div className={style.ClickArea} onClick={onCollapseClicked} data-testid={testid}>
        <strong className={selectStyle(variant)}>{header}</strong>
        <Chevron direction={collapsed ? 'down' : 'up'} />
      </div>
      {collapsed ? null : children}
    </>
  );
};

export default CollapsibleComponent;
