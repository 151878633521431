type LocalizedString =
  | string
  | {
      de?: string;
      en?: string;
    };

export interface VenueEventFlat {
  eventSeries?: string;
  gameday?: string;
  id: string;
  masterId?: string;
  maxTicketsPerUser: number;
  personalDataOnTicketIsRequired: boolean;
  personalDataOnTicketShouldBeCollected: boolean;
  personalPhoneNumberOnTicketShouldBeRequired: boolean;
  seasonTicketLegalRecipientShouldBeCollected: boolean;
  preliminaryDateText?: string;
  startDate?: string;
  subtitle?: string;
  teaserImageUrl?: string;
  team1ImageUrl?: string;
  team2ImageUrl?: string;
  title: string;
  venueLayout: string;
  tosText?: string;
  tosRequired?: boolean;
  venuePlanId?: string;
  venuePlanVersionId: string;
  disableVisualSelection?: boolean;
  locale: string;
}

export interface VenueEvent {
  eventSeries?: string;
  gameday?: string;
  id: string;
  masterId?: string;
  maxTicketsPerUser: number;
  personalDataOnTicketIsRequired: boolean;
  personalDataOnTicketShouldBeCollected: boolean;
  personalPhoneNumberOnTicketShouldBeRequired: boolean;
  seasonTicketLegalRecipientShouldBeCollected: boolean;
  preliminaryDateText?: string;
  startDate?: Date|null;
  subtitle?: string;
  teaserImageUrl?: string;
  team1ImageUrl?: string;
  team2ImageUrl?: string;
  tosText: string;
  tosRequired: boolean;
  title: string;
  venueLayout: string;
  venuePlanId?: string;
  venuePlanVersionId: string;
  disableVisualSelection?: boolean;
  locale: string;
}

export const mapVenueEvent = (flatVenueEvent: VenueEventFlat): VenueEvent => {
  return {
    eventSeries: flatVenueEvent.eventSeries,
    gameday: flatVenueEvent.gameday,
    id: flatVenueEvent.id,
    masterId:
      (flatVenueEvent.masterId?.length ?? 0) > 0
        ? flatVenueEvent.masterId
        : undefined,
    maxTicketsPerUser: flatVenueEvent.maxTicketsPerUser,
    personalDataOnTicketIsRequired: flatVenueEvent.personalDataOnTicketIsRequired,
    personalDataOnTicketShouldBeCollected: flatVenueEvent.personalDataOnTicketShouldBeCollected,
    personalPhoneNumberOnTicketShouldBeRequired: flatVenueEvent.personalPhoneNumberOnTicketShouldBeRequired,
    seasonTicketLegalRecipientShouldBeCollected: flatVenueEvent.seasonTicketLegalRecipientShouldBeCollected,
    preliminaryDateText: flatVenueEvent.preliminaryDateText,
    startDate: flatVenueEvent.startDate ? new Date(flatVenueEvent.startDate) : null,
    subtitle:flatVenueEvent.subtitle,
    teaserImageUrl:
      (flatVenueEvent.teaserImageUrl?.length ?? 0) > 0
        ? flatVenueEvent.teaserImageUrl
        : undefined,
    team1ImageUrl:
        (flatVenueEvent.team1ImageUrl?.length ?? 0) > 0
            ? flatVenueEvent.team1ImageUrl
            : undefined,
    team2ImageUrl:
      (flatVenueEvent.team2ImageUrl?.length ?? 0) > 0
        ? flatVenueEvent.team2ImageUrl
        : undefined,
    title: flatVenueEvent.title,
    tosText: flatVenueEvent.tosText ?? '',
    tosRequired: flatVenueEvent?.tosRequired ?? false,
    venueLayout: flatVenueEvent.venueLayout,
    venuePlanId: flatVenueEvent.venuePlanId,
    venuePlanVersionId: flatVenueEvent.venuePlanVersionId,
    disableVisualSelection: flatVenueEvent.disableVisualSelection,
    locale: flatVenueEvent.locale,
  };
};
