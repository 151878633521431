import React from 'react';
import style from './style.module.css';

type StyleVariant = 'main' | 'sub' | undefined;

const selectStyle = (variant: StyleVariant): string => {
  switch (variant) {
    case 'sub':
      return style.SectionSub;
    case 'main':
    default:
      return style.SectionMain;
  }
};

const SectionComponent: React.FC<{
  border?: boolean;
  variant?: StyleVariant;
}> = ({ border, children, variant }) => {
  return (
    <div className={selectStyle(variant)}>
      <div
        className={`${style.SectionContent} ${
          border === true ? style.SectionBorder : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default SectionComponent;
