import { getAuthenticationState, signInSilent } from '../auth';

export function createAuthenticationHeaders() {
  const authenticationState = getAuthenticationState();

  if (authenticationState.type === 'AUTHENTICATED') {
    return { 'Authorization': `Bearer ${authenticationState.accessToken}` };
  }

  return undefined;
}

export class HTTPError extends Error {
  public status: number;

  constructor(status: number, message?: string) {
    super(message);
    this.status = status;
  }
}

export class HTTPKnownError extends HTTPError {
  public errorType: string;

  constructor(status: number, errorType: string) {
    super(status, errorType);
    this.errorType = errorType;
  }
}

export class QueueItRedirectError extends Error {
  public url: string;

  constructor(url: string, errorType: string) {
    super(errorType);
    this.url = url;
  }
}

export class SeasonTicketLegalRecipientIdNotFoundError extends Error {
  public id: string;

  constructor(id: string, errorType: string) {
    super(errorType);
    this.id = id;
  }
}

export class SelectionNotFoundError extends Error {
}

export enum KnowErrors {
  InvalidPlace = 'place.not_valid',
  InvalidPurchasableItem = 'purchasableItem.not_valid',
  InvalidRightsProvider = 'rightsProvider.not_valid',
  SubscriptionIdNotFound = 'subscriptionId.notFound',
  PersonalizationNotAllPlacesProvided = 'selection.personalization.notAllPlacesProvided',
  PersonalizationRequired = 'selection.personalization.required',
  SalesChannelNotFound = 'salesChannel.notFound',
  UserIsNotInTargetGroup = 'user.is.not.in.targetGroup',
  UserHasMaxTickets = 'user.has.max.tickets',
  PlaceIsBocked = 'selection.place.booked',
  FreePlacesAreMissing = 'free.places.are.missing',
  UserDefaultAddressIsNotSet = 'user.default.address.not.set',
}

let isAlreadyFetchingAccessToken = false;

const handleErrorResponse = async (response: Response): Promise<void> => {
  if (response.status >= 400) {
    const responseBody = await response.json();
    if (Object.values(KnowErrors).includes(responseBody?.errorType)) {
      throw new HTTPKnownError(response.status, responseBody?.errorType);
    } else if (responseBody?.errorType === 'queue.redirect') {
      throw new QueueItRedirectError(responseBody?.url, responseBody.errorType);
    } else if (responseBody?.errorType === 'selection.notFound') {
      throw new SelectionNotFoundError(responseBody.errorType);
    }  else if (responseBody?.errorType === 'selection.seasonTicketLegalRecipientId.notFound') {
      throw new SeasonTicketLegalRecipientIdNotFoundError(responseBody.message, responseBody.errorType);
    } else {
      if (responseBody.code === 401 && responseBody.message === 'Invalid JWT Token' ) {
        if (!isAlreadyFetchingAccessToken) {
          await signInSilent().then(() => isAlreadyFetchingAccessToken = true);

          // Reset isAlreadyFetchingAccessToken after 1m
          setTimeout(() => {
            isAlreadyFetchingAccessToken = false;
          },  60000);
        }
      }

      throw new HTTPError(response.status, 'Invalid API request');
    }
  }
};

export const apiGETRequest = async (
  url: string,
  excludeAuthentication?: boolean,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const headers = excludeAuthentication ? undefined : createAuthenticationHeaders();

  const response = await fetch(url, {
    headers: headers,
  });
  await handleErrorResponse(response);
  return response.json();
};

export const apiPOSTRequest = async (
  url: string,
  body: any,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      ...createAuthenticationHeaders(),
    },
    method: 'POST',
  });
  await handleErrorResponse(response);
  return response.json();
};

export const apiDELETERequest = async (
  url: string,
  body?: any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {

  const init = {
    method: 'DELETE'
  } as RequestInit;

  if(typeof body !== 'undefined') {
    init.body = JSON.stringify(body);
    init.headers = {
      'Content-Type': 'application/json',
      ...createAuthenticationHeaders(),
    };
  } else {
    init.headers = createAuthenticationHeaders();
  }

  const response = await fetch(url, init);
  await handleErrorResponse(response);
  return response.json();
};

export const generateURL = (
  template: string,
  args: {
    params?: { [key: string]: string };
    query?: { [key: string]: string };
    withoutBaseUrl?: boolean;
  },
): string => {
  let url = args.withoutBaseUrl ? '' : process.env.REACT_APP_API_BASE_URL ?? '';

  const params = new Map(Object.entries(args.params ?? {}));
  const templateComponents = template.split(/{{([^}]+)}}/);
  for (let index = 0; index < templateComponents.length; index++) {
    const component = templateComponents[index];
    if (index % 2 === 0) {
      url += component;
    } else {
      const param = params.get(component);
      if (param) {
        url += param;
      }
      // else {
      //   throw Error(
      //     `Param "${component}" not found. URL cannot be created. Template: "${template}"`,
      //   );
      // }
    }
  }

  const queryEntities = Object.entries(args.query ?? {});
  if (queryEntities.length > 0) {
    const queryComponents = [];
    for (const [key, value] of queryEntities) {
      if (value) {
        queryComponents.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        );
      }
      // else {
      //   throw Error(
      //     `Query "${key}" not found. URL cannot be created. Template: "${template}"`,
      //   );
      // }
    }
    url += '?' + queryComponents.join('&');
  }

  return url;
};
