import React from 'react';
import Section from '../Section';
import { resizeImage } from '../../util/resizeImage';
import style from './style.module.css';
import { useLocale } from '../../state/Localization';
import { useVenueEvent } from '../../state/VenueEvent';

const GameSummaryComponent: React.FC = () => {
  const { venueEvent } = useVenueEvent();
  const { language } = useLocale();

  if (!venueEvent) {
    return null;
  }

  return (
    <Section border={true}>
      <div className={style.Container}>
        <div className={style.ColumnAside}>
          {venueEvent.teaserImageUrl ? (
            <img
              alt=""
              className={style.TeamImage}
              src={resizeImage(venueEvent.teaserImageUrl, 300, 300)}
            />
          ) : null}
        </div>
        <div>
          <div className={style.ColumnMainTop}>
            <strong>{venueEvent.title}</strong>
            <span>{venueEvent.subtitle}</span>
          </div>
          <div className={style.ColumnMainBottom}>
            <strong>{venueEvent.eventSeries}</strong>
            <span>{venueEvent.gameday}</span>
            {venueEvent.startDate && <span>
              {venueEvent.startDate.toLocaleString(language, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}{' '}
              /{' '}
              {venueEvent.startDate.toLocaleString(language, {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>}
            <span>{venueEvent.preliminaryDateText}</span>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default GameSummaryComponent;
