import { Action, ActionType, SalesChannelEventsState } from './types';

const initialState: SalesChannelEventsState = {
  events: [],
  fetching: false,
};

export const reducer = (
  state = initialState,
  action: Action,
): SalesChannelEventsState => {
  switch (action.type) {
    case ActionType.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        fetching: false,
      };
    }
    case ActionType.FETCH: {
      return {
        ...state,
        fetching: true,
      };
    }
    case ActionType.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        events: response.events,
        error: undefined,
        fetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
