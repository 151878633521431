import React, {useEffect, useState} from 'react';
import {getState as getTicketSelectionState, useTicketSelection} from '../../state/TicketSelection';
import Section from '../Section';
import style from './style.module.css';
import { useLocale } from '../../state/Localization';
import {setOrganizationId, useSession} from '../../state/Session';
import Modal from 'react-modal';
import Button from '../Button';
import Select from '../Select';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFetch} from '../../state/Fetch';
import queryString from "query-string";


const OrganizationModal: React.FC = () => {
    const location = useLocation();
    const {organizationId, salesChannel, user} = useSession();
    const {strings} = useLocale();
    const ticketSelectionState = useTicketSelection();
    const dispatch = useDispatch();
    const history = useHistory();
    const { fetchComponent, fetchIndicator } = useFetch();

    const availableOrganizations = ticketSelectionState?.availableOrganizations;
    const selectedOrganizationIdFromPlaceSelection = ticketSelectionState?.selectedOrganizationId;

    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(organizationId);

    const userName = `${user?.firstName || ''} ${user?.lastName || ''}`;

    const searchParams = queryString.parse(location.search);

    // set selected organization id when organizationId changed
    useEffect(() => {
        setSelectedOrganizationId(selectedOrganizationIdFromPlaceSelection);
    }, [selectedOrganizationIdFromPlaceSelection]);

    const getSelectedOrganizationName = (organizationId: string | undefined): string => {
        let name = '';
        if (organizationId) {
            const organization = availableOrganizations?.find((org) => org.id === organizationId);
            if (organization) {
                name = organization.name;
            }
        }

        return name;
    };

    const greetingString = () => {
        const name = selectedOrganizationIdFromPlaceSelection
            ? getSelectedOrganizationName(selectedOrganizationIdFromPlaceSelection)
            : userName;

        return <>{strings.Organization_Greatings}&nbsp;<strong>{name}</strong></>;
    };

    const onCancel = (): void => {
        setIsShowModal(false);
    };

    const onConfirm = (): void => {
        // set organization id in state
        dispatch(setOrganizationId(selectedOrganizationId ?? ''));

        // set/remove organizationId in query param
        const params = new URLSearchParams();
        if (selectedOrganizationId) {
            params.append('organizationId', selectedOrganizationId);
        } else {
            params.delete('organizationId');
        }
        history.push({search: params.toString()});

        // update placeSelection state
        dispatch(getTicketSelectionState(fetchComponent));

        // show toast message
        const name = getSelectedOrganizationName(selectedOrganizationId);

        const content = <>
            <p><strong>{strings.Organization_Toast_Title}</strong></p>

            <p>{strings.Organization_Toast_YouSelected} <strong>
                {(selectedOrganizationId) ? name : userName}
            </strong></p>
        </>;

        toast(content, {
            closeButton: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            progressStyle: { background:  '#009e0f' },
        });

        setIsShowModal(false);
    };

    const onOrganizationChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setSelectedOrganizationId(event.target.value);
    };

    if (
        !availableOrganizations?.length
        || (!salesChannel || !['purchaseOptions', 'web'].includes(salesChannel))
        || searchParams.SubjectId
        || searchParams.access_token
    ) {
        return (<></>);
    }

    return (
        <>
            <Section>
                <div className={style.Greeting}>
                    <p>
                        {greetingString()}
                        &nbsp;
                        <Button onClick={() => setIsShowModal(true)}>
                            {strings.Organization_ChangeButton}
                        </Button>
                    </p>
                </div>
            </Section>
            <Modal
                isOpen={isShowModal}
                onRequestClose={onCancel}
                className={style.Modal}
                overlayClassName={style.Overlay}
            >
                <button onClick={onCancel} className={style.CloseButton}>X</button>
                <div className={style.ModalInner}>
                    <p>{strings.Organization_Modal_Label}</p>

                    <Select
                        className={style.Select}
                        onChange={onOrganizationChange}
                        value={selectedOrganizationId ?? ''}
                        testid="selectOrganization"
                    >
                        <option value="">{userName}</option>
                        {availableOrganizations
                            .map((organization, key) => (
                                <option key={key} value={organization.id}>
                                    {organization.name}
                                </option>
                            ))}
                    </Select>
                    &nbsp;
                    <button onClick={onConfirm} className={style.SubmitButton} disabled={fetchIndicator.fetching}>
                        {strings.Organization_Modal_Apply}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default OrganizationModal;
