export enum ActionType {
  SET_RESPONSE_REVOKE_TICKET = 'USER::SET_RESPONSE_REVOKE_TICKET',
}

export interface RevokeTicketState {
  isRevokeTicketSent: boolean;
  responseRevokeStatus: number;
}

export interface SetResponseRevokeTicket {
  type: typeof ActionType.SET_RESPONSE_REVOKE_TICKET;
  payload: {
    isRevokeTicketSent: boolean;
    responseRevokeStatus: number;
  };
}

export type Action =
  | SetResponseRevokeTicket;
