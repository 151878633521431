import {
  ActionType,
  SetArePlacesSubmitted, SetArePurchaseOptionsSubmitted, SetIsPurchaseOptions,
  SetJWTAction,
  SetPurchaseForTicketHolderIdAction,
  SetQueueittokenAction,
  SetSalesChannelAction,
  SetSalesRuleIdAction,
  SetSubscriptionIdAction,
  SetActionCodeAction, SetPlaceSelectionId, SetOrganizationId, SetRedirectUrl
} from './index';
import { SyncThunkAction } from '../thunkAction';


export const setJWT = (jwt: string): SetJWTAction => ({
  payload: { jwt },
  type: ActionType.SET_JWT,
});

export const setSalesChannel = (
  salesChannel: string,
): SetSalesChannelAction => ({
  payload: { salesChannel },
  type: ActionType.SET_SALES_CHANNEL,
});

export const setSalesRuleId = (
  salesRuleId: string,
): SetSalesRuleIdAction => ({
  payload: { salesRuleId },
  type: ActionType.SET_SALES_RULE_ID,
});

export const setPurchaseForTicketHolderId = (
  purchaseForTicketHolderId: string,
): SetPurchaseForTicketHolderIdAction => ({
  payload: { purchaseForTicketHolderId },
  type: ActionType.SET_PURCHASE_FOR_TICKET_HOLDER_ID,
});

export const setActionCode = (
  actionCode: string,
): SetActionCodeAction => ({
  payload: { actionCode },
  type: ActionType.SET_ACTION_CODE,
});

export const setSubscriptionId = (
  subscriptionId: string,
): SetSubscriptionIdAction => ({
  payload: { subscriptionId },
  type: ActionType.SET_SUBSCRIPTION_ID,
});

export const setQueueittoken = (
  queueittoken: string,
): SetQueueittokenAction => ({
  payload: { queueittoken },
  type: ActionType.SET_QUEUEITOKEN,
});

export const setRightsProvider = (
  rightsProviderId?: string,
): SyncThunkAction => (dispatch, getState): void => {
  const selectedRightsProvider = getState().ticketSelection?.rightsProviders.find(
    (rightsProvider) => rightsProvider.id === rightsProviderId,
  );
  dispatch({
    payload: { selectedRightsProvider },
    type: ActionType.SET_SELECTED_RIGHTS_PROVIDER,
  });
};

export const setIsPurchaseOptions = (
  isPurchaseOptions: boolean,
): SetIsPurchaseOptions => ({
  payload: { isPurchaseOptions },
  type: ActionType.SET_IS_PURCHASE_OPTIONS,
});

export const setArePlacesSubmitted = (
  arePlacesSubmitted: boolean,
): SetArePlacesSubmitted => ({
  payload: { arePlacesSubmitted },
  type: ActionType.SET_ARE_PLACES_SUBMITTED,
});

export const setArePurchaseOptionsSubmitted = (
  arePurchaseOptionsSubmitted: boolean,
): SetArePurchaseOptionsSubmitted => ({
  payload: { arePurchaseOptionsSubmitted },
  type: ActionType.SET_ARE_PURCHASE_OPTIONS_SUBMITTED,
});

export const setPlaceSelectionId = (
    placeSelectionId?: string,
): SetPlaceSelectionId => ({
  payload: { placeSelectionId },
  type: ActionType.SET_PLACE_SELECTION_ID,
});

export const setOrganizationId = (
    organizationId: string,
): SetOrganizationId => ({
  payload: { organizationId },
  type: ActionType.SET_ORGANIZATION_ID,
});

export const setRedirectUrl = (
    redirectUrl?: string | undefined,
): SetRedirectUrl => ({
  payload: { redirectUrl },
  type: ActionType.SET_REDIRECT_URL,
});
