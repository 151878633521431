import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { API_ENDPOINTS } from '../../config.json';
import { ActionType } from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';

// get blocks by venuePlanId
export const getBlocks = (
  venueEventId: string,
): AsyncReduxThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(API_ENDPOINTS.GET_BLOCKS, {
      params: { venueEventId },
    });

    const blocks = await apiGETRequest(requestURL);

    dispatch({ payload: { blocks }, type: ActionType.SUCCESS });
  } catch (error) {
    dispatch({ payload: { error }, type: ActionType.ERROR });
    handleError(dispatch, error);
  }
};

// get blocks by blockGroupId
export const getBlocksByBlockGroup = (
  blockGroupId: string,
): AsyncReduxThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(API_ENDPOINTS.GET_BLOCKS_BY_BLOCKGROUP, {
      params: {blockGroupId},
    });

    const blocks = await apiGETRequest(requestURL);

    dispatch({ payload: { blocks }, type: ActionType.SUCCESS });
  } catch (error) {
    dispatch({ payload: { error }, type: ActionType.ERROR });
    handleError(dispatch, error);
  }
};
