export enum ActionType {
  ERROR = 'SALES_CHANNEL_EVENTS::ERROR',
  FETCH = 'SALES_CHANNEL_EVENTS::FETCH',
  SUCCESS = 'SALES_CHANNEL_EVENTS::SUCCESS',
}

export interface VenueEvent {
  id: string,
  title: string,
  type?: string,
  serialTitle: string,
  gameday: string,
  startDateTime: string,
  isDatePreliminary: boolean,
  preliminaryDateText?: string,
  salesInformation?: string,
  venueTitle: string,
  salesLink: string,
  tags: string[]
}

// Response
export interface FetchResponse {
  events: VenueEvent[]
}

// Response state
export interface SalesChannelEventsState extends FetchResponse {
  error?: Error;
  fetching: boolean;
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.SUCCESS;
}

export type Action =
  | ErrorAction
  | FetchAction
  | SuccessAction;