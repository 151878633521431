import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { API_ENDPOINTS } from '../../config.json';
import { FetchComponent, createFetchActions } from '../Fetch';
import { ActionType } from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { ensureBasicState } from '../TicketSelection/utils/ensureBasicState';
import { handleError } from '../../util/handleError';

export const getVenuePlan = (
  venueEventId: string,
  blockGroupId: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    dispatch({ type: ActionType.FETCH });

    const appState = getState();
    const { session } = appState;
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      userId,
      actionCode,
      organizationId
    } = ensureBasicState(appState);
    const rightsProviderId = session.selectedRightsProvider?.id ?? userId;

    const requestURL = generateURL(API_ENDPOINTS.GET_AVAILABLE_SEATS, {
      params: { venueEventId },
      query: {
        blockGroupId,
        purchaseForTicketHolderId,
        subscriptionId,
        rightsProviderId,
        salesChannel,
        salesRuleId,
        actionCode,
        organizationId
      },
    });

    const venuePlan = await apiGETRequest(requestURL);
    dispatch({ payload: { venuePlan }, type: ActionType.SUCCESS });
  } catch (error) {
    dispatch({ payload: { error }, type: ActionType.ERROR });
    handleError(dispatch, error);
  } finally {
    dispatch(fetchStop);
  }
};
