import React, { useCallback } from 'react';
import { setRightsProvider } from '../../state/Session';
import style from './style.module.css';
import { useDispatch } from 'react-redux';

const RightsProviderSelectItemComponen: React.FC<{
  rightsProviderId: string | undefined;
  rightsProviderName: string;
  rightsProviderTickets: number;
  rightsProviderTicketsMax?: number;
  isSelected?: boolean;
}> = ({
  rightsProviderId,
  rightsProviderName,
  rightsProviderTickets,
  rightsProviderTicketsMax,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    () => dispatch(setRightsProvider(rightsProviderId)),
    [dispatch, rightsProviderId],
  );
  return (
    <li
      className={
        isSelected
          ? `${style.RightsProvider} ${style.RightsProviderSelected}`
          : style.RightsProvider
      }
      onClick={onClick}
    >
      {rightsProviderName} ({rightsProviderTickets}
      {rightsProviderTicketsMax && rightsProviderTicketsMax >= 0
        ? ` / ${rightsProviderTicketsMax}`
        : null}
      )
    </li>
  );
};

export default RightsProviderSelectItemComponen;
