export enum ActionType {
  ERROR = 'VENUE_PLAN::ERROR',
  FETCH = 'VENUE_PLAN::FETCH',
  SUCCESS = 'VENUE_PLAN::SUCCESS',
}

// single seat
export interface Seat {
  id: string;
  blockId: string;
  blockLabel: string;
  pricingCategory: {
    id: string;
    name: string;
  };
  seatId: string;
  seatLabel: string;
  rowLabel: string;
  color: string;
}

// single seat
export interface StandingBlock {
  id: string;
  blockLabel: string;
  color: string;
  availableCapacity: number | null;
}

// single seat
export interface VenuePlan {
  seats?: Seat[];
  standingBlocks?: StandingBlock[];
}

// places state
export interface VenuePlanState {
  error?: Error;
  fetching: boolean;
  venuePlan?: VenuePlan;
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { venuePlan: VenuePlan };
  type: typeof ActionType.SUCCESS;
}

export type Action = ErrorAction | FetchAction | SuccessAction;
