import { ActionType, FetchResponse } from './index';
import { API_ENDPOINTS } from '../../config.json';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import {apiGETRequest, generateURL} from '../../util/apiRequest';
import { handleError } from '../../util/handleError';
import queryString from 'query-string';

export const updateEvents = (
  salesChannelKey: string,
  sortExpr: string,
  searchExpr: string,
): AsyncReduxThunkAction => async (dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    let requestURL = generateURL(API_ENDPOINTS.GET_SALES_CHANNEL_EVENTS, {
      params: {salesChannelKey},
    });

    const queryObj = {
      'sort': sortExpr,
      'search': searchExpr,
    };
    const query = queryString.stringify(queryObj);
    requestURL += `?${query}`;

    const response = await apiGETRequest(requestURL) as FetchResponse;
    dispatch({ payload: { response }, type: ActionType.SUCCESS });
  } catch (error) {
    dispatch({ payload: { error }, type: ActionType.ERROR });
    handleError(dispatch, error);
  }
};
