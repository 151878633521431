import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import style from './style.module.css';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocale } from '../../state/Localization';
import { Mode } from '../../state/PlaceReseating/types';
import { addOtherSubscriberPlaces } from '../../state/PlaceReseating/actions';
import { VenueSelectedPlace } from '../../components/VenuePlan/data';
import { Place } from '../../state/PlaceReseating/types';

type TicketReseatingRecipientSelectionProps = {
  places: Place[];
  venuePlaces: VenueSelectedPlace[];
  blockName: string;
  showRecipientSelectionModal: boolean;
  setShowRecipientSelectionModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const TicketReseatingRecipientSelectionComponent: React.FC<TicketReseatingRecipientSelectionProps> = (props) => {
  const { strings } = useLocale();
  const dispatch = useDispatch();

  const [places, setPlaces] = useState<Place[]>([]);

  useEffect(() => {
    setPlaces(props.places);
  }, [props.places]);

  const onCheckboxClicked = (placeId: string): void => {
    setPlaces(
      places.map((place) => {
        if (place.id === placeId) {
          place.mode = place.mode === 'selected'
            ? Mode.View
            : Mode.Selected;
        }
        return place;
      })
    );
  };

  const onCancel = (): void => {
    props.setShowRecipientSelectionModal(false);
  };

  const onConfirm = (): void => {
    const selectedPlaces = places.filter((place) => place.mode === Mode.Selected)
      .map((place) => ({...place, mode: Mode.View}));
    const selectedPlaceIds = selectedPlaces.map((place) => place.id);
    const selectedVenuePlaces = props.venuePlaces.filter(
      (venuePlace) => selectedPlaceIds.includes(venuePlace.place.id)
    );

    dispatch(addOtherSubscriberPlaces(
      selectedPlaces, selectedVenuePlaces
    ));

    props.setShowRecipientSelectionModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={!!props.showRecipientSelectionModal}
        onRequestClose={onCancel}
        className={style.Modal}
        overlayClassName={style.Overlay}
      >

        <button onClick={onCancel} className={style.CloseButton}>{strings.StandingplaceModal_Cancel}</button>
        <div className={style.ModalInner}>
          <h2 className={style.Title}>{strings.Shared_Block} {props.blockName}</h2>

          <div className={style.TicketReseatingBlock}>
            {places.map((place, key) => (
              <div key={key} className={`${style.TicketReseating}
                ${place.mode === 'selected' ? style.TicketReseatingSelected : ''}`}
              >
                <div className={style.TicketReseatingCheckBox}>
                  <div className={style.TicketReseatingCheckBoxIcon}
                    onClick={(): void => { onCheckboxClicked(place.id); }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </div>
                <div className={style.TicketReseatingContent}>
                  <strong>{place.seasonTicketLegalRecipient}</strong>
                </div>
              </div>
            ))}
            <button onClick={onConfirm} className={style.SubmitButton}>
              {strings.StandingplaceModal_NewPlaceForSubmit}
            </button>
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default TicketReseatingRecipientSelectionComponent;