import {faSort, faSortDown, faSortUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../state/Fetch';
import {useLocale} from '../../state/Localization';
import {deletePlaces, Place, useTicketSelection} from '../../state/TicketSelection';
import {submitPurchaseOptions} from '../../state/TicketSelection/actions/submitPurchaseOptions';
import {useVenueEvent} from '../../state/VenueEvent';
import Button from '../Button';
import PurchaseOptionsListItemComponent from './purchaseOptionsListItem';
import style from './style.module.css';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

export interface CheckedPlace {
  placeId: string;
}

export type ChangeInputCallback = (arg1: React.ChangeEvent<HTMLInputElement>) => void;

const PurchaseOptionsListComponent: React.FC = () => {
  const useInput = (): [string, any] => {
    const [value, setValue] = useState('');
    const input = <input value={value} onChange={(e) => setValue(e.target.value)} type="text"/>;
    return [value, input];
  };

  const dispatch = useDispatch();
  const {fetchComponent} = useFetch();
  const submitFetch = useFetch();
  const ticketSelection = useTicketSelection();
  const {strings, language} = useLocale();
  const totalPlacesInSelection = ticketSelection?.places.length;

  const venueEvent = useVenueEvent();

  const [tosChecked, setTosChecked] = useState(false);
  const [hasPurchaseOptions, setPurchaseOptions] = useState(false);
  const [hasPurchaseObligations, setPurchaseObligations] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [placeToDelete, setPlaceToDelete] = useState<Place | null>(null);
  const [checkedPlaces, setCheckedPlaces] = useState<CheckedPlace[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [sortingOrder, setSortingOrder] = useState('');

  const [blockFilter, blockFilterInput] = useInput();
  const [rowFilter, rowFilterInput] = useInput();
  const [seatFilter, seatFilterInput] = useInput();
  const [placeFilter, placeFilterInput] = useInput();
  const [personalizationFullNameFilter, personalizationFullNameFilterInput] = useInput();
  const [priceFilter, priceFilterInput] = useInput();
  const [expiresAtFilter, expiresAtFilterInput] = useInput();

  const isSeasonTicketEvent = !!venueEvent.venueEvent?.masterId;

  const placeGetLabel = (place: Place | null, type: 'block' | 'row' | 'seat'): string => {
    if (!place) {
      return '';
    }

    let result = strings.StandingPlace;

    if (type === 'block') {
      result = place.blockLabel;
    }

    if (place.blockType === 'seating') {
      if (type == 'row') {
        result = place.rowLabel;
      } else if (type == 'seat') {
        result = place.seatLabel;
      }
    }

    return result;
  };

  const placeGetFullLabel = (place: Place | null): string => {
    if (!place) {
      return '';
    }

    return `${place.blockLabel} ${placeGetLabel(place, 'row')} ${placeGetLabel(place, 'seat')}`;
  };

  const selectionPlaces = (ticketSelection?.places || []).filter((p) => {

    if (blockFilter && !p.blockLabel.toLowerCase().includes(blockFilter.toLowerCase())) {
      return false;
    }

    if (rowFilter && !placeGetLabel(p, 'row').toLowerCase().includes(rowFilter.toLowerCase())) {
      return false;
    }

    if (seatFilter && !placeGetLabel(p, 'seat').toLowerCase().includes(seatFilter.toLowerCase())) {
      return false;
    }

    if (placeFilter && !placeGetFullLabel(p).toLowerCase().includes(placeFilter.toLowerCase())) {
      return false;
    }

    if (personalizationFullNameFilter
      && !p.personalizationFullName?.toLowerCase().includes(personalizationFullNameFilter.toLowerCase())) {
      return false;
    }

    const purchasableItemName = `${p.selectedPurchasableItem?.pricingClass.publicName} ${(
      (p.selectedPurchasableItem?.grossAmount ?? 0) / 100
    ).toLocaleString(language, {
      currency: 'EUR',
      style: 'currency',
    })}`;

    if (priceFilter && !purchasableItemName.toLowerCase().includes(priceFilter.toLowerCase())) {
      return false;
    }

    const expiresString = `${p.expiresAt.toLocaleString(language, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })}, ${p.expiresAt.toLocaleString(language, {
      hour: '2-digit',
      minute: '2-digit',
    })} Uhr`;

    if (expiresAtFilter && !expiresString.toLowerCase().includes(expiresAtFilter.toLowerCase())) {
      return false;
    }

    return true;
  }).sort((p1, p2) => {
    let val1, val2;

    if (sortBy === 'block') {
      val1 = p1.blockLabel;
      val2 = p2.blockLabel;
    } else if (sortBy === 'row') {
      val1 = placeGetLabel(p1, 'row');
      val2 = placeGetLabel(p2, 'row');
    } else if (sortBy === 'seat') {
      val1 = placeGetLabel(p1, 'seat');
      val2 = placeGetLabel(p2, 'seat');
    } else if (sortBy === 'place') {
      val1 = placeGetFullLabel(p1);
      val2 = placeGetFullLabel(p2);
    } else if (sortBy === 'personalizationFullName') {
      val1 = p1.personalizationFullName ?? '';
      val2 = p2.personalizationFullName ?? '';
    } else if (sortBy === 'price') {
      val1 = p1.selectedPurchasableItem?.grossAmount ?? 0;
      val2 = p2.selectedPurchasableItem?.grossAmount ?? 0;
    } else if (sortBy === 'expiresAt') {
      val1 = p1.expiresAt;
      val2 = p2.expiresAt;
    } else {
      return 0;
    }

    if (val1 > val2) {
      return sortingOrder === 'desc' ? -1 : 1;
    }

    if (val1 < val2) {
      return sortingOrder === 'desc' ? 1 : -1;
    }

    return 0;
  });

  useEffect(() => {
    setPurchaseObligations(!!selectionPlaces.find((item) => {
      return item.isPurchaseObligations;
    }));
    setPurchaseOptions(!!selectionPlaces.find((item) => {
      return !item.isPurchaseObligations;
    }));
  }, [selectionPlaces]);

  const getExpiresColumnName = useCallback(() => {
    if (hasPurchaseOptions && !hasPurchaseObligations) {
      return strings.PurchaseOptions_expiresPurchaseOptions;
    } else if (!hasPurchaseOptions && hasPurchaseObligations) {
      return strings.PurchaseOptions_expiresPurchaseObligations;
    } else {
      return strings.PurchaseOptions_expiresPurchaseOptionsAndObligations;
    }
  }, [hasPurchaseOptions, hasPurchaseObligations]);

  const checkAllPlaces = useCallback<ChangeInputCallback>(
    (eventArgs) => {
      if (eventArgs.currentTarget.checked) {
        setCheckedPlaces(selectionPlaces.map((el) => {
          return {placeId: el.id};
        }));
      } else {
        setCheckedPlaces([]);
      }
    },
    [selectionPlaces],
  );

  const changeTos = useCallback<ChangeInputCallback>(
    (eventArgs) => setTosChecked(eventArgs.currentTarget.checked),
    [setTosChecked],
  );

  const onSubmitClicked = useCallback(() => {
    dispatch(submitPurchaseOptions(checkedPlaces, submitFetch.fetchComponent));
  }, [dispatch, checkedPlaces]);

  const tosRequiredAndNotChecked = (venueEvent.venueEvent?.tosRequired && !tosChecked);
  const submitButtonDisabled = tosRequiredAndNotChecked || checkedPlaces.length === 0;

  const checkPlace = useCallback<ChangeInputCallback>(
    (eventArgs) => {
      let newCheckedPlaces = checkedPlaces.filter((el) => {
        return el.placeId !== eventArgs.currentTarget.value;
      });

      if (eventArgs.currentTarget.checked) {
        newCheckedPlaces = [...newCheckedPlaces, {placeId: eventArgs.currentTarget.value}];
      }

      setCheckedPlaces(newCheckedPlaces);
    },
    [checkedPlaces],
  );

  const getItemById = (id: string): Place | undefined => {
    return selectionPlaces.find((item) => {
      return item.id === id;
    });
  };

  const onDeletePlaceClicked = useCallback(() => {
    if (placeToDelete?.id) {
      dispatch(deletePlaces([placeToDelete.id], fetchComponent));
      setCheckedPlaces(checkedPlaces.filter((el) => {
        return el.placeId !== placeToDelete.id;
      }));
      setIsShowDeleteModal(false);
      setPlaceToDelete(null);
    }
  }, [dispatch, placeToDelete?.id, fetchComponent]);

  // show delete modal window
  const deleteItemHandle = (id: string): void => {
    const itemToDelete = getItemById(id);
    if (itemToDelete) {
      setPlaceToDelete(itemToDelete);
      setIsShowDeleteModal(true);
    }
  };

  function closeModal(): void {
    setIsShowDeleteModal(false);
    setPlaceToDelete(null);
  }

  const sortingClicked = useCallback(
    (name: string) => {
      if (sortBy !== name) {
        setSortBy(name);
        setSortingOrder('asc');
      } else if (sortingOrder === 'asc') {
        setSortingOrder('desc');
      } else if (sortingOrder === 'desc') {
        setSortBy('');
        setSortingOrder('');
      }
    },
    [setSortBy, setSortingOrder, sortBy, sortingOrder],
  );

  const getSortingIcon = (name: string): JSX.Element => {
    const icon = sortBy != name ? faSort : (sortingOrder === 'asc' ? faSortDown : faSortUp);
    return <FontAwesomeIcon icon={icon}/>;
  };

  if (submitFetch.fetchIndicator.fetching && submitFetch.fetchIndicator.hasFocus) {
    return (
      <div className={style.Container}>
        <div className={style.LoaderContainer}>
          <div className={style.Loader}></div>
        </div>
      </div>
    );
  }

  if (!totalPlacesInSelection || totalPlacesInSelection === 0) {
    return <></>;
  }

  const tosBlock = (): JSX.Element => {
    if (venueEvent.venueEvent?.tosText) {
      return <div>
        {venueEvent.venueEvent?.tosRequired &&
            <input type="checkbox" className={style.Checkbox} id="tos" onChange={changeTos} checked={tosChecked}/>}
        <label htmlFor="tos" className={style.LabelText}>
            <span>
              <span dangerouslySetInnerHTML={{
                __html: venueEvent.venueEvent.tosText
              }}/>
            </span>
        </label>
      </div>;
    }

    return <></>;
  };

  return (
    <div className={style.Container}>
      {totalPlacesInSelection ? (
        <div className={style.PurchaseList}>
          <table>
            <thead>
            <tr>
              <th><input type="checkbox" onChange={checkAllPlaces}/></th>
              <th onClick={() => sortingClicked('block') }className={style.ShowWeb}>{strings.Shared_Block} {getSortingIcon('block')}</th>
              <th onClick={() => sortingClicked('row')} className={style.ShowWeb}>{strings.Shared_Row} {getSortingIcon('row')}</th>
              <th onClick={() => sortingClicked('seat')} className={style.ShowWeb}>{strings.Shared_Seat} {getSortingIcon('seat')}</th>
              <th onClick={() => sortingClicked('place')} className={style.ShowMobile}>
                Platz {getSortingIcon('place')}
              </th>
              <th onClick={() => sortingClicked('personalizationFullName')}>
                Inhaber {getSortingIcon('personalizationFullName')}
              </th>
              <th onClick={() => sortingClicked('price')}>Preisklasse {getSortingIcon('price')}</th>
              <th className={style.DateRow} onClick={() => sortingClicked('expiresAt')}>
                {getExpiresColumnName()} {getSortingIcon('expiresAt')}
              </th>
              <th className={style.ActionRow}>Aktion</th>
            </tr>
            </thead>
            <tbody>
            <tr className={style.FiltersRow}>
              <td></td>
              <td className={style.ShowWeb}>{blockFilterInput}</td>
              <td className={style.ShowWeb}>{rowFilterInput}</td>
              <td className={style.ShowWeb}>{seatFilterInput}</td>
              <td className={style.ShowMobile}>{placeFilterInput}</td>
              <td>{personalizationFullNameFilterInput}</td>
              <td>{priceFilterInput}</td>
              <td>{expiresAtFilterInput}</td>
              <td></td>
            </tr>
            {selectionPlaces.map((place, key) => (
              <PurchaseOptionsListItemComponent
                place={place}
                key={key}
                deleteItemHandle={deleteItemHandle}
                placeGetLabel={placeGetLabel}
                checkedPlaces={checkedPlaces}
                checkPlace={checkPlace}
              />
            ))}
            </tbody>
          </table>
          <div className={style.NumbersBlock}>
            <div className={style.NumbersBlockSelected}>{strings.PurchaseOptions_checked} {checkedPlaces.length}</div>
            <div className={style.NumbersBlockTotal}>{strings.PurchaseOptions_total} {totalPlacesInSelection}</div>
          </div>

          <div className={style.ButtonSubmit}>
            {tosBlock()}

            <Button
              variant="secondary"
              onClick={onSubmitClicked}
              disabled={submitButtonDisabled}
            >
               {isSeasonTicketEvent
                   ? strings.PurchaseOption_ConfirmSeasonTicket
                   : strings.PurchaseOption_Confirm
               }
            </Button>
          </div>

          <Modal
            isOpen={isShowDeleteModal}
            onRequestClose={closeModal}
            className={style.Modal}
            overlayClassName={style.Overlay}
          >
            <h2 className={style.Title}>{strings.PurchaseOptions_deleteModalText}</h2>
            <h4>
              {strings.Shared_Block} - {placeGetLabel(placeToDelete, 'block')}&nbsp;;&nbsp;
              {strings.Shared_Row} - {placeGetLabel(placeToDelete, 'row')}&nbsp;;&nbsp;
              {strings.Shared_Seat} - {placeGetLabel(placeToDelete, 'seat')}
            </h4>

            <div className={style.ModalButtons}>
              <button
                className={style.ConfirmDeleteButton}
                onClick={onDeletePlaceClicked}
                autoFocus={true}
              >
                {strings.Shared_Yes}
              </button>
              <button onClick={closeModal} className={style.CancelDeleteButton}>
                {strings.Shared_No}
              </button>
            </div>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default PurchaseOptionsListComponent;
