import {FetchComponent, createFetchActions} from '../../state/Fetch';
import {createAuthenticationHeaders, generateURL} from '../../util/apiRequest';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { API_ENDPOINTS } from '../../config.json';
import {setResponseRevokeTicket} from '../../state/RevokeTicket';

export const sendRevokeTicket = (
  ticketId: string,
  salesChannel: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const requestURL = generateURL(API_ENDPOINTS.POST_REVOKE_TICKET_ON_MARKETPLACE, {
      params: {ticketID: ticketId},
      query: { salesChannel },
    });
    const stateResponse = (await fetch(requestURL, {
      headers: {
        'Content-Type': 'application/json',
        ...createAuthenticationHeaders(),
      },
      method: 'POST',
    }));
    dispatch(setResponseRevokeTicket(true, stateResponse.status));
  } catch (error) {
    dispatch(setResponseRevokeTicket(true, error.status));
  } finally {
    dispatch(fetchStop);
  }
};
