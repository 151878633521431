import {API_ENDPOINTS} from '../../config.json';
import {apiGETRequest, generateURL} from '../../util/apiRequest';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import {handleError} from '../../util/handleError';
import {FetchComponent, createFetchActions} from '../Fetch';
import {ensureBasicState} from '../TicketSelection/utils/ensureBasicState';
import {Action, PurchasableItems, ActionType as PurchasableItemsActionType} from './types';
import {mapPurchasableItemsResponse} from './types/mapPurchasableItemsResponse';
import {PurchasableItemsResponse} from './types/PurchasableItemsResponse';

export const clearPurchasableItems = (): Action => {
  const state = {
    placeId: '',
    pricingCategories: [],
    pricingClasses: [],
    purchasableItems: []
  } as PurchasableItems;
  return { payload: { state }, type: PurchasableItemsActionType.SET_PURCHASABLE_ITEMS };
};

export const loadPurchasableItemsForPlace = (
  placeId: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  dispatch(clearPurchasableItems());
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      venueEventId,
      organizationId
    } = ensureBasicState(
      appState,
    );
    const requestURL = generateURL(API_ENDPOINTS.GET_PURCHASABLE_ITEMS, {
      params: { placeId, venueEventId },
      query: { purchaseForTicketHolderId, salesChannel, salesRuleId, subscriptionId, organizationId },
    });

    const stateResponse = await apiGETRequest(requestURL) as PurchasableItemsResponse;
    const state = mapPurchasableItemsResponse(stateResponse, placeId);
    dispatch({ payload: { state }, type: PurchasableItemsActionType.SET_PURCHASABLE_ITEMS });
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch(fetchStop);
  }
};

