export const COLOR_SEAT_DEFAULT = '#000000';

export const TINT_BLOCK_LABEL = 0x606060;
export const TINT_BLOCK_OUTLINE = 0xDDDDDD;
export const TINT_BLOCK_RESEATING_LABEL = 0x009E0F;

export const TINT_ROW_LABEL = 0x000000;

export const TINT_SEAT_UNAVAILABLE = 0xDDDDDD;
export const TINT_SEAT_LABEL_AVAILABLE = 0xFFFFFF;
export const TINT_SEAT_LABEL_UNAVAILABLE = 0x909090;
export const TINT_SEAT_LABEL_SELECTED = 0x000000;
export const TINT_SEAT_RESEATING = 0x009E0F;
