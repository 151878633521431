import { FetchComponent, createFetchActions } from '../../Fetch';
import {
  SelectionNotFoundError,
  apiPOSTRequest,
  generateURL
} from '../../../util/apiRequest';
import { API_ENDPOINTS } from '../../../config.json';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { ensureBasicState } from '../utils/ensureBasicState';
import { handleError } from '../../../util/handleError';
import {setArePlacesSubmitted, setPlaceSelectionId, setRedirectUrl} from '../../Session';

export const submitTickets = (
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      venueEventId,
      organizationId
    } = ensureBasicState(appState);
    const requestURL = generateURL(API_ENDPOINTS.POST_EVENT_TICKET_ITEM, {
      params: { venueEventId },
      query: { organizationId, purchaseForTicketHolderId, salesChannel, salesRuleId, subscriptionId },
    });

    const phoneNumber = appState.placesPersonalization.placesPersonalization[0].phoneNumber ?? null;

    if (phoneNumber) {
      appState.placesPersonalization.placesPersonalization.map((p) => p.phoneNumber = phoneNumber.trim());
    }

    // send only tickets with personalization data filled
    const filledPlacesPersonalization =
        appState.placesPersonalization.placesPersonalization.filter(
            (p) => (p.fullName.length > 0 || p.phoneNumber.length > 0 || p.seasonTicketLegalRecipientId.length > 0)
        );
    const body = {placesPersonalization: filledPlacesPersonalization};
    await apiPOSTRequest(requestURL, body).then((response) => {
        dispatch(setPlaceSelectionId(response.placeSelectionId));
        dispatch(setRedirectUrl(response.redirectUrl));
    });

    dispatch(setArePlacesSubmitted(true));
  } catch (error) {
    if (error instanceof SelectionNotFoundError) {
      //Seems like user clicked submit button multiply times
      dispatch(setArePlacesSubmitted(true));
      return;
    }

    handleError(dispatch, error);
  } finally {
    dispatch(fetchStop);
  }
};
