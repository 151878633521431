import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { DEEP_LINK, DEEP_LINK_PURCHASE_OPTIONS } from './config.json';
import Default from './screens/Default';
import MessageStack from './components/MessageStack';
import React from 'react';
import SelectTicket from './screens/SelectTicket';
import { Provider as StateProvider } from 'react-redux';
import VenueEventList from './screens/PurchasingList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Store } from 'redux';
import Rmv from './screens/Rmv';
import PurchaseOptions from './screens/PurchaseOptions';
import RevokeTicket from './screens/RevokeTicket';
import TicketAgency from './screens/PurchasingSuccess';

const publicURL = process.env.PUBLIC_URL;

const App: React.FC<{ store: Store }> = ({ store }) => {
  return (
    <StateProvider store={store}>
      <BrowserRouter basename={publicURL}>
        <Switch>
          <Route path={`${DEEP_LINK_PURCHASE_OPTIONS}/purchaseOptions/:venueEventId`}>
            <PurchaseOptions/>
          </Route>
          <Route path={`${DEEP_LINK}/:salesChannel/:venueEventId`}>
            <SelectTicket/>
          </Route>
          <Route path="/revokeMarketplaceTicket/:salesChannel/:ticketId">
            <RevokeTicket/>
          </Route>
          <Route path="/purchasing/:salesChannel/list">
            <VenueEventList/>
          </Route>
          <Route path="/rmv">
            <Rmv/>
          </Route>
          <Route path="/purchasing/ticket_agency/success/:placeSelectionId">
            <TicketAgency/>
          </Route>
          <Route>
            <Default/>
          </Route>
        </Switch>
        <MessageStack/>
        <ToastContainer />
      </BrowserRouter>
    </StateProvider>
  );
};

export default App;
