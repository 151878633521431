import { Action, ActionType, VenuePlanState } from './types';

const initialState: VenuePlanState = {
  fetching: false,
};

export const reducer = (state = initialState, action: Action): VenuePlanState => {
  switch (action.type) {
    case ActionType.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        fetching: false,
      };
    }
    case ActionType.FETCH: {
      return {
        ...state,
        fetching: true,
      };
    }
    case ActionType.SUCCESS: {
      const { venuePlan } = action.payload;
      return {
        ...state,
        error: undefined,
        fetching: false,
        venuePlan: venuePlan,
      };
    }
    default: {
      return state;
    }
  }
};
