import React, {useEffect, useState} from 'react';
import {API_ENDPOINTS} from '../../config.json';
import {PlaceSelectionStatus} from '../../components/VenuePlan';
import {apiGETRequest, createAuthenticationHeaders, generateURL} from "../../util/apiRequest";
import {useRouteMatch} from "react-router-dom";
import {saveAs} from "file-saver";
import style from './style.module.css';
import images from '../../assets/images';
import {useLocale} from "../../state/Localization";
import {hydrateString} from "../../util/localization";
import Header from "../../components/Page/Header";
import Footer from "../../components/Page/Footer";
import {ActionType, MessageLevel, MessageType, MessageWithId} from "../../state/Message";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from "uuid";

const TicketAgencyScreen: React.FC = () => {
    const { strings } = useLocale();
    const { placeSelectionId } = useRouteMatch<{
        placeSelectionId: string;
    }>().params;
    const [isRequestFailed, setIsRequestFailed] = useState(false);
    const [status, setStatus] = useState<PlaceSelectionStatus>({
        checkoutIds: [],
        ready: false,
        bookedCount: undefined,
        ticketsCount: undefined,
        pdfsCount: undefined,
        eventId: undefined
    });

    const startInterval = 1000; // Start with 1 second
    const maxInterval = 5000;
    const [intervalDuration, setIntervalDuration] = useState(startInterval);

    const dispatch = useDispatch();

    const getStatusRequestURL = generateURL(API_ENDPOINTS.GET_BOX_OFFICE_PLACE_SELECTION_STATUS, {
        params: { placeSelectionId: placeSelectionId }
    });

    const handleTicketsPrint = () => {
        const printPdfRequestURL = generateURL(API_ENDPOINTS.PRINT_TICKETS, {
            params: { placeSelectionId: placeSelectionId }
        });

        fetch(printPdfRequestURL, {
            headers: {
                'Content-Type': 'application/json',
                ...createAuthenticationHeaders(),
            },
        })
            .then((response) => {
                return response.blob();
            })
            .then((response) => {
                saveAs(response, placeSelectionId + '.pdf');
            })
    }

    const handleResponseError = (errorStatus: number) => {
        let messageId = uuidv4();
        let message: MessageWithId;
        switch (errorStatus) {
            case 403:
                message = {
                    id: messageId,
                    level: MessageLevel.Error,
                    type: MessageType.ErrorWrongBooking,
                };

                break;
            case 404:
                message = {
                    id: messageId,
                    level: MessageLevel.Error,
                    type: MessageType.ErrorBookingNotFound,
                };

                break;
            default:
                message = {
                    id: messageId,
                    level: MessageLevel.Error,
                    type: MessageType.Error,
                };

                break;
        }

        return dispatch({
            payload: { message },
            type: ActionType.ADD,
        });
    }

    useEffect(() => {
        const requestInterval = setInterval(() => {
            if (status.ready) {
                clearInterval(requestInterval);

                return;
            }

            apiGETRequest(getStatusRequestURL)
                .then((response) => {
                    setStatus(response);

                    if (intervalDuration < maxInterval) {
                        setIntervalDuration(
                            prevDuration => Math.min(prevDuration + startInterval, maxInterval)
                        );
                    }
                })
                .catch((error) => {
                    clearInterval(requestInterval);
                    setIsRequestFailed(true);
                    dispatch(handleResponseError(error.status));
                });
        }, intervalDuration);
        return () => clearInterval(requestInterval);
    }, [intervalDuration, status]);

    const headingString = (checkoutIds: string[]) => {
        const string = status.ready
            ? strings.TicketAgencySales_Heading
            : strings.TicketAgencySales_Heading_In_Progress;

        return hydrateString(string, {
            checkoutIds: status.checkoutIds.join(', ')
        })
    }

    return (
        <>
            <div className={style.Body}>
                <Header/>
                <div className={style.Main}>
                    <div className={style.OrderInfo}>
                        <div className={style.Heading}>
                            <h2>{headingString(status.checkoutIds)}</h2>
                            {!status.ready && !isRequestFailed &&
                                <img src={images.loadingIndicator} width='30' height='30' alt='Loading...'/>
                            }
                        </div>
                        <p><strong>{strings.TicketAgencySales_BookedCount}</strong>{status.bookedCount}</p>
                        <p><strong>{strings.TicketAgencySales_TicketsCount}</strong>{status.ticketsCount}/{status.bookedCount}</p>
                        <p><strong>{strings.TicketAgencySales_PdfsCount}</strong>{status.pdfsCount}/{status.bookedCount}</p>
                    </div>
                    <div className={style.Buttons}>
                        <button className={style.LinkButton}
                                disabled={!status.ready}
                                onClick={(): void => handleTicketsPrint()}
                        >
                            {strings.TicketAgencySales_PrintTickets}
                        </button>
                        <button className={style.LinkButton}
                                disabled={status.eventId === undefined}
                                onClick={() => window.open('/placeSelection/ticket_agency/' + status.eventId, '_self')}
                        >
                            {strings.TicketAgencySales_BackToEvent}
                        </button>
                        <button className={style.LinkButton}
                                onClick={() => window.open('/purchasing/ticket_agency/list', '_self')}
                        >
                            {strings.TicketAgencySales_BackToEventListing}
                        </button>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
};

export default TicketAgencyScreen;
