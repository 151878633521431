import { Language, Locale } from '../../util/localization';
import germanLocale from '../../locales/de.json';
import englishLocale from '../../locales/en.json';
import {useVenueEvent} from '../VenueEvent';

export const useLocale = (): Locale => {
  const {venueEvent} = useVenueEvent();

  if (venueEvent?.locale === 'en') {
    return {
      language: Language.English,
      ...englishLocale,
    };
  }

  return {
    language: Language.German,
    ...germanLocale,
  };
};
