import { Action, ActionType, PlacesPersonalizationState } from './types';


const initialState: PlacesPersonalizationState = { placesPersonalization: [] };

export const reducer = (state = initialState, action: Action): PlacesPersonalizationState => {
  switch (action.type) {
    case ActionType.SET_PERSONALIZATION: {
      const { placesPersonalization } = action.payload;
      return { ...state, placesPersonalization };
    }
    default: {
      return state;
    }
  }
};